import {getComponentConfig, getMultiImagePosition, isSingleEventWidget} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {EventImage as Presentation} from './event-image'
import {EventImageOwnProps, EventImageStateProps} from './interfaces'

const mapState = ({state}: AppProps) => {
  const config = getComponentConfig(state)

  return {
    multiImagePosition: getMultiImagePosition(config),
    singleLayout: isSingleEventWidget(config),
    seo: state.environment.seo,
  }
}

export const EventImage = connect<EventImageOwnProps, EventImageStateProps>(mapState)(Presentation)
