import type { DispatchModalStore } from 'root/states/DispatchModalStore';
import type { TFunction, ReportError } from '@wix/yoshi-flow-editor';
import {
  convertDateToDropdownOption,
  convertTimeSlotToDropdownOption,
  ErrorType,
  SchedulingTypeModalState,
} from './dispatchModalUtils';
import { SchedulingType, DispatchType } from 'root/types/businessTypes';
import { FormState, FieldState } from 'formstate';
import type { AddressInputAddress } from 'root/types/businessTypes';
import { makeObservable, action, computed } from 'mobx';
import type { TimeSlotOption } from './dispatchModalUtils';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { monitorCallback } from 'root/utils/utils';

type DispatchModalFormFields = {
  dispatchType: FieldState<DispatchType>;
  address: FieldState<AddressInputAddress | undefined>;
  selectedDate: FieldState<Date | undefined>;
  selectedOption: FieldState<TimeSlotOption | undefined>;
  schedulingType: FieldState<SchedulingType>;
};

export class DispatchModalForm {
  private form!: FormState<DispatchModalFormFields>;
  constructor(
    private store: DispatchModalStore,
    private t: TFunction,
    private timezone: string,
    private locale: string,
    private sentry?: ErrorMonitor,
    private reportError?: ReportError
  ) {
    this.monitorClassMethods();

    try {
      sentry?.addBreadcrumb({ category: 'DispatchModalForm', type: 'ctor', message: 'createForm' });
      this.form = this.createForm(store, t, timezone, locale);
    } catch (error) {
      reportError?.(error as Error);
      sentry?.captureException(error as Error);
    }

    makeObservable(this, {
      dropdownTimeSlotsOptions: computed,
      dropdownTimeSlotsOptionsMap: computed,
      dropdownDatesOptions: computed,
      shouldCollapseDateSlotDropdown: computed,
      selectedOption: computed,
      canSubmitOrderForNow: computed,
      isSchedulingPickerDisplayed: computed,
      shouldCollapseTimeMultiStateBox: computed,
      isASAP: computed,
      isScheduleForLater: computed,
      isPreOrder: computed,
      isPickup: computed,
      address: computed,
      schedulingType: computed,
      dispatchType: computed,
      errorText: computed,
      hasError: computed,
      selectedDate: computed,
      setAddress: action,
      setSchedulingType: action,
      setDispatchType: action,
      setSelectedOption: action,
      setSelectedDate: action,
      canSubmit: action,
    });
  }

  private createForm(store: DispatchModalStore, t: TFunction, timezone: string, locale: string) {
    return new FormState({
      dispatchType: new FieldState(store.dispatchType),
      address: new FieldState(store.address).validators((address) => {
        if (!!address && !address.location) {
          return ErrorType.ADDRESS_NOT_FOUND.toString();
        }
      }),
      selectedDate: new FieldState(store.currentDate),
      selectedOption: new FieldState(
        store.selectedTimeSlot
          ? convertTimeSlotToDropdownOption(store.selectedTimeSlot, t, timezone, locale)
          : undefined
      ).validators((option) => {
        if (!option) {
          return this.t('menu_olo.dispatchModal.errorMessage').toString();
        }
      }),
      schedulingType: new FieldState(
        store.selectedTimeSlot?.startsNow ? SchedulingType.ASAP : store.schedulingType
      ),
    }).validators((form) => {
      if (!form.address.value) {
        return this.t('menu_olo.dispatchModal.error.noAddress').toString();
      }
    });
  }

  private monitorClassMethods() {
    this.updatePickerValues = monitorCallback(
      this.updatePickerValues.bind(this),
      {
        category: 'DispatchModalForm',
        type: 'side effect',
        message: 'updatePickerValues',
      },
      this.reportError,
      this.sentry
    );

    this.setAddress = monitorCallback(
      this.setAddress.bind(this),
      {
        category: 'DispatchModalForm',
        type: 'action',
        message: 'setAddress',
      },
      this.reportError,
      this.sentry
    );

    this.setSchedulingType = monitorCallback(
      this.setSchedulingType.bind(this),
      {
        category: 'DispatchModalForm',
        type: 'action',
        message: 'setSchedulingType',
      },
      this.reportError,
      this.sentry
    );

    this.setDispatchType = monitorCallback(
      this.setDispatchType.bind(this),
      {
        category: 'DispatchModalForm',
        type: 'action',
        message: 'setDispatchType',
      },
      this.reportError,
      this.sentry
    );

    this.setSelectedOption = monitorCallback(
      this.setSelectedOption.bind(this),
      {
        category: 'DispatchModalForm',
        type: 'action',
        message: 'setSelectedOption',
      },
      this.reportError,
      this.sentry
    );

    this.setSelectedDate = monitorCallback(
      this.setSelectedDate.bind(this),
      {
        category: 'DispatchModalForm',
        type: 'action',
        message: 'setSelectedDate',
      },
      this.reportError,
      this.sentry
    );
  }

  get dropdownTimeSlotsOptions() {
    const options = this.store.timeSlots
      .filter((slot) => !slot.startsNow)
      .map((timeSlot) =>
        convertTimeSlotToDropdownOption(timeSlot, this.t, this.timezone, this.locale)
      );
    return options;
  }

  get dropdownTimeSlotsOptionsMap() {
    return this.dropdownTimeSlotsOptions.reduce((acc, option) => {
      acc[option.value] = option;
      return acc;
    }, {} as Record<string, TimeSlotOption>);
  }

  get dropdownDatesOptions() {
    const dates = this.store.hasOnlyAsapToday
      ? this.store.availableDates.slice(1)
      : this.store.availableDates;

    const options = dates.map((date) =>
      convertDateToDropdownOption(date, this.timezone, this.locale, this.t)
    );
    return options;
  }

  get shouldCollapseDateSlotDropdown() {
    return (
      this.form.hasError ||
      !this.isScheduleForLater ||
      (this.dropdownDatesOptions.length === 0 && !this.store.isLoading)
    );
  }

  get shouldCollapseTimeMultiStateBox() {
    return !this.form.$.address.value && !this.store.isLoading;
  }

  get selectedOption() {
    return this.form.$.selectedOption.value;
  }

  get canSubmitOrderForNow() {
    return this.store.schedulingType !== SchedulingType.PRE_ORDER && !!this.store.asapTimeSlot;
  }

  get isSchedulingPickerDisplayed() {
    return (
      this.canSubmitOrderForNow &&
      this.store.schedulingTypeModalState === SchedulingTypeModalState.ASAP_AND_FUTURE
    );
  }

  get isASAP() {
    return this.form.$.schedulingType.value === SchedulingType.ASAP;
  }

  get isScheduleForLater() {
    return this.form.$.schedulingType.value === SchedulingType.ASAP_AND_FUTURE;
  }

  get isPreOrder() {
    return this.form.$.schedulingType.value === SchedulingType.PRE_ORDER;
  }

  get isPickup() {
    return this.form.$.dispatchType.value === DispatchType.PICKUP;
  }

  get address() {
    return this.form.$.address.value;
  }

  get schedulingType() {
    return this.form.$.schedulingType.value;
  }

  get dispatchType() {
    return this.form.$.dispatchType.value;
  }

  get errorText() {
    return this.form.error;
  }

  get hasError() {
    return this.form.hasError;
  }

  get selectedDate() {
    return this.form.$.selectedDate.value;
  }

  private async updatePickerValues() {
    this.form.$.selectedDate.onChange(this.store.currentDate);
    if (this.store.selectedTimeSlot) {
      if (this.store.selectedTimeSlot.startsNow) {
        this.form.$.schedulingType.onChange(SchedulingType.ASAP);
      } else {
        this.form.$.schedulingType.onChange(this.store.schedulingType);
      }
      this.form.$.selectedOption.onChange(
        convertTimeSlotToDropdownOption(
          this.store.selectedTimeSlot,
          this.t,
          this.timezone,
          this.locale
        )
      );
    } else {
      this.form.$.selectedOption.onChange(undefined);
    }
    return Promise.all([
      this.form.$.selectedDate.validate(),
      this.form.$.schedulingType.validate(),
      this.form.$.selectedOption.validate(),
    ]);
  }

  async setAddress(address: AddressInputAddress) {
    if (address.formatted !== this.form.$.address.value?.formatted) {
      this.form.clearFormError();
      this.form.$.address.onChange(address);
      await this.form.$.address.validate();
      if (address.location) {
        this.store.setIsLoading(true);
        await this.store.setAddress(address);
        await this.updatePickerValues();
        this.store.setIsLoading(false);
      }
    }
  }

  async setSchedulingType(schedulingType: SchedulingType) {
    this.form.$.schedulingType.onChange(schedulingType);
    this.form.$.schedulingType.validate();
    if (schedulingType === SchedulingType.ASAP) {
      await this.store.switchToAsapTimeSlot();
    } else if (schedulingType === SchedulingType.ASAP_AND_FUTURE) {
      const [firstOption] = this.dropdownTimeSlotsOptions;
      firstOption && (await this.setSelectedOption(firstOption.value));
      const [firstDateOption] = this.dropdownDatesOptions;
      firstDateOption && (await this.setSelectedDate(new Date(firstDateOption.value)));
    }
  }

  async setDispatchType(dispatchType: DispatchType) {
    if (dispatchType !== this.dispatchType) {
      this.store.setIsLoading(true);
      const setDispatchTypePromise = this.store.setDispatchType(dispatchType);
      this.form.$.address.onChange(this.store.address);
      const updatePickerValuesValidationPromise = this.updatePickerValues();
      this.form.$.dispatchType.onChange(dispatchType);
      await Promise.all([
        this.form.$.address.validate(),
        setDispatchTypePromise,
        updatePickerValuesValidationPromise,
        this.form.$.dispatchType.validate(),
      ]);
      this.store.setIsLoading(false);
      this.form.clearFormError();
    }
  }

  async setSelectedOption(optionId: string) {
    this.form.$.selectedOption.onChange(this.dropdownTimeSlotsOptionsMap[optionId]);
    this.store.setTimeSlot(optionId);
    return this.form.$.selectedOption.validate();
  }

  async setSelectedDate(date: Date) {
    this.form.$.selectedDate.onChange(date);
    const dateValidationPromise = this.form.$.selectedDate.validate();
    await this.store.setDate(date);
    const [firstOption] = this.dropdownTimeSlotsOptions;
    const selectedOptionValidationPromise = this.setSelectedOption(firstOption.value);
    await Promise.all([selectedOptionValidationPromise, dateValidationPromise]);
  }

  async canSubmit() {
    await this.form.validate();
    return !this.form.hasError && this.store.hasAvailableTimeSlots;
  }
}
