import {ISettingsParam} from '@wix/tpa-settings';
import {useControllerProps} from '../domain/controllers/ControllerContext';
import {useSettings} from '@wix/tpa-settings/react';

export const useSettingsWithDefaults = () => {
  const {useGetSettingsWithDefaults} = useControllerProps().cartStore;
  const {get: getSettings, getDefaultValue} = useSettings();

  return {
    getSettingsWithDefaults: (settingKey: ISettingsParam): string =>
      useGetSettingsWithDefaults
        ? ((getSettings(settingKey) || getDefaultValue(settingKey)) as string)
        : (getSettings(settingKey) as string),
  };
};
