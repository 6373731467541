import {ISettingsParam} from '@wix/tpa-settings';
import {useSettings} from '@wix/tpa-settings/react';
import {useControllerProps} from '../components/thankYouPage/Widget/ControllerContext';

export const useSettingsWithDefaults = () => {
  const {useGetSettingsWithDefaults} = useControllerProps().thankYouPageStore;
  const {get: getSettings, getDefaultValue} = useSettings();

  return {
    getSettingsWithDefaults: (settingKey: ISettingsParam): string => {
      return useGetSettingsWithDefaults
        ? ((getSettings(settingKey) || getDefaultValue(settingKey)) as string)
        : (getSettings(settingKey) as string);
    },
  };
};
