import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import type { MenuCheckbox } from 'root/clientTypes';
import { isSectionChecked, isPriceShowed } from 'root/utils/display';
import { useGetDisplaySettingsParams } from 'root/hooks/useGetDisplaySettingsParams';
import { useItemsContext } from 'root/contexts/ItemsContext';
import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';
import type { ItemVariant } from 'root/apiTypes';
import { AlignmentProperty, useGetItemTextAlignment } from 'root/hooks/preset/useGetItemTextAlignment';
import { useGetDesignStylesParams } from 'root/hooks/useGetDesignStylesParams';

export const useVariantLogic = ({ variant }: { variant: ItemVariant }) => {
  const { sectionId } = useItemsContext();

  const styles = useStyles();
  const preset = styles.get(stylesParams.preset);

  const { showItemVariantsName, showItemVariantsPrice, showItemVariantsCurrency } = useGetDisplayStylesParams();
  const { showZeroPrice } = useGetDisplaySettingsParams();

  const { checkPricingMenus, showZeroPriceToAll } = useGetDisplaySettingsParams();
  const { variantTitleTheme, variantPriceTheme } = useGetDesignStylesParams();

  const formattedPrice = showItemVariantsCurrency
    ? variant.priceInfo.formattedPrice
    : variant.priceInfo.formattedPriceNoCurrency ?? '';

  const priceOptionSectionChecked =
    showZeroPriceToAll || isSectionChecked(checkPricingMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowVariantsPrice = isPriceShowed({
    price: variant.priceInfo.price ?? '',
    showPrice: showItemVariantsPrice,
    showZeroPrice: showZeroPrice as boolean,
    sectionChecked: priceOptionSectionChecked as boolean,
  });

  const justifySelfCss = useGetItemTextAlignment(preset, AlignmentProperty.JustifySelf);

  return {
    formattedPrice,
    showItemVariantsName,
    shouldShowVariantsPrice,
    justifySelfCss,
    variantTitleTheme,
    variantPriceTheme,
  };
};
