import {IStylesGetParam} from '@wix/tpa-settings/dist/src/contexts/StylesContext/StylesContext.types'
import {LIST_LOCATION_AND_DATE_FORMAT, MOBILE_LIST_LAYOUT, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import stylesParams from './stylesParams'

export const StyleSelectors = (get: IStylesGetParam, mobile: boolean) => {
  const getWidgetType = () => get(stylesParams.widgetType)

  const getMobileLayout = () => get(stylesParams.mobileListLayout)

  const isMultiEventWidget = () => getWidgetType() === WIDGET_TYPE.LIST

  const isMobileCompactLayout = () => isMultiEventWidget() && getMobileLayout() === MOBILE_LIST_LAYOUT.COMPACT

  const isListAdditionalInfoEnabled = () => get(stylesParams.listShowAdditionalInfo)

  const isListFullDateEnabled = () => get(stylesParams.listShowFullDate)

  const isListLocationEnabled = () => get(stylesParams.listShowLocation)

  const isListDescriptionEnabled = () => get(stylesParams.listShowDescription)

  const isListCountdownEnabled = () => get(stylesParams.listShowCountdown)

  const isListSocialShareEnabled = () => get(stylesParams.listShowSocialShare)

  const isListMembersEnabled = () => get(stylesParams.listShowMembers)

  const isListAdditionalComponentsVisible = () => {
    if (mobile && isMobileCompactLayout()) {
      return false
    }
    return (
      isListAdditionalInfoEnabled() &&
      (isListFullDateEnabled() || isListLocationEnabled() || isListDescriptionEnabled() || isListSocialShareEnabled())
    )
  }

  const isListDateEnabled = () => get(stylesParams.listShowDate)

  const isListVenueNameEnabled = () => get(stylesParams.listShowVenueName)

  const isListFullDateOrLocationEnabled = () => isListFullDateEnabled() || isListLocationEnabled()

  const isListImageEnabled = () => get(stylesParams.listShowImage)

  const getListLocationAndDateFormat = () => get(stylesParams.listLocationAndDateFormat)

  const getCardLocationAndDateFormat = () => get(stylesParams.cardLocationAndDateFormat)

  const isSideBySideShortDateLocationVisible = () =>
    getListLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.COMPACT &&
    (isListDateEnabled() || isListVenueNameEnabled())

  const isSideBySideFullDateLocationVisible = () =>
    getListLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.FULL &&
    (isListFullDateEnabled() || isListLocationEnabled())

  const isCardShortDateLocationVisible = () => getCardLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.COMPACT

  const isCardFullDateLocationVisible = () => getCardLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.FULL

  const isCardHoveredFullDateLocationVisible = () =>
    isListFullDateOrLocationEnabled() && getCardLocationAndDateFormat() !== LIST_LOCATION_AND_DATE_FORMAT.FULL

  const isListMainTitleEnabled = () => get(stylesParams.listShowMainTitle)

  const isListDateVisible_V2 = () => get(stylesParams.listShowDateV2)
  const isListLocationVisible_V2 = () => get(stylesParams.listShowLocationV2)

  const isListExtraInfoDateVisible_V2 = () => get(stylesParams.listExtraInfoShowDate)
  const isListExtraInfoLocationVisible_V2 = () => get(stylesParams.listExtraInfoShowLocation)

  const isListDateAndLocationContainerVisible_V2 = () => isListDateVisible_V2() || isListLocationVisible_V2()

  const isListDateLocationFormatShort_V2 = () =>
    get(stylesParams.listLocationAndDateFormatV2) === LIST_LOCATION_AND_DATE_FORMAT.COMPACT
  const isListDateLocationFormatFull_V2 = () =>
    get(stylesParams.listLocationAndDateFormatV2) === LIST_LOCATION_AND_DATE_FORMAT.FULL

  const isListShortDateLocationVisible_V2 = () =>
    isListDateAndLocationContainerVisible_V2() && isListDateLocationFormatShort_V2()
  const isListFullDateLocationVisible_V2 = () =>
    isListDateAndLocationContainerVisible_V2() && isListDateLocationFormatFull_V2()
  const isListExtraInfoDateOrLocationVisible_V2 = () =>
    isListExtraInfoDateVisible_V2() || isListExtraInfoLocationVisible_V2()

  const isListShortDateVisible_V2 = () => isListDateVisible_V2() && isListDateLocationFormatShort_V2()
  const isListFullDateVisible_V2 = () => isListDateVisible_V2() && isListDateLocationFormatFull_V2()

  const isListShortLocationVisible_V2 = () => isListLocationVisible_V2() && isListDateLocationFormatShort_V2()
  const isListFullLocationVisible_V2 = () => isListLocationVisible_V2() && isListDateLocationFormatFull_V2()

  return {
    isMultiEventWidget,
    isMobileCompactLayout,
    isListAdditionalInfoEnabled,
    isListFullDateEnabled,
    isListLocationEnabled,
    isListDescriptionEnabled,
    isListCountdownEnabled,
    isListSocialShareEnabled,
    isListMembersEnabled,
    isListAdditionalComponentsVisible,
    isListDateEnabled,
    isListVenueNameEnabled,
    isListFullDateOrLocationEnabled,
    isListImageEnabled,
    isSideBySideShortDateLocationVisible,
    isSideBySideFullDateLocationVisible,
    isCardShortDateLocationVisible,
    isCardFullDateLocationVisible,
    isCardHoveredFullDateLocationVisible,
    isListMainTitleEnabled,
    getListLocationAndDateFormat,

    isListDateVisible_V2,
    isListLocationVisible_V2,
    isListDateAndLocationContainerVisible_V2,
    isListShortDateLocationVisible_V2,
    isListFullDateLocationVisible_V2,
    isListExtraInfoDateVisible_V2,
    isListExtraInfoLocationVisible_V2,
    isListShortDateVisible_V2,
    isListFullDateVisible_V2,
    isListShortLocationVisible_V2,
    isListFullLocationVisible_V2,
    isListExtraInfoDateOrLocationVisible_V2,
  }
}
