import { createAsyncThunk } from '@reduxjs/toolkit';
import { Interaction } from '@constants';
import { BiToastType } from '@services/BiLogger';
import { toastsActions } from '../../toast';
import { modalsActions } from '../../modal';
import type { ThunkApiConfig } from '../../store.types';
import { getLastUpdatedMethod } from '../selectors';
import { fetchSavedPaymentMethods, savedPaymentMethodsActions } from '..';
import type { RemoveThunk } from './types';

export const removeSavedPaymentMethod = createAsyncThunk<
  void,
  RemoveThunk,
  ThunkApiConfig
>(
  'savedPaymentMethods/remove',
  async (
    { paymentMethodId },
    { extra, dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { flowAPI, savedPaymentMethodsApi } = extra;
      const { environment, translations, fedops } = flowAPI;
      const state = getState();

      if (environment.isViewer) {
        await savedPaymentMethodsApi.remove(paymentMethodId);

        const lastUpdatedMethod = getLastUpdatedMethod(paymentMethodId)(state);
        if (lastUpdatedMethod) {
          dispatch(
            savedPaymentMethodsActions.setMethodPrimary(lastUpdatedMethod.id),
          );
        }

        dispatch(fetchSavedPaymentMethods());
      }

      fedops.interactionEnded(Interaction.RemovePaymentMethod);
      dispatch(modalsActions.closeModal());
      dispatch(
        toastsActions.showToast({
          text: translations.t('app.toast.payment-method-removed.text'),
          meta: { action: BiToastType.RemovedPaymentMethod },
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        extra.flowAPI.errorMonitor.captureException(error);
      }

      dispatch(
        toastsActions.showToast({
          text: extra.flowAPI.translations.t(
            'app.toast.remove-payment-method.error.text',
          ),
          meta: { action: BiToastType.RemoveError, skin: 'error' },
        }),
      );

      throw rejectWithValue({ error });
    }
  },
);
