import { Text, Divider } from 'wix-ui-tpa/cssVars';
import React from 'react';
import type { ItemVariant } from 'root/apiTypes';
import { useVariantLogic } from './Variant.logic';
import { classes } from './Variant.st.css';
import { dataHooks } from 'root/dataHooks';

export const Variant = ({ variant, showDivider }: { variant: ItemVariant; showDivider: boolean }) => {
  const {
    formattedPrice,
    showItemVariantsName,
    shouldShowVariantsPrice,
    justifySelfCss,
    variantTitleTheme,
    variantPriceTheme,
  } = useVariantLogic({
    variant,
  });

  return (
    <div className={classes.variantContainer} data-hook={dataHooks.item.variant} role="listitem" style={justifySelfCss}>
      <div className={classes.variantDetails}>
        {showItemVariantsName && (
          <Text className={classes.variantName} data-hook={dataHooks.variant.name} tagName={variantTitleTheme.htmlTag}>
            {variant.name}
          </Text>
        )}
        {shouldShowVariantsPrice && (
          <Text
            className={classes.variantPrice}
            data-hook={dataHooks.variant.price}
            tagName={variantPriceTheme.htmlTag}
          >
            {formattedPrice}
          </Text>
        )}
      </div>
      {showDivider && <Divider className={classes.divider} direction="vertical" />}
    </div>
  );
};
