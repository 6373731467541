import model from './model';
import { MenuController } from './menuController';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import { WarmupDataManager } from 'root/utils/WarmupDataManager';
import { restaurantsUouPageFinishedLoading } from '@wix/bi-logger-restaurants/v2';
import { getHostingEnvironment } from 'root/utils/bi';
import MenuWidget from 'root/components/Menu/.component.json';
import { MENU_WIDGET_NAME } from './consts';
import { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import type { PopulatedMenu } from 'root/types';
import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import type { GetAllResponse } from 'root/api/utils/types';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import {
  getFilteredMenusOrder,
  getMenusSEOData,
  getIsCurrentPageMenusDefaultPage,
  isValidMenuQueryParam,
} from './utils';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from './panels/Settings/types';
import { BiReporter } from 'root/utils/BiReporter';
import { MENUS_EXTENDED_FIELDS_NAME_SPACE } from 'root/utils/consts';
import type { MenusPromiseResponse } from './types';

let menusPromise: Promise<{ data: GetAllResponse<PopulatedMenu> | undefined; error: Error | undefined }> | undefined;

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { location, seo, site } = flowAPI?.controllerConfig?.wixCodeApi;
  const { isViewer } = flowAPI.environment;
  const biReporter = new BiReporter(flowAPI.bi, flowAPI.controllerConfig.compId);
  biReporter.init({ flowAPI });
  const menuController = new MenuController($w, isViewer, flowAPI.translations.t, biReporter);
  const fedopsLogger = new FedopsLogger(flowAPI.fedops as FedopsLoggerType);
  const menuQueryParam = location?.query?.menu;

  fedopsLogger.loadMenusPageStarted();
  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment.isSSR
  );

  const getMonitoredPopulatedMenuClient = () =>
    getMonitoredApiCall(
      () => PopulatedMenuClient(flowAPI.essentials.httpClient).getAll({ onlyVisible: true }),
      flowAPI.reportError
    );

  /* in tests we want to fetch the data every time.
      TODO: extract this to a different module so we could mock it in test env https://jira.wixpress.com/browse/RST-8402 */
  if (!menusPromise || process.env.NODE_ENV === 'test') {
    menusPromise = warmupData.manageData<MenusPromiseResponse, GetAllResponse<PopulatedMenu>>(
      getMonitoredPopulatedMenuClient,
      'populatedMenus'
    );
  }

  const sendSEOData = async (currentMenu: PopulatedMenu) => {
    const pageBaseUrl = location.baseUrl;
    const menusPageSlug = site.currentPage?.url;
    const urlQueryParam = isValidMenuQueryParam(currentMenu.urlQueryParam)
      ? `?menu=${encodeURIComponent(currentMenu.urlQueryParam)}`
      : `?menuId=${currentMenu.id}`;
    const fullPageUrl = pageBaseUrl + menusPageSlug + urlQueryParam;
    const isCurrentPageMenusDefaultPage = await getIsCurrentPageMenusDefaultPage(site);
    const itemData = getMenusSEOData({
      currentMenu,
      pageUrl: fullPageUrl,
      environment: flowAPI.environment,
      menusOrder: $widget.props.menusOrder,
      firstMenu: menuController.menus?.[0],
    });

    seo.renderSEOTags({
      itemType: isCurrentPageMenusDefaultPage
        ? ITEM_TYPES.RESTAURANTS_MENU_PAGE
        : ITEM_TYPES.RESTAURANTS_MENU_COMPONENT,
      itemData,
      seoData: currentMenu?.extendedFields?.namespaces?.[MENUS_EXTENDED_FIELDS_NAME_SPACE]?.seoData || {},
    });
  };

  const setMenus = async (menus: PopulatedMenu[]) => {
    menuController.setMenus(menus);
    fedopsLogger.setMenusDataEnded();
    const menusOrder = getFilteredMenusOrder({
      menus,
      menusOrder: $widget.props.menusOrder,
      menusDisplayOption: $widget.props.menusDisplayOption,
    });
    const menuId = location?.query?.menuId || menusOrder?.[0] || (menus[0].id as string);
    const hasMenu = menuController.init(
      menuId,
      menuQueryParam,
      $widget.props.preset,
      $widget.props.shouldDisplayCurrency,
      $widget.props.shouldDisplayVariantCurrency,
      $widget.props.zeroPriceDisplayOption as ZeroPriceDisplayValue,
      $widget.props.zeroPriceDisplaySpecificSectionIds as string[],
      $widget.props.placeholderImageDisplayValue as PlaceholderImageDisplayValue,
      $widget.props.sectionsWithPlaceholderImageIds,
      $widget.props.placeholderImage
    );
    if (!hasMenu) {
      seo?.setSeoStatusCode(404);
      menuController.setErrorState();
    } else {
      const currentMenu = menus.find((menu: PopulatedMenu) =>
        menuQueryParam ? menu.urlQueryParam === menuQueryParam : menu.id === menuId
      );

      menuController.setNavigationBar(
        $widget.props.preset,
        $widget.props.shouldDisplayCurrency,
        $widget.props.shouldDisplayVariantCurrency,
        $widget.props.zeroPriceDisplayOption as ZeroPriceDisplayValue,
        $widget.props.zeroPriceDisplaySpecificSectionIds as string[],
        $widget.props.placeholderImageDisplayValue as PlaceholderImageDisplayValue,
        $widget.props.sectionsWithPlaceholderImageIds,
        location,
        flowAPI,
        $widget.props.placeholderImage,
        menusOrder
      );

      if (currentMenu) {
        sendSEOData(currentMenu);
      } else {
        const sentry = flowAPI.errorMonitor;
        sentry.captureException(new Error('Menu not found for SEO data'));
      }
    }

    return menuId;
  };

  const retryOnEmptyMenus = async (counter = 0) => {
    if (counter < 5) {
      setTimeout(async () => {
        const { data } = (await getMonitoredPopulatedMenuClient()) || {};
        const { data: menus } = data || {};
        if (menus?.length) {
          fedopsLogger.setMenusDataStarted();
          await setMenus(menus);
        } else {
          retryOnEmptyMenus(counter + 1);
        }
      }, 2000);
    }
  };

  $widget.onPropsChanged((prevProps, nextProps) => {
    if (
      prevProps.preset !== nextProps.preset ||
      prevProps.shouldDisplayCurrency !== nextProps.shouldDisplayCurrency ||
      prevProps.shouldDisplayVariantCurrency !== nextProps.shouldDisplayVariantCurrency ||
      prevProps.zeroPriceDisplayOption !== nextProps.zeroPriceDisplayOption ||
      prevProps.placeholderImageDisplayValue !== nextProps.placeholderImageDisplayValue ||
      prevProps.zeroPriceDisplaySpecificSectionIds !== nextProps.zeroPriceDisplaySpecificSectionIds ||
      prevProps.sectionsWithPlaceholderImageIds !== nextProps.sectionsWithPlaceholderImageIds ||
      prevProps.placeholderImage !== nextProps.placeholderImage
    ) {
      menuController.setColumns(
        nextProps.preset,
        nextProps.shouldDisplayCurrency,
        nextProps.shouldDisplayVariantCurrency,
        nextProps.zeroPriceDisplayOption as ZeroPriceDisplayValue,
        nextProps.zeroPriceDisplaySpecificSectionIds as string[],
        nextProps.placeholderImageDisplayValue as PlaceholderImageDisplayValue,
        nextProps.sectionsWithPlaceholderImageIds,
        nextProps.placeholderImage
      );
    }
    if (prevProps.menusOrder !== nextProps.menusOrder) {
      const shouldInitMenu = nextProps.menusOrder[0] !== menuController.menu?.id;
      shouldInitMenu &&
        menuController.init(
          nextProps.menusOrder[0],
          menuQueryParam,
          nextProps.preset,
          nextProps.shouldDisplayCurrency,
          nextProps.shouldDisplayVariantCurrency,
          nextProps.zeroPriceDisplayOption as ZeroPriceDisplayValue,
          nextProps.zeroPriceDisplaySpecificSectionIds as string[],
          nextProps.placeholderImageDisplayValue as PlaceholderImageDisplayValue,
          nextProps.sectionsWithPlaceholderImageIds,
          nextProps.placeholderImage
        );
      menuController.setNavigationBar(
        nextProps.preset,
        nextProps.shouldDisplayCurrency,
        nextProps.shouldDisplayVariantCurrency,
        nextProps.zeroPriceDisplayOption as ZeroPriceDisplayValue,
        nextProps.zeroPriceDisplaySpecificSectionIds as string[],
        nextProps.placeholderImageDisplayValue as PlaceholderImageDisplayValue,
        nextProps.sectionsWithPlaceholderImageIds,
        location,
        flowAPI,
        nextProps.placeholderImage,
        nextProps.menusOrder
      );
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      let menuId;

      if (menusPromise) {
        const { data, error } = (await menusPromise) || {};

        if (!error) {
          const { data: menus } = data || {};
          if (menus?.length) {
            fedopsLogger.setMenusDataStarted();
            menuId = await setMenus(menus);
          } else if (menus?.length === 0) {
            menuController.setEmptyState();
            !flowAPI.environment.isViewer && retryOnEmptyMenus();
          }
          fedopsLogger.loadMenusPageEnded();
        } else {
          menuController.setErrorState();
          throw error;
        }
      }

      const isCurrentPageMenusDefaultPage = await getIsCurrentPageMenusDefaultPage(site);
      // TODO: send loading time https://jira.wixpress.com/browse/RST-6147
      flowAPI.bi?.report(
        restaurantsUouPageFinishedLoading({
          hosting: getHostingEnvironment(flowAPI.environment),
          pageName: 'menus',
          product: 'menus',
          widgetId: MenuWidget.id,
          widgetInstanceId: flowAPI.controllerConfig.compId,
          widgetName: MENU_WIDGET_NAME,
          isMenusDefaultPage: isCurrentPageMenusDefaultPage,
          menuId,
          isOoi: false,
        })
      );
    },
    exports: {},
  };
});
