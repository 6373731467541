import {ExperimentNames, getEventTicketLimitPerOrder, hasPricingOptions} from '@wix/wix-events-commons-statics'
import {SEATING_ERROR} from '../../constants'
import {DONATION_ERROR} from '../../constants/donation-error'
import {setPlaceDonationError, setPlaceDonationErrors, setSeatingError} from '../../reducers/seating/errors'
import {getEvent} from '../../selectors/event'
import {isChooseMultipleSeatsMode, isChooseSeatMode} from '../../selectors/navigation'
import {isInAccessibilityMode, isInDescription} from '../../selectors/seating/mode'
import {
  getPlaceQuantityById,
  isPlaceArea,
  isPlaceInBasket,
  isPlaceReservableAsWhole,
} from '../../selectors/seating/place'
import {getAnyPlaceById, getPlaces, getPlacesInBasket} from '../../selectors/seating/places'
import {getSelectedTicketsQuantity, isTicketLimitReached} from '../../selectors/selected-tickets'
import {checkDonationError} from '../../services/donations'
import {createAsyncAction} from '../../services/redux-toolkit'

const donationErrors = (chooseSeatMode: boolean) =>
  chooseSeatMode ? {excludeErrors: [DONATION_ERROR.MINIMUM_NOT_REACHED]} : undefined

export const validateDonation = createAsyncAction<
  boolean,
  {placeId: string; ticket: wix.events.ticketing.Ticket; donation: string}
>('VALIDATE_DONATION', async ({placeId, ticket, donation}, {getState, dispatch}) => {
  const chooseSeatMode = isChooseSeatMode(getState())
  const chooseMultipleSeatsMode = isChooseMultipleSeatsMode(getState())
  const error = checkDonationError(ticket, donation, donationErrors(chooseSeatMode || chooseMultipleSeatsMode))

  if (error) {
    dispatch(setPlaceDonationError({placeId, error}))
  }

  return !error
})

export const validateAllDonations = createAsyncAction<boolean, void>(
  'VALIDATE_ALL_DONATIONS',
  async (_, {getState, dispatch}) => {
    const state = getState()
    const chooseSeatMode = isChooseSeatMode(getState())
    const chooseMultipleSeatMode = isChooseMultipleSeatsMode(getState())
    const placesInBasket = getPlacesInBasket(state)

    const errors = placesInBasket
      .map(place => {
        const error = checkDonationError(
          place.ticket,
          place.donation,
          donationErrors(chooseSeatMode || chooseMultipleSeatMode),
        )
        if (error) {
          return {placeId: place.id, error}
        }
        return undefined
      })
      .filter(Boolean)

    dispatch(setPlaceDonationErrors(errors))

    return !errors.length
  },
)

export const validateTicketLimit = createAsyncAction<boolean, {planPlaceId: string}>(
  'VALIDATE_TICKET_LIMIT',
  async ({planPlaceId}, {dispatch, getState, extra: {flowAPI}}) => {
    const state = getState()

    const planTicketsDesignEnabled = flowAPI.experiments.enabled(ExperimentNames.PlanTicketsDesign)

    const place = getAnyPlaceById(getPlaces(state), planPlaceId)
    const placeQuantity = getPlaceQuantityById(getPlaces(state), place.id)
    const placeInBasket = isPlaceInBasket(place)
    const area = isPlaceArea(place)
    const pricingOptions = hasPricingOptions(place.ticket)
    const showAccessibilityMode = isInAccessibilityMode(state)
    const inDescription = isInDescription(state)

    const accountForAreaCounterValue = planTicketsDesignEnabled && !placeInBasket && area

    const chooseSeatMode = isChooseSeatMode(state)
    const selectedTicketsQuantity = getSelectedTicketsQuantity(state)
    const ticketLimitReached = isTicketLimitReached(state, accountForAreaCounterValue, place.id)
    const event = getEvent(state)
    const eventTicketLimitPerOrder = getEventTicketLimitPerOrder(event)

    const wholeElementLimitReachedError =
      !placeInBasket &&
      isPlaceReservableAsWhole(place) &&
      placeQuantity === 0 &&
      eventTicketLimitPerOrder < (chooseSeatMode ? place.places.length : selectedTicketsQuantity + place.places.length)

    const canShowError =
      planTicketsDesignEnabled && placeInBasket && area && pricingOptions && !showAccessibilityMode && !inDescription

    const ticketLimitReachedError =
      ticketLimitReached && ((!planTicketsDesignEnabled && area) || !placeInBasket || canShowError)

    if (wholeElementLimitReachedError || (!chooseSeatMode && ticketLimitReachedError)) {
      dispatch(setSeatingError({error: SEATING_ERROR.TICKET_LIMIT_REACHED}))
      return false
    }
    return true
  },
)
