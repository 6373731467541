import {IAppliedCoupon} from '../../types/app.types';
import {Coupon, MultiCurrencyPrice} from '@wix/ecom_current-cart';
import {PriceModel} from './Price.model';

export class AppliedCouponModel {
  public id: string;
  public code: string;
  /**
   * @deprecated
   */
  public name?: string;
  public amount: PriceModel;

  constructor(params: AppliedCouponModel) {
    this.id = params.id;
    this.code = params.code;
    this.name = params.name;
    this.amount = params.amount;
  }

  public static fromGQL(
    appliedCoupon: IAppliedCoupon,
    {
      convertedAmount,
      formattedConvertedAmount,
    }: {
      convertedAmount?: number;
      formattedConvertedAmount?: string;
    }
  ) {
    return new AppliedCouponModel({
      id: appliedCoupon?.couponId,
      code: appliedCoupon?.code,
      name: appliedCoupon?.name,
      amount: PriceModel.fromGQL({convertedAmount, formattedConvertedAmount}),
    });
  }

  public static fromSDK(appliedCoupon: Coupon & {amount: MultiCurrencyPrice}) {
    return new AppliedCouponModel({
      id: appliedCoupon?._id,
      code: appliedCoupon?.code,
      amount: PriceModel.fromSDK(appliedCoupon?.amount),
    });
  }
}
