import React from 'react';
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor';
import {Tooltip} from 'wix-ui-tpa';
import {useControllerProps} from '../../../../../../../domain/controllers/ControllerContext';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {ShippingDestination} from '../ShippingDestination/ShippingDestination';
import s from './EstimatedTax.scss';

const ShippingDestinationAndTaxRates = () => {
  const {t} = useTranslation();
  const {shouldDisplayEstimatedTaxShippingDestination, taxName} = useControllerProps().orderStore;
  const {formattedConvertedAmount} = useControllerProps().cartStore.estimatedTotals.priceSummary.tax;
  const {isMobile, isRTL} = useEnvironment();
  const pointToMove = isRTL ? /* istanbul ignore next */ {x: -60, y: 0} : {x: 60, y: 0};

  return (
    <dl>
      <dt>
        <div data-hook={OrderSummaryDataHook.EstimatedTaxShippingDestinationWithTitle}>
          <span data-hook={OrderSummaryDataHook.EstimatedTaxTaxName}>
            {taxName || /* istanbul ignore next */ t('cart.estimated_tax')}
          </span>
          &nbsp;
          <Tooltip
            moveBy={isMobile ? /* istanbul ignore next */ pointToMove : {x: 0, y: 0}}
            content={t('cart.tooltips.estimated_tax_message')}
            placement="top"
            minWidth={200}
            maxWidth={250}>
            <span className={s.info}>?</span>
          </Tooltip>
          {shouldDisplayEstimatedTaxShippingDestination && (
            <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationTaxRoot} />
          )}
        </div>
      </dt>
      <dd
        data-hook={OrderSummaryDataHook.EstimatedTaxEstimatedTaxPrice}
        data-wix-estimated-tax-price={OrderSummaryDataHook.EstimatedTaxEstimatedTaxPrice}>
        {formattedConvertedAmount}
      </dd>
    </dl>
  );
};

export const EstimatedTax = () => {
  const {isEstimatedDestinationIsShippable, shouldDisplayEstimatedTaxShippingDestination} =
    useControllerProps().orderStore;

  return (
    <div data-hook={OrderSummaryDataHook.EstimatedTaxRoot}>
      {isEstimatedDestinationIsShippable ? (
        <ShippingDestinationAndTaxRates />
      ) : (
        shouldDisplayEstimatedTaxShippingDestination && (
          <dt>
            <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationTaxRoot} />
          </dt>
        )
      )}
    </div>
  );
};
