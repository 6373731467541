import type { CSSProperties } from 'react';
import { Alignment } from 'root/consts';
import { getAlignmentText } from 'root/utils/alignment';
import { Preset } from 'root/utils/presets';
import { useGetAlignment } from 'root/hooks/useGetAlignment';

export enum AlignmentProperty {
  JustifySelf = 'justifySelf',
  TextAlign = 'textAlign',
}

export const useGetItemTextAlignment = (preset: Preset, alignmentProperty: AlignmentProperty) => {
  const gridItemAlignment = useGetAlignment('gridItemAlignment');

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridItemAlignment,
    [Preset.Columns]: getAlignmentText(Alignment.Start),
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: getAlignmentText(Alignment.Start),
  };

  const alignment = {
    [alignmentProperty]: alignmentMap[preset] as CSSProperties[typeof alignmentProperty],
  };

  return alignment;
};
