import React from 'react';
import type { PopulatedMenu } from 'root/apiTypes';
import { st, classes } from './Menu.st.css';
import { classes as global } from 'root/components/Menus/Global.st.css';
import { dataHooks } from 'root/dataHooks';
import { Sections } from 'root/components/Menus/Sections/Sections';
import { Text, TextTypography } from 'wix-ui-tpa/cssVars';
import { useMenuLogic } from './Menu.logic';
import { MENU_TITLE_ID } from 'root/consts';

export type MenuProps = {
  menu: PopulatedMenu;
};

export const Menu = ({ menu }: MenuProps) => {
  const { showMenuName, showMenuDescription, textAlignCss, menuTitleTheme, menuDescriptionTheme } = useMenuLogic();
  const menuTitleId = MENU_TITLE_ID(menu.id!);

  return (
    <div className={classes.menuContainer} data-hook={dataHooks.menu.container} style={textAlignCss}>
      {showMenuName && (
        <Text
          className={st(global.fullWidth, classes.menuTitle)}
          data-hook={dataHooks.menu.name}
          id={menuTitleId}
          tagName={menuTitleTheme.htmlTag}
          typography={TextTypography.largeTitle}
        >
          {menu.name}
        </Text>
      )}
      {showMenuDescription && (
        <Text
          className={st(global.fullWidth, classes.menuDescription)}
          data-hook={dataHooks.menu.description}
          tagName={menuDescriptionTheme.htmlTag}
          typography={TextTypography.largeTitle}
        >
          {menu.description}
        </Text>
      )}
      <Sections menuTitleId={menuTitleId} sections={menu.sections} />
    </div>
  );
};
