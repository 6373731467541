import {CartType, ProductType, ShippingRuleStatus} from '@wix/wixstores-client-core';
import type {
  CartActions,
  ICart as ICartFromCartApi,
  ICurrentCartService,
  SelectedShippingOption,
  SiteStore,
} from '@wix/wixstores-client-storefront-sdk';
import {
  CheckoutApi,
  // eslint-disable-next-line import/no-deprecated
  intToGuid,
} from '@wix/wixstores-client-storefront-sdk';
import {CreateCheckoutExceptions, FedopsInteractions} from '../../common/constants';
import {ICartControllerApi, IOptionsSelectionsValue, IRenderingConfig} from '../../types/app.types';
import {BIService} from './BIService';
import {StyleSettingsService} from './StyleSettingsService';
import {SPECS} from '../specs';
import {CartModel} from '../models/Cart.model';
import {LineItemModel} from '../models/LineItem.model';
import {EstimatedTotalsModel} from '../models/EstimatedTotals.model';
import {ViolationSeverity} from '../models/Violation.model';
import {Cart, DeliveryLogistics, EstimateTotalsResponse, MultiCurrencyPrice} from '@wix/ecom_current-cart';

type CartWithPrivateFields = Cart & {subtotalAfterDiscounts: MultiCurrencyPrice; subtotal: MultiCurrencyPrice};
type DeliveryLogisticsWithPrivateFields = DeliveryLogistics & {deliveryTimeSlot: string};

const bookingsAppId = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';

export type CouponError = {
  code: string;
  message: string;
};

type IEnrichedData = {
  renderingConfigMap: Record<string, IRenderingConfig>;
  bookingsOptionsMap: Record<string, IOptionsSelectionsValue[]>;
};

export class CartService {
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutApi: CheckoutApi;
  private readonly cartActions: CartActions;
  private readonly currentCartService: ICurrentCartService;
  public couponError: CouponError = null;
  public cartModel: CartModel;
  public estimatedTotals: EstimatedTotalsModel;
  public checkoutId: string;
  public origin: string;
  public hasError: boolean;
  public isSummaryLoading: boolean = false;
  public controllerApi: ICartControllerApi;
  public loadingItems: number[] = [];

  constructor({
    siteStore,
    biService,
    styleSettingsService,
    currentCartService,
    origin,
    controllerApi,
  }: {
    controllerApi: ICartControllerApi;
    siteStore: SiteStore;
    biService: BIService;
    styleSettingsService: StyleSettingsService;
    currentCartService: ICurrentCartService;
    origin: string;
  }) {
    this.siteStore = siteStore;
    this.biService = biService;
    this.styleSettingsService = styleSettingsService;
    this.currentCartService = currentCartService;
    this.origin = origin;
    this.checkoutApi = new CheckoutApi({siteStore, origin});
    this.cartActions = this.currentCartService.cartActions;
    this.controllerApi = controllerApi;
  }

  public clearLoadingItems = () => {
    this.loadingItems = [];
  };

  public showLoaderOnItem = (cartItemId: number) => {
    this.loadingItems.push(cartItemId);
    this.isSummaryLoading = true;
  };

  private hasBookingsItem(cart: Cart) {
    return cart?.lineItems.some((lineItem) => lineItem?.catalogReference?.appId === bookingsAppId);
  }

  private async getEnrichedData(): Promise<IEnrichedData> {
    const cartGql = await this.getCartGql();
    const renderingConfigMap = cartGql.items.reduce((acc, item) => {
      acc[item.cartItemId] = item.renderingConfig;
      return acc;
    }, {});
    const bookingsOptionsMap = cartGql.items.reduce((acc, item) => {
      acc[item.cartItemId] = item.optionsSelectionsValues;
      return acc;
    }, {});
    return {renderingConfigMap, bookingsOptionsMap};
  }

  public async fetchCartFromSDK(): Promise<void> {
    const cart = await this.currentCartService.getCurrentCart();
    if (this.hasBookingsItem(cart)) {
      const {renderingConfigMap, bookingsOptionsMap} = await this.getEnrichedData();
      this.setCartModel(cart, renderingConfigMap, bookingsOptionsMap);
    } else {
      this.setCartModel(cart);
    }
  }

  public async isPreselectedNonShippingFlow(): Promise<boolean> {
    try {
      const estimatedTotals = await this.currentCartService.estimateCurrentCartTotals({
        calculateShipping: true,
        calculateTax: this.shouldCalculateTax(),
      });
      const isPickup =
        !!estimatedTotals.shippingInfo.selectedCarrierServiceOption.logistics?.pickupDetails?.address?.addressLine1;
      const hasTimeSlots = Boolean(
        (estimatedTotals.shippingInfo.selectedCarrierServiceOption.logistics as DeliveryLogisticsWithPrivateFields)
          .deliveryTimeSlot
      );
      return isPickup || hasTimeSlots;
    } catch (e) {
      await this.controllerApi.reportFedops(FedopsInteractions.errorInEstimateTotalsWithShipping);
      return false;
    }
  }

  public async fetchEstimateTotals(): Promise<void> {
    const estimatedTotals = await this.currentCartService.estimateCurrentCartTotals({
      calculateShipping: this.shouldCalculateShipping(),
      calculateTax: this.shouldCalculateTax(),
    });
    this.setEstimatedTotalModel(estimatedTotals);
  }

  public async fetchCartAndEstimateTotals(): Promise<void> {
    if (this.shouldFetchCartFromSdk()) {
      await this.fetchCartFromSDK();
      this.isSummaryLoading = this.shouldEstimateTotals();

      if (!this.siteStore.isSSR() && this.shouldEstimateTotals()) {
        void this.fetchEstimateTotals().then(() => {
          this.isSummaryLoading = false;
          void this.controllerApi.updateComponent();
        });
      } else {
        this.removeEstimatedTotals();
      }
    } else {
      const cartGql = await this.getCartGql();
      this.isSummaryLoading = false;
      this.setCartGQLModel(cartGql);
      this.setGQLEstimatedTotalModel(cartGql);
    }
  }

  private shouldFetchCartFromSdk(): boolean {
    const {shouldShowShipping, shouldShowTax} = this.styleSettingsService;

    const shouldFetchForShippingOrTax =
      this.siteStore.experiments.enabled(SPECS.CartFromSDKWhenShowShippingOrShowTax) &&
      (shouldShowShipping || shouldShowTax);

    const shouldFetchForMS1 =
      this.siteStore.experiments.enabled(SPECS.CartFromSDKWhenShowShippingOrShowTax) &&
      this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk);

    return shouldFetchForShippingOrTax || shouldFetchForMS1;
  }

  private shouldCalculateTax(): boolean {
    const {shouldShowTax} = this.styleSettingsService;
    return shouldShowTax && !this.siteStore.priceSettings?.taxOnProduct;
  }

  private shouldCalculateShipping(): boolean {
    const {shouldShowShipping} = this.styleSettingsService;
    return shouldShowShipping && !this.isNonShippableCart;
  }

  private isCartWithDepositItem(): boolean {
    return this.cartModel.lineItems.some((lineItem) => lineItem.depositAmount);
  }

  public shouldEstimateTotals(): boolean {
    if (!this.shouldFetchCartFromSdk()) {
      return false;
    }

    if (
      this.siteStore.experiments.enabled(SPECS.CartFromSDKWhenShowShippingOrShowTax) &&
      !this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk)
    ) {
      return this.cartModel.lineItems.length > 0;
    }

    const {isExpressCheckoutButtonShownInSomeBreakpoint} = this.styleSettingsService;

    return (
      this.cartModel.lineItems.length > 0 &&
      (this.shouldCalculateShipping() ||
        this.shouldCalculateTax() ||
        isExpressCheckoutButtonShownInSomeBreakpoint ||
        this.isCartWithDepositItem() ||
        this.siteStore.isEditorMode())
    );
  }

  private getCartGql() {
    const {shouldShowShipping, shouldShowTax} = this.styleSettingsService;
    return this.currentCartService.getCurrentCartGQL({
      withShipping: shouldShowShipping,
      withTax: shouldShowTax,
    });
  }

  public get cartType(): CartType {
    const hasDigital = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.DIGITAL);
    const hasPhysical = this.hasShippableItems;
    const hasService = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.SERVICE);
    const hasGiftCard = this.cartModel?.lineItems.some((lineItem) => lineItem.itemType === ProductType.GIFT_CARD);
    const hasMultiVerticalItems = (hasDigital || hasPhysical) && (hasService || hasGiftCard);

    if (hasMultiVerticalItems) {
      return CartType.MIXED_VERTICALS;
    }

    /* istanbul ignore next */
    if (hasDigital && hasPhysical) {
      return CartType.MIXED;
    } else if (hasDigital) {
      return CartType.DIGITAL;
    } else if (hasPhysical) {
      return CartType.PHYSICAL;
    } else if (hasService) {
      return CartType.SERVICE;
    } else if (hasGiftCard) {
      return CartType.GIFT_CARD;
    } else {
      return CartType.UNRECOGNISED;
    }
  }

  public get isNonShippableCart(): boolean {
    return !this.hasShippableItems;
  }

  public get hasShippableItems(): boolean {
    return this.cartModel?.lineItems.some(
      (lineItem) => !lineItem.itemType || lineItem.itemType === ProductType.PHYSICAL
    );
  }

  public get isZeroCart(): boolean {
    if (this.estimatedTotals) {
      return this.estimatedTotals.priceSummary.total.convertedAmount === 0;
    } else {
      return this.cartModel.subtotalAfterDiscounts.convertedAmount === 0;
    }
  }

  public get isEmpty(): boolean {
    return !this.cartModel?.lineItems.length;
  }

  public get areAllItemsInStock(): boolean {
    return (
      this.cartModel?.lineItems &&
      this.cartModel.lineItems.every(
        (lineItem) => lineItem.quantityAvailable === undefined || lineItem.quantityAvailable > 0
      )
    );
  }

  public get isFullAddressRequired() {
    return this.estimatedTotals?.shippingInfo?.status === ShippingRuleStatus.FullAddressRequired;
  }

  public get itemsCount(): number {
    return this.cartModel.lineItems.reduce((count, lineItem) => count + lineItem.quantity, 0);
  }

  public get hasErrorViolations(): boolean {
    return this.estimatedTotals?.violations?.some((violation) => violation.severity === ViolationSeverity.error);
  }

  public createCheckout(): Promise<string | {error: string} | undefined> {
    return this.checkoutApi
      .createCheckout(this.cartModel.id)
      .then((id) => (this.checkoutId = id))
      .catch((error) => {
        console.error(error);

        return JSON.stringify(error)
          .toLowerCase()
          .includes(CreateCheckoutExceptions.siteMustAcceptPayments.toLowerCase())
          ? {error: CreateCheckoutExceptions.siteMustAcceptPayments}
          : undefined;
      });
  }

  public readonly updateItemQuantity = async (
    cartItemId: number,
    quantity: number,
    productId: string
  ): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      // eslint-disable-next-line import/no-deprecated
      return this.currentCartService.updateLineItemQuantity({lineItemId: intToGuid(cartItemId), quantity});
    }
    return this.cartActions.updateLineItemQuantityInCart(
      {
        cartId: this.cartModel.id,
        cartItemId,
        quantity,
        productId,
        itemsCount: this.cartModel.lineItems.length,
        cartType: this.cartType,
      },
      {origin: this.origin}
    );
  };

  public readonly updateBuyerNote = async (content: string) => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      await this.currentCartService.updateBuyerNote({buyerNote: content});
    } else {
      await this.cartActions.updateBuyerNote(this.cartModel.id, content);
    }
    this.biService.updateBuyerNote(this.cartModel, !!content);
  };

  public readonly removeItemFromCart = async (lineItem: LineItemModel): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      // eslint-disable-next-line import/no-deprecated
      return this.currentCartService.removeLineItem({lineItemId: intToGuid(lineItem.id)});
    }
    return this.cartActions.removeItemFromCart(
      {
        cartId: this.cartModel.id,
        cartItemId: lineItem.id,
        price: lineItem.price.convertedAmount,
        productId: lineItem.catalogReference.catalogItemId,
        productName: lineItem.productName,
        productType: lineItem.itemType,
        quantity: lineItem.quantity,
        sku: lineItem.sku,
        currency: this.cartModel.currencyCode,
        catalogAppId: lineItem.catalogReference.appId,
      },
      {origin: this.origin}
    );
  };

  public readonly setDestinationForEstimation = async (
    {
      country,
      subdivision,
      zipCode,
    }: {
      country: string;
      subdivision?: string;
      zipCode?: string;
    },
    cartId: string
  ): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      return this.currentCartService.setAddress({address: {country, subdivision, zipCode}});
    }
    return this.cartActions.setDestinationForEstimation({destination: {country, subdivision, zipCode}}, cartId);
  };

  public readonly setShippingOption = async (
    cartId: string,
    selectedShippingOption: SelectedShippingOption
  ): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      return this.currentCartService.selectShippingOption({selectedShippingOption});
    }
    return this.cartActions.setShippingOption(cartId, selectedShippingOption);
  };

  public get isMemberLoggedIn(): boolean {
    return !!this.siteStore.usersApi.currentUser && !!this.siteStore.usersApi.currentUser.id;
  }

  public readonly clearCouponError = (): void => {
    this.couponError = null;
  };

  public readonly applyCoupon = async (couponCode: string): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ErrorMessage) && !couponCode) {
      const errorCode = 'ERROR_EMPTY_INPUT';
      this.biService.errorWhenApplyingACouponSf(this.cartModel, couponCode, errorCode);
      this.couponError = {
        code: errorCode,
        message: '',
      };
      throw new Error(errorCode);
    }

    const userIdentifier = this.siteStore.usersApi.currentUser.loggedIn
      ? await this.siteStore.usersApi.currentUser.getEmail()
      : undefined;

    const useActionsDirectlyFromCurrentCartService = this.siteStore.experiments.enabled(
      SPECS.ActionsDirectlyFromCurrentCartService
    );
    const applyCouponPromise = useActionsDirectlyFromCurrentCartService
      ? this.currentCartService.applyCoupon({code: couponCode})
      : this.cartActions.applyCouponToCart({
          cartId: this.cartModel.id,
          couponCode,
          userIdentifier,
          isMember: this.isMemberLoggedIn,
        });

    await applyCouponPromise.catch((e) => {
      /* istanbul ignore else */
      if (e.success === false) {
        const errorCode = e.errors[0].code;
        this.biService.errorWhenApplyingACouponSf(this.cartModel, couponCode, errorCode);
        this.couponError = {
          code: errorCode,
          message: e.errors[0].message,
        };
      }
      throw e;
    });
  };

  public readonly removeCoupon = (): Promise<void> => {
    if (this.siteStore.experiments.enabled(SPECS.ActionsDirectlyFromCurrentCartService)) {
      return this.currentCartService.removeCoupon();
    }
    const appliedCoupon = this.estimatedTotals?.appliedCoupon || this.cartModel.appliedCoupon;
    return this.cartActions.removeCouponFromCart({
      cartId: this.cartModel.id,
      couponId: appliedCoupon.id,
      couponCode: appliedCoupon.code,
    });
  };

  public readonly setHasErrorState = (value: boolean) => (this.hasError = value);

  private readonly setCartGQLModel = (cart: ICartFromCartApi) => (this.cartModel = CartModel.fromGQL(cart));
  private readonly setCartModel = (
    cart: Cart,
    renderingConfigMap?: Record<string, IRenderingConfig>,
    bookingsOptionsMap?: Record<string, IOptionsSelectionsValue[]>
  ) => (this.cartModel = CartModel.fromSDK(cart as CartWithPrivateFields, renderingConfigMap, bookingsOptionsMap));
  private readonly setGQLEstimatedTotalModel = (cart: ICartFromCartApi) =>
    (this.estimatedTotals = EstimatedTotalsModel.fromGQL(cart));
  private readonly setEstimatedTotalModel = (estimatedTotals: EstimateTotalsResponse) =>
    (this.estimatedTotals = EstimatedTotalsModel.fromSDK(estimatedTotals));
  private readonly removeEstimatedTotals = () => (this.estimatedTotals = undefined);
}
