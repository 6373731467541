import React, { type FC, type SyntheticEvent, useCallback } from 'react';
import { Image } from 'wix-ui-tpa/cssVars';
import ccIconURI from '@assets/icons/creditCard.svg?url';

type Props = {
  url: string;
  altText?: string;
};

export const PaymentMethodIcon: FC<Props> = (props) => {
  const { url, altText = '' } = props;

  const handleError = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = ccIconURI;
  }, []);

  return (
    <Image
      src={url}
      alt={altText}
      width={45}
      height={28}
      errorMessage=""
      onError={handleError}
    />
  );
};
