import type { DisplaySettingOption } from './types';

export const BASE_MEDIA = 'https://static.wixstatic.com/media/';

export const MENU_NAVIGATION = 'comp-ldj6yhu7';
export const MENU_NAME = 'comp-ldj71xga';
export const MENU_DESCRIPTION = 'comp-ldj73gab';
export const SECTION_NAME = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-ler3xely';
export const SECTION_DESCRIPTION = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lezfxmd5';
export const ITEM_DESCRIPTION = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lerepn9i_r_comp-lemrxvc2';
export const ITEM_PRICE = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lerepn9i_r_comp-lemryyde';
export const ITEM_LABEL = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lerepn9i_r_comp-lems2gwu';
export const ITEM_IMAGE = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lerepn9i_r_comp-levf2zda';
export const VARIANT_NAME = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lerepn9i_r_comp-limrj2bj_r_comp-ljlbzu37';
export const VARIANT_PRICE = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lerepn9i_r_comp-limrj2bj_r_comp-limqx2zu1';
export const ITEM_DIVIDER = 'comp-ldlm5li3_r_comp-ler41nf1_r_comp-lerepn9i_r_comp-lemrvq81';

export enum DisplaySettings {
  showTabs = 'showTabs',
  showMenuName = 'showMenuName',
  showMenuDescription = 'showMenuDescription',
  showSectionName = 'showSectionName',
  showSectionDescription = 'showSectionDescription',
  showItemDescription = 'showItemDescription',
  showItemPrice = 'showItemPrice',
  showItemLabels = 'showItemLabels',
  showItemImage = 'showItemImage',
  showItemVariantsName = 'showItemVariantsName',
  showItemVariantsPrice = 'showItemVariantsPrice',
  showItemDividers = 'showItemDividers',
}

export const displaySettingOptions: DisplaySettingOption[] = [
  {
    key: MENU_NAVIGATION,
    prop: DisplaySettings.showTabs,
  },
  {
    key: MENU_NAME,
    prop: DisplaySettings.showMenuName,
  },
  {
    key: MENU_DESCRIPTION,
    prop: DisplaySettings.showMenuDescription,
  },
  {
    key: SECTION_NAME,
    prop: DisplaySettings.showSectionName,
  },
  {
    key: SECTION_DESCRIPTION,
    prop: DisplaySettings.showSectionDescription,
  },
  {
    key: ITEM_DESCRIPTION,
    prop: DisplaySettings.showItemDescription,
  },
  {
    key: ITEM_PRICE,
    prop: DisplaySettings.showItemPrice,
  },
  {
    key: ITEM_LABEL,
    prop: DisplaySettings.showItemLabels,
  },
  {
    key: ITEM_IMAGE,
    prop: DisplaySettings.showItemImage,
  },
  {
    key: VARIANT_NAME,
    prop: DisplaySettings.showItemVariantsName,
  },
  {
    key: VARIANT_PRICE,
    prop: DisplaySettings.showItemVariantsPrice,
  },
  {
    key: ITEM_DIVIDER,
    prop: DisplaySettings.showItemDividers,
  },
];
