import type { I$W, TFunction } from '@wix/yoshi-flow-editor';
import { FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { DispatchType } from '../../types/businessTypes';
import type model from './model';
import type { ControllerParams } from 'root/types/widgets';
import { fulfillmentPickerStore } from 'root/states/FulfillmentPickerStore';

const DISPATCH_TYPE_STATE = {
  selected: 'selected',
  default: 'default',
  disabled: 'disabled',
} as const;

const TRANSLATION_KEY = {
  [DispatchType.DELIVERY]: 'header_olo.delivery',
  [DispatchType.PICKUP]: 'header_olo.pickup',
};

const getDispatchTypeState = (
  dispatchType: DispatchType,
  selectedDispatchType: DispatchType,
  availableDispatchTypes: DispatchType[]
) => {
  if (!availableDispatchTypes.includes(dispatchType)) {
    return DISPATCH_TYPE_STATE.disabled;
  } else if (selectedDispatchType === dispatchType) {
    return DISPATCH_TYPE_STATE.selected;
  } else {
    return DISPATCH_TYPE_STATE.default;
  }
};

type BindAll = ControllerParams<typeof model>['$bindAll'];

export class FulfillmentPickerController {
  constructor(private $w: I$W, private $bindAll: BindAll) {}

  async initFulfillmentPicker(
    t: TFunction,
    configuredDispatchTypes: DispatchType[],
    isLoading: boolean
  ) {
    const configs = [DispatchType.DELIVERY, DispatchType.PICKUP].map((dispatchType) => ({
      componentId: FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[dispatchType],
      collapsed: !configuredDispatchTypes.includes(dispatchType) && !isLoading,
      text: t(TRANSLATION_KEY[dispatchType]),
    }));
    configs.forEach(async (config) => {
      const multiStateBox = this.$w(config.componentId.multiStateBox);
      if (config.collapsed) {
        await multiStateBox.collapse();
      } else if (multiStateBox.collapsed) {
        await this.$w(config.componentId.multiStateBox).expand();
      }
      this.$w(config.componentId.selectedText).text = config.text;
      this.$w(config.componentId.defaultText).text = config.text;
      this.$w(config.componentId.disabledText).text = config.text;
    });
    this.$bindAll({
      [FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS.GENERAL.container]: {
        // @ts-expect-error
        accessibility: {
          role: () => 'group',
          tabIndex: () => (configuredDispatchTypes.length > 1 ? 0 : -1),
          ariaAttributes: {
            label: () => t('header_olo.fulfillmentPicker.ariaLabel'),
          },
        },
      },
    });
  }

  async initButtons() {
    [DispatchType.DELIVERY, DispatchType.PICKUP].forEach(async (dispatchType) => {
      const componentIds = FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[dispatchType];
      const { operation, onDispatchTypeChange, hasPopup = false } = fulfillmentPickerStore;

      if (fulfillmentPickerStore.isOnlyPickupAvailable) {
        if (operation?.operationType === 'ASAP' && !operation.allowAsapFutureHandling) {
          return;
        }
      }

      this.$bindAll({
        [componentIds[DISPATCH_TYPE_STATE.selected]]: {
          onClick: async () => {
            await onDispatchTypeChange?.(dispatchType);
          },
          onKeyPress: async (e: KeyboardEvent) => {
            if (e.key === 'Enter' || e.key === ' ') {
              await onDispatchTypeChange?.(dispatchType);
            }
          },
          accessibility: {
            role: () => 'button',
            tabIndex: () => 0,
            ariaAttributes: {
              pressed: () => 'true',
              haspopup: () => hasPopup.toString(),
            },
          },
        },
        [componentIds[DISPATCH_TYPE_STATE.default]]: {
          onClick: async () => {
            await onDispatchTypeChange?.(dispatchType);
          },
          onKeyPress: async (e: KeyboardEvent) => {
            if (e.key === 'Enter' || e.key === ' ') {
              await onDispatchTypeChange?.(dispatchType);
            }
          },
          accessibility: {
            role: () => 'button',
            tabIndex: () => 0,
            ariaAttributes: {
              pressed: () => 'false',
              haspopup: () => hasPopup.toString(),
            },
          },
        },
        [componentIds[DISPATCH_TYPE_STATE.disabled]]: {
          accessibility: {
            role: () => 'button',
            tabIndex: () => -1,
            ariaAttributes: {
              pressed: () => 'false',
              haspopup: () => 'false',
            },
          },
        },
      });
    });
  }

  async setDispatchState(
    availableDispatchTypes: DispatchType[],
    isLoading: boolean,
    selectedDispatchType?: DispatchType
  ) {
    [DispatchType.DELIVERY, DispatchType.PICKUP].forEach(async (dispatchType) => {
      const state =
        selectedDispatchType && !isLoading
          ? getDispatchTypeState(dispatchType, selectedDispatchType, availableDispatchTypes)
          : DISPATCH_TYPE_STATE.default;

      const componentIds = FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[dispatchType];

      const boxElementId = componentIds[state];

      await this.$w(componentIds.multiStateBox).changeState(boxElementId.slice(1));
    });
  }
}
