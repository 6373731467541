import React from 'react';

const ExpressCheckoutButtons = React.lazy(() =>
  import('@wix/ecom-platform-express-checkout-buttons').then((a) => ({default: a.ExpressCheckoutButtons}))
);

export const LazyExpressCheckoutButtons = (props: React.ComponentProps<typeof ExpressCheckoutButtons>) => {
  return (
    <React.Suspense fallback={<div></div>}>
      <ExpressCheckoutButtons {...props} />
    </React.Suspense>
  );
};
