import { AlignmentText, NavigationAlignment } from 'root/consts';
import { Preset } from 'root/utils/presets';
import { useGetAlignment } from 'root/hooks/useGetAlignment';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const useGetNavigationAlignment = (preset: Preset) => {
  const gridMenuNavigationAlignment = useGetAlignment('gridMenuNavigationAlignment');
  const columnsMenuNavigationAlignment = useGetAlignment('columnsMenuNavigationAlignment');
  const sideBySideMenuNavigationAlignment = useGetAlignment('sideBySideMenuNavigationAlignment');
  const { isRTL } = useEnvironment();

  const getNavigationAlignmentByLanguage = (currentAlignment: string): string => {
    switch (currentAlignment) {
      case AlignmentText.Center:
        return NavigationAlignment.Center;
      case AlignmentText.Start:
        return isRTL ? NavigationAlignment.Right : NavigationAlignment.Left;
      case AlignmentText.End:
      default:
        return isRTL ? NavigationAlignment.Left : NavigationAlignment.Right;
    }
  };

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: getNavigationAlignmentByLanguage(gridMenuNavigationAlignment),
    [Preset.Columns]: getNavigationAlignmentByLanguage(columnsMenuNavigationAlignment),
    [Preset.Center]: NavigationAlignment.Center,
    [Preset.SideBySide]: getNavigationAlignmentByLanguage(sideBySideMenuNavigationAlignment),
  };

  return alignmentMap[preset];
};
