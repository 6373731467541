import type { HorizontalMenuItem, Menu, PopulatedMenu, PopulatedSection } from 'root/types';
import type { SortableMenu, MenusOrder, ShowMenuValue, SetMenuSharedDeviceGfpp, SetMenuGfpp } from './types';
import type { ControllerFlowAPI, ILocation, ISiteApis, StructurePage } from '@wix/yoshi-flow-editor';
import {
  GENERAL_MENUS_ARTICLE_ID,
  HASH_TO_PREVENT_SCROLL,
  MENU_LAYOUT_PANEL_ARTICLE_ID,
  PAGE_DATA,
  PANEL_IDS,
} from 'root/utils/consts';
import type { TPAPageId } from '@wix/platform-editor-sdk';
import type { GfppDesktopBuilder } from '@wix/app-manifest-builder';
import { SHOW_MENUS_OPTIONS } from './consts';

export const getSortableMenusWithOrder = (
  unsortedMenus: Menu[],
  menusDisplayOption: ShowMenuValue,
  menusOrder?: MenusOrder
): SortableMenu[] => {
  const res: SortableMenu[] = [];
  const menusMap = unsortedMenus.reduce(
    (acc: Record<string, Menu>, menu) => ({ ...acc, [menu.id as string]: menu }),
    {}
  );
  menusOrder?.forEach((menuId) => {
    const menu = menusMap[menuId];
    if (menu) {
      res.push({ id: menu.id as string, name: menu.name as string, checked: true, draft: !menu.visible });
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete menusMap[menuId];
    }
  });

  const isShowAllMenus = menusDisplayOption === SHOW_MENUS_OPTIONS.ALL;
  const isMenusOrderNotUpdated = !!menusOrder && isShowAllMenus && menusOrder.length !== unsortedMenus.length;
  const isCheckedByDefault = !menusOrder || isMenusOrderNotUpdated;

  Object.values(menusMap).forEach((menu) => {
    const isDraft = !menu.visible;

    res.push({
      id: menu.id as string,
      name: menu.name as string,
      checked: isDraft ? false : isCheckedByDefault,
      draft: isDraft,
    });
  });
  return res;
};

export const truncate = (str: string, maxChars: number) => {
  return str.length > maxChars ? `${str.slice(0, maxChars - 1)}...` : str;
};

export const getMenusSEOData = ({
  currentMenu,
  pageUrl,
  environment,
  menusOrder,
  firstMenu,
}: {
  currentMenu: PopulatedMenu;
  pageUrl: string;
  environment: ControllerFlowAPI['environment'];
  menusOrder: MenusOrder;
  firstMenu?: Menu;
}) => {
  const menuItems = currentMenu.sections.flatMap((section: PopulatedSection) => section.items);
  const { multilingual } = environment;
  const isMainMenu = menusOrder?.[0] === currentMenu.id || firstMenu?.id === currentMenu.id;

  return {
    menu: {
      ...currentMenu,
      url: pageUrl,
      isMainMenu,
    },
    items: menuItems,
    translatedPages: multilingual,
  };
};

export const getIsCurrentPageMenusDefaultPage = async (site: ISiteApis) => {
  const allPages = (await site.getSiteStructure({ includePageId: true })).pages as (StructurePage & {
    tpaPageId: TPAPageId;
  })[];
  const currentPageTpaId = allPages.find((page) => page.id === site.currentPage?.id)?.tpaPageId;
  return currentPageTpaId === PAGE_DATA.pageId;
};

const updateMenusOrderToIncludeAllMenus = (menus: Menu[], menusOrder: MenusOrder): MenusOrder => {
  const res: MenusOrder = [];
  const menusMap = menus.reduce((acc: Record<string, Menu>, menu) => ({ ...acc, [menu.id as string]: menu }), {});

  menusOrder.forEach((menuId) => {
    const menu = menusMap[menuId];
    if (menu) {
      res.push(menu.id as string);
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete menusMap[menuId];
    }
  });

  Object.values(menusMap).forEach((menu) => {
    res.push(menu.id as string);
  });

  return res;
};

export const getFilteredMenusOrder = ({
  menus,
  menusOrder,
  menusDisplayOption,
}: {
  menus: Menu[];
  menusOrder?: MenusOrder;
  menusDisplayOption?: ShowMenuValue;
}): MenusOrder | undefined => {
  if (!menusOrder) {
    return undefined;
  }

  const shouldShowAllMenus = menusDisplayOption === SHOW_MENUS_OPTIONS.ALL;
  const shouldAddNewMenuToMenusOrder =
    shouldShowAllMenus && menus.some((menu) => menu.id && !menusOrder.includes(menu.id));

  if (shouldAddNewMenuToMenusOrder) {
    return updateMenusOrderToIncludeAllMenus(menus, menusOrder);
  }

  return menusOrder.filter((id) => menus.some((menu) => menu.id === id));
};

export const isValidMenuQueryParam = (queryParam?: string): queryParam is string => {
  return !!queryParam && queryParam !== 'undefined';
};

const createCleanLink = (url: string) => {
  const link = new URL(url);
  link.searchParams.delete('menu');
  link.searchParams.delete('menuId');
  link.hash = HASH_TO_PREVENT_SCROLL;
  return link;
};

export const getNavigationDropdownItems = (navigationBarItems: string[], menusMap: Record<string, PopulatedMenu>) => {
  return navigationBarItems.map((menuId) => {
    const menu = menusMap[menuId];

    return {
      value: menuId,
      label: menu.name,
    };
  });
};

export const getNavigationBarItems = (
  navigationBarItems: string[],
  menusMap: Record<string, PopulatedMenu>,
  activeMenuId: string,
  location: ILocation
): HorizontalMenuItem[] => {
  const activeMenu = menusMap[activeMenuId];

  const menuItems: HorizontalMenuItem[] = navigationBarItems.map((menuId, idx) => {
    const menu = menusMap[menuId];
    const link = createCleanLink(location.url);

    if (idx > 0) {
      if (isValidMenuQueryParam(menu?.urlQueryParam)) {
        link.searchParams.set('menu', menu?.urlQueryParam);
      } else {
        link.searchParams.set('menuId', menuId);
      }
    }

    return {
      label: truncate(menu?.name || '', 20),
      link: link.toString(),
      id: menuId,
      selected: isValidMenuQueryParam(activeMenu?.urlQueryParam)
        ? menu?.urlQueryParam === activeMenu?.urlQueryParam
        : (idx === 0 && !activeMenuId) || menuId === activeMenuId,
    };
  });

  return menuItems;
};

const setMenuWidgetCommonGfpp = ({
  gfppBuilder,
  t,
  isMenusBlocksLastTimeExperienceEnabled,
}: SetMenuSharedDeviceGfpp) => {
  if (isMenusBlocksLastTimeExperienceEnabled) {
    gfppBuilder
      .set('mainAction1', {
        label: t('app.gfpp.upgrade'),
        actionId: PANEL_IDS.upgrade,
      })
      .set('mainAction2', { behavior: 'HIDE' })
      // @ts-expect-error
      .set('stretch', { behavior: 'HIDE' })
      // @ts-expect-error
      .set('settings', { behavior: 'HIDE' })
      // @ts-expect-error
      .set('design', { behavior: 'HIDE' })
      // @ts-expect-error
      .set('filters', { behavior: 'HIDE' })
      // @ts-expect-error
      .set('layout', { behavior: 'HIDE' });
  } else {
    const layoutCustomizationAction = {
      label: t('app.gfpp.change-layout'),
      actionId: PANEL_IDS.menuLayoutCustomization,
      helpId: MENU_LAYOUT_PANEL_ARTICLE_ID,
    };

    gfppBuilder
      .set('help', { id: GENERAL_MENUS_ARTICLE_ID })
      .set('mainAction1', {
        label: t('app.gfpp.manage-menus'),
        actionId: PANEL_IDS.manageMenus,
      })
      .set('mainAction2', {
        label: t('app.gfpp.menu-settings'),
        actionId: PANEL_IDS.menuSettings,
      })
      .set('settings', { actionId: PANEL_IDS.settings })
      .set('layout', layoutCustomizationAction);
  }
};

const setMenuWidgetDesktopGfpp = (gfppBuilder: GfppDesktopBuilder<'widget'>) => {
  gfppBuilder.set('connect', { behavior: 'HIDE' });
};

export const setMenuWidgetGfpp = ({
  desktopGfppBuilder,
  mobileGfppBuilder,
  t,
  isMenusBlocksLastTimeExperienceEnabled,
}: SetMenuGfpp) => {
  [desktopGfppBuilder, mobileGfppBuilder].forEach((gfppBuilder) => {
    setMenuWidgetCommonGfpp({
      gfppBuilder,
      t,
      isMenusBlocksLastTimeExperienceEnabled,
    });
  });

  setMenuWidgetDesktopGfpp(desktopGfppBuilder);
};
