import type { ComponentRef, EditorSDK, PresetValue, StyleParam } from '@wix/platform-editor-sdk';
import {
  LIST_1_COLUMN_PRESET_ID,
  LIST_2_COLUMN_PRESET_ID,
  LIST_3_COLUMN_PRESET_ID,
  LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
  SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
  SIDE_BY_SIDE_2_COLUMN_PRESET_ID,
  GRID_PRESET_ID,
  CARD_MOBILE_PRESET_ID,
} from 'root/utils/consts';
import { getPresetName, Preset } from 'root/utils/OOI/consts';
import type { Design, FullDesign, MigrationComponent } from './types';
import type { BreakpointRange, Pointer } from '@wix/document-services-types';

const getPreset = (presetRes?: PresetValue): Pick<Design, 'preset' | 'columns'> => {
  switch (presetRes?.layout) {
    case LIST_1_COLUMN_PRESET_ID:
      return {
        preset: Preset.Center,
      };
    case LIST_2_COLUMN_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 2,
      };
    case LIST_3_COLUMN_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 3,
      };
    case LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case LIST_1_COLUMN_WITH_IMAGE_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case SIDE_BY_SIDE_1_COLUMN_PRESET_ID:
      return {
        preset: Preset.SideBySide,
      };
    case SIDE_BY_SIDE_2_COLUMN_PRESET_ID:
      return {
        preset: Preset.SideBySide,
        columns: 2,
      };
    case CARD_MOBILE_PRESET_ID:
      return {
        preset: Preset.Grid,
        columns: 1,
      };
    case GRID_PRESET_ID:
    default:
      return {
        preset: Preset.Grid,
        columns: 3,
      };
  }
};

const getResponsiveMobileVariant = (component: MigrationComponent) => {
  const breakpoints = Object.fromEntries(
    Object.entries(component.serialize.referredVariants ?? {}).filter(
      (variant): variant is [string, { pointer: Pointer; data: BreakpointRange }] => {
        return variant[1].data.type === 'BreakpointRange';
      }
    )
  );

  const minBreakpoint = Object.entries(breakpoints).reduce((variant1, variant2) => {
    const does1GreaterThan2 = (variant1[1].data.canvasSize ?? 10000) > (variant2[1].data.canvasSize ?? 10000);

    return does1GreaterThan2 ? variant2 : variant1;
  });

  return minBreakpoint[0];
};

export const getBlocksDesign = ({
  component,
  isResponsive,
}: {
  component: MigrationComponent;
  isResponsive: boolean;
}): FullDesign => {
  const desktopPreset = getPreset(component.serialize.presets);

  const variant = isResponsive ? getResponsiveMobileVariant(component) : 'MOBILE-VARIANT';
  const mobilePreset = getPreset(component.serialize.scopedPresets?.[variant]);

  return {
    desktop: desktopPreset,
    mobile: mobilePreset,
  };
};

const getStyleParams = (design: Design) => {
  const styleParams: StyleParam[] = [
    {
      key: 'preset',
      param: {
        value: design.preset,
      },
      type: 'number',
    },
  ];

  if (design.columns) {
    styleParams.push({
      key: `${getPresetName(design.preset)}NumOfColumns`,
      param: {
        value: design.columns,
      },
      type: 'number',
    });
  }

  return styleParams;
};

export const applyOoiDesign = async ({
  editorSDK,
  ref,
  design,
  ooiStyleParams,
}: {
  editorSDK: EditorSDK;
  ref: ComponentRef;
  design: FullDesign;
  ooiStyleParams: StyleParam[];
}) => {
  const styleParams = [...ooiStyleParams, ...getStyleParams(design.desktop)];

  const mobileStyleParams = getStyleParams(design.mobile);

  const mobileSuffix = `▶︎m`;

  const mStyles = mobileStyleParams.map((param) => ({
    ...param,
    key: `${param.key}${mobileSuffix}`,
  }));

  await editorSDK.document.tpa.setStyleParams('', {
    compRef: ref,
    styleParams: [...styleParams, ...mStyles],
  });
};
