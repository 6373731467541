import React, {useEffect, useState} from 'react';
import TagSmall from 'wix-ui-icons-common/on-stage/TagSmall';
import {SummaryDataHooks} from '../Summary';
import {useControllerProps} from '../../Widget/ControllerContext';
import {CodeInputSection} from '../CodeInputSection/CodeInputSection';
import {ILocaleKeys, useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {CheckoutErrorCode} from '../../../../domain/utils/errors';
import {CheckoutErrorModel} from '../../../../domain/models/checkout/CheckoutError.model';

export const CouponInput: React.FC<{mobilePosition?: string}> = ({mobilePosition}) => {
  const localeKeys = useLocaleKeys();
  const {checkout, applyCouponError, applyCoupon, removeCoupon, onAddCouponSectionOpen, isCouponLoading} =
    useControllerProps().checkoutStore;

  const [couponCodeError, setCouponCodeError] = useState(
    applyCouponError ? getErrorTextFromCouponError(applyCouponError, localeKeys) : undefined
  );

  useEffect(() => {
    const error = applyCouponError ?? checkout.errors?.couponError;
    setCouponCodeError(error ? getErrorTextFromCouponError(error, localeKeys) : undefined);
  }, [checkout, applyCouponError, localeKeys]);

  const handleAddCouponSectionOpen = () => {
    onAddCouponSectionOpen(mobilePosition);
  };

  const handleApplyCode = (couponCode: string) => {
    void applyCoupon(couponCode, mobilePosition);
  };

  const handleRemoveCode = () => {
    void removeCoupon(mobilePosition);
  };

  return (
    <CodeInputSection
      dataHook={SummaryDataHooks.couponSectionInput}
      enterACodeButtonText={localeKeys.checkout.coupon.label()}
      onCodeInputOpen={handleAddCouponSectionOpen}
      applyCodeText={localeKeys.checkout.coupon.applyButton()}
      removeCodeText={localeKeys.checkout.coupon.removeButton()}
      codeValue={checkout.appliedCoupon?.code}
      onApplyCode={handleApplyCode}
      onRemoveCode={handleRemoveCode}
      error={couponCodeError}
      icon={<TagSmall />}
      placeholder={localeKeys.checkout.paymentDetails.enterPromoCode.placeholder()}
      isLoading={isCouponLoading}
    />
  );
};

const getErrorTextFromCouponError = (couponError: CheckoutErrorModel, localeKeys: ILocaleKeys): string => {
  switch (couponError?.code) {
    case CheckoutErrorCode.ERROR_COUPON_IS_NOT_ACTIVE_YET:
      return localeKeys.checkout.coupon.notActiveYet.error();
    case CheckoutErrorCode.ERROR_COUPON_HAS_EXPIRED:
      return localeKeys.checkout.coupon.expired.error();
    case CheckoutErrorCode.ERROR_COUPON_USAGE_EXCEEDED:
      return localeKeys.checkout.coupon.maxUsage.error();
    case CheckoutErrorCode.ERROR_COUPON_IS_DISABLED:
      return localeKeys.checkout.coupon.disabled.error();
    case CheckoutErrorCode.ERROR_INVALID_COMMAND_FIELD:
      return localeKeys.checkout.coupon.characterLimit.error();
    case CheckoutErrorCode.ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED:
      return localeKeys.checkout.coupon.alreadyUsed.error();
    case CheckoutErrorCode.ERROR_INVALID_SUBTOTAL:
      return localeKeys.checkout.coupon.invalidSubtotal.error({
        subtotal: couponError?.data?.description,
      });
    case CheckoutErrorCode.ERROR_INVALID_PRODUCTS:
      return localeKeys.checkout.coupon.invalidProducts.error();
    case CheckoutErrorCode.ERROR_INVALID_PRODUCT_QUANTITY:
      return localeKeys.checkout.coupon.productQuantity.error({
        quantity: couponError?.data?.description,
      });
    case CheckoutErrorCode.ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS:
      return localeKeys.checkout.coupon.subscriptions.error();
    case CheckoutErrorCode.ERROR_COUPON_DOES_NOT_EXIST:
    default:
      return localeKeys.checkout.coupon.invalidGeneral.error();
  }
};
