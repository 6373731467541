import React from 'react';
import type { Label as LabelType } from 'root/apiTypes';
import { Label } from 'root/components/Menus/Label/Label';
import { classes } from './Labels.st.css';
import { useLabelsLogic } from './Labels.logic';

export const Labels = ({ labels }: { labels: LabelType[] }) => {
  const { justifySelfCss } = useLabelsLogic();

  return (
    <div className={classes.labels} role="list" style={justifySelfCss}>
      {labels.map((label) => (
        <Label key={label.id} label={label} />
      ))}
    </div>
  );
};
