import {GetState} from '../types/state'
import {StoreExtraArgs} from '../types/store-extra-arg'
import {Interaction} from '../utils/interactions'

export const DETAILS_PAGE_LOADED = 'DETAILS_PAGE_LOADED'
export const FORM_PAGE_LOADED = 'FORM_PAGE_LOADED'
export const THANK_YOU_PAGE_LOADED = 'THANK_YOU_PAGE_LOADED'
export const TICKETED_THANK_YOU_PAGE_LOADED = 'TICKETED_THANK_YOU_PAGE_LOADED'

export const detailsPageLoaded = () => ({type: DETAILS_PAGE_LOADED})
export const formPageLoaded =
  (isCheckout = false) =>
  (dispatch: Function, _getState: GetState, {flowAPI}: StoreExtraArgs) => {
    if (isCheckout) {
      flowAPI.fedops.interactionEnded(Interaction.CreateReservation)
    }
    return dispatch({type: FORM_PAGE_LOADED})
  }
export const thankYouPageLoaded = () => ({type: THANK_YOU_PAGE_LOADED})
export const ticketedThankYouPageLoaded = () => ({type: TICKETED_THANK_YOU_PAGE_LOADED})
