import { createSelector } from '@reduxjs/toolkit';
import type { AppState } from '../rootReducer';
import { removeById } from './utils';

export const isAlreadyFetchedOnce = (state: AppState) =>
  state.savedPaymentMethods.alreadyFetchedOnce;

export const isFetched = (state: AppState) =>
  state.savedPaymentMethods.isFetched;

export const getManagedId = (state: AppState) =>
  state.savedPaymentMethods.managedPaymentMethodId;

export const getDeletedIDsQueue = (state: AppState) =>
  state.savedPaymentMethods.deletedIDsQueue;

export const getAllMethods = (state: AppState) =>
  state.savedPaymentMethods.savedPaymentMethods;

export const getLastUpdatedMethod = (paymentMethodId: string) =>
  createSelector(getAllMethods, (methods) => {
    if (methods) {
      const paymentMethods = removeById(methods, paymentMethodId);

      return paymentMethods.sort((a, b) => {
        if (a.updatedDate && b.updatedDate) {
          return b.updatedDate.getTime() - a.updatedDate.getTime();
        }

        return 0;
      })[0];
    }
  });

export const getMethodById = (paymentMethodId: string) =>
  createSelector(getAllMethods, (methods) =>
    methods?.find((method) => method.id === paymentMethodId),
  );
