import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {OrderSummaryDataHook} from '../OrderSummary';
import commonStyles from '../OrderSummary.scss';
import s from './CouponTotal.scss';
import classnames from 'classnames';

export const CouponTotal = () => {
  const {t} = useTranslation();
  const {cartModel, estimatedTotals, useCouponCodeOnSummary} = useControllerProps().cartStore;
  const appliedCoupon = estimatedTotals?.appliedCoupon || cartModel.appliedCoupon;

  return (
    <div data-hook={OrderSummaryDataHook.CouponRoot} className={classnames(commonStyles.divider, s.root)}>
      <dl>
        <dt>
          <span data-hook={OrderSummaryDataHook.CouponLabel}>{t('cart.coupon')}</span>{' '}
          <span data-hook={OrderSummaryDataHook.CouponCode}>
            {useCouponCodeOnSummary ? appliedCoupon.code : appliedCoupon.name}
          </span>
        </dt>
        <dd
          className={s.value}
          data-hook={OrderSummaryDataHook.CouponDiscountValue}
          data-wix-coupon-discount-value={OrderSummaryDataHook.CouponDiscountValue}>
          -{appliedCoupon.amount.formattedConvertedAmount}
        </dd>
      </dl>
    </div>
  );
};
