import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { settingsParams } from 'root/components/Menus/settingsParams';
import { Preset } from 'root/utils/presets';
import type { ItemImage } from '@wix/restaurants-populated-item-client/dist/types/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import stylesParams from 'root/components/Menus/stylesParams';

export const useSideImageLogic = ({ mobileImage }: { mobileImage: boolean }) => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { showMenusSideImage } = useGetDisplayStylesParams();
  const preset = styles.get(stylesParams.preset);
  const shouldShowMenusSideImagedDesktop = !isMobile && [Preset.SideBySide].includes(preset);
  const shouldShowMenusSideImageMobile = isMobile && showMenusSideImage && [Preset.SideBySide].includes(preset);
  const menusSideImage = settings.get(settingsParams.menusSideImage) as ItemImage;

  return {
    shouldShowMenusSideImage: mobileImage ? shouldShowMenusSideImageMobile : shouldShowMenusSideImagedDesktop,
    menusSideImage,
  };
};
