import type { IHttpClient } from '@wix/yoshi-flow-editor';
import type { PaymentMethod } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { ChargeIntent } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { fetchPaymentMethods } from './http';

export class PaymentMethodService {
  constructor(private readonly httpClient: IHttpClient) {}

  async getRecurringPaymentMethods(
    accountId: string,
  ): Promise<PaymentMethod[] | undefined> {
    const { paymentMethods } = await fetchPaymentMethods(this.httpClient, {
      accountId,
      chargeIntent: ChargeIntent.RECURRING,
    });

    return paymentMethods;
  }
}
