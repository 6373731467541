import {LineItemModel} from '../../../../domain/models/checkout/LineItem.model';
import {SubscriptionFrequency, SubscriptionModel} from '../../../../domain/models/checkout/Subscription.model';
import {ViolationModel, ViolationSeverity} from '../../../../domain/models/checkout/Violation.model';
import {ComWixEcommerceCatalogSpiApiV1PaymentOptionType} from '../../../../gql/graphql';
import {ILocaleKeys} from '../../../../locale-keys/LocaleKeys';

export function getSubscriptionDuration(
  subscription: SubscriptionModel,
  localeKeys: ILocaleKeys,
  shouldUseNewSubscriptionView: boolean
): string {
  if (subscription.isAutoRenewal) {
    return shouldUseNewSubscriptionView
      ? localeKeys.checkout.pricingPlans.duration.label({
          number: undefined,
          frequency: localeKeys.checkout.page_plan_autorenew(),
        })
      : localeKeys.checkout.page_plan_autorenew();
  }

  if (shouldUseNewSubscriptionView) {
    const frequency = getFrequencyText({
      frequency: subscription.frequency,
      cycles: subscription.billingCycles,
      localeKeys,
      shouldUseNewSubscriptionView,
    });

    return localeKeys.checkout.pricingPlans.duration.label({
      number: subscription.billingCycles * subscription.interval,
      frequency,
    });
  }

  const frequency = getFrequencyText({
    frequency: subscription.frequency,
    cycles: subscription.billingCycles,
    localeKeys,
  });

  if (subscription.billingCycles > 1) {
    return localeKeys.checkout.page_plan_duration_description_plural({
      numberOfFrequencyUnits: subscription.billingCycles * subscription.interval,
      frequencyUnitSingular: frequency,
    });
  }

  return localeKeys.checkout.page_plan_duration_description_singular({
    duration: undefined,
    frequencyUnitSingular: frequency,
  });
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getFrequencyText({
  frequency,
  cycles,
  localeKeys,
  shouldUseNewSubscriptionView,
}: {
  frequency: SubscriptionFrequency;
  cycles: number;
  localeKeys: ILocaleKeys;
  shouldUseNewSubscriptionView?: boolean;
}) {
  if (shouldUseNewSubscriptionView) {
    switch (frequency) {
      case SubscriptionFrequency.DAY: {
        return cycles > 1
          ? localeKeys.checkout.pricingPlans.frequency.days.plural()
          : localeKeys.checkout.pricingPlans.frequency.day.singular();
      }
      case SubscriptionFrequency.WEEK: {
        return cycles > 1
          ? localeKeys.checkout.pricingPlans.frequency.weeks.plural()
          : localeKeys.checkout.pricingPlans.frequency.week.singular();
      }
      case SubscriptionFrequency.MONTH: {
        return cycles > 1
          ? localeKeys.checkout.pricingPlans.frequency.months.plural()
          : localeKeys.checkout.pricingPlans.frequency.month.singular();
      }
      case SubscriptionFrequency.YEAR: {
        return cycles > 1
          ? localeKeys.checkout.pricingPlans.frequency.years.plural()
          : localeKeys.checkout.pricingPlans.frequency.year.singular();
      }
    }
  }
  switch (frequency) {
    case SubscriptionFrequency.DAY: {
      return cycles > 1 ? localeKeys.checkout.page_plan_days() : localeKeys.checkout.page_plan_day();
    }
    case SubscriptionFrequency.WEEK: {
      return cycles > 1 ? localeKeys.checkout.page_plan_weeks() : localeKeys.checkout.page_plan_week();
    }
    case SubscriptionFrequency.MONTH: {
      return cycles > 1 ? localeKeys.checkout.page_plan_months() : localeKeys.checkout.page_plan_month();
    }
    case SubscriptionFrequency.YEAR: {
      return cycles > 1 ? localeKeys.checkout.page_plan_years() : localeKeys.checkout.page_plan_year();
    }
  }
}

export function getPaymentTypeLabel(lineItem: LineItemModel, localeKeys: ILocaleKeys): string {
  const membershipName = lineItem.selectedMembership?.name?.original;

  switch (lineItem.paymentOption) {
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.DEPOSIT_ONLINE:
      return localeKeys.checkout.myCartPaymentMethod.deposit.lineItem({price: lineItem.depositAmount?.formattedAmount});
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.MEMBERSHIP:
      return membershipName
        ? localeKeys.checkout.myCartPaymentMethod.onlinePlan.lineItem({membership: membershipName})
        : localeKeys.checkout.myCartPaymentMethod.payWithPlan.lineItem();
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.MEMBERSHIP_OFFLINE:
      return membershipName
        ? localeKeys.checkout.myCartPaymentMethod.offlinePlan.lineItemWithId({membership: membershipName})
        : localeKeys.checkout.myCartPaymentMethod.offlinePlan.lineItemWithoutId();
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.FULL_PAYMENT_OFFLINE:
      return lineItem.prices.lineItemPrice.amount > 0
        ? localeKeys.checkout.myCartPaymentMethod.offlinePayment.lineItem()
        : '';
    default:
      return '';
  }
}

export function isLineItemErrorViolationExist(violations: ViolationModel[]): boolean {
  return violations.some(
    (violation) => !!violation.target?.lineItemTarget && violation.severity === ViolationSeverity.error
  );
}
export function getLineItemViolationIfItExists(
  violations: ViolationModel[],
  lineItemId: string
): ViolationModel | undefined {
  return (
    violations.find(
      (violation) =>
        violation.target?.lineItemTarget?.id === lineItemId && violation.severity === ViolationSeverity.error
    ) ??
    violations.find(
      (violation) =>
        violation.target?.lineItemTarget?.id === lineItemId && violation.severity === ViolationSeverity.warning
    )
  );
}
