import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { AppError } from '@constants';

type State = {
  appError?: AppError;
};

const initialState: State = {
  appError: undefined,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<AppError>) => {
      state.appError = action.payload;
    },
    clearError: () => initialState,
  },
});

export const { reducer: appReducer, actions: appActions } = appSlice;
