import type model from './model';
import { NAVIGATION_TOOLBAR_IDS } from '../../appConsts/blocksIds';
import { context } from '../../context/RootContext';
import type { I$W } from '@wix/yoshi-flow-editor';
import type { ControllerParams } from '../../types/widgets';
import { LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName } from '@wix/restaurants-bi';
import { navigationMenuState } from 'root/states/NavigationMenuState';

type BindAll = ControllerParams<typeof model>['$bindAll'];

export class NavigationMenuController {
  constructor(private $w: I$W, private $bindAll: BindAll, private isMobile?: boolean) {
    const { pubsub } = context;
    pubsub.subscribe('onHeaderViewportEnter', this.onHeaderViewportEnter);
    pubsub.subscribe('onHeaderViewportLeave', this.onHeaderViewportLeave);
  }

  init() {
    const getCommonParamsMenuDropdownBIEvent = () => ({
      operationId: context.operation?.id,
      numTotalMenus: navigationMenuState.menus?.length,
      currentMenuId: navigationMenuState.selectedMenuId,
      currentMenuName: navigationMenuState.selectedMenu?.name,
      currentMenuNumber: navigationMenuState.menus.findIndex(
        (menu) => menu.id === navigationMenuState.selectedMenuId
      ),
    });
    this.$bindAll({
      [NAVIGATION_TOOLBAR_IDS.menuDropdown]: {
        options: () =>
          navigationMenuState.menus.map((menu) => ({
            label: menu.name,
            value: menu.id,
          })),
        onClick: () => {
          context.biReporterService?.reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent({
            ...getCommonParamsMenuDropdownBIEvent(),
            buttonName: LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName.DROPDOWN,
            menuNumber: undefined,
            menuName: undefined,
            menuId: undefined,
          });
        },
        value: () => navigationMenuState.selectedMenuId,
        onChange: (event: $w.Event) => {
          // TODO: remove ts-expect-error when $w types are fixed
          const menuId = event.target.value;
          context.biReporterService?.reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent({
            ...getCommonParamsMenuDropdownBIEvent(),
            buttonName: LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName.DROPDOWN_MENU_OPTION,
            menuNumber: navigationMenuState.menus.findIndex((menu) => menu.id === menuId),
            menuName: navigationMenuState.menuMap[menuId].name,
            menuId,
          });
          navigationMenuState.setSelectedMenuId(menuId);
          context.pubsub.publish('onScrollToMenu', { menuId });
        },
        collapsed: () => !navigationMenuState.hasMultipleMenus,
      },
      [NAVIGATION_TOOLBAR_IDS.navigationDivider]: {
        collapsed: () => !navigationMenuState.hasMultipleMenus,
      },
      [NAVIGATION_TOOLBAR_IDS.sectionTabs]: {
        menuItems: () => navigationMenuState.sectionTabs,
        // @ts-expect-error
        onItemClick: ({ item }) => {
          context.biReporterService?.reportOloLiveSiteClicksOnMenuBarBiEvent({
            currentSectionNumber: navigationMenuState.selectedMenu?.sections.findIndex(
              (section) => section.id === navigationMenuState.selectedSectionId
            ),
            numTotalSections: navigationMenuState.selectedMenu?.sections?.length,
            operationId: context.operation?.id,
            sectionId: item.id,
            sectionName: navigationMenuState.selectedMenu?.sections.find(
              (section) => section.id === item.id
            )?.name,
          });
          navigationMenuState.setSelectedSectionId(item.id);
          context.pubsub.publish('onScrollToSection', { sectionId: item.id });
        },
        collapsed: () => navigationMenuState.sectionTabs?.length === 0,
      },
    });
  }

  onHeaderViewportLeave = () => {
    if (this.isMobile && navigationMenuState.hasMultipleMenus) {
      this.$w(NAVIGATION_TOOLBAR_IDS.menuDropdown).collapse();
    }
  };

  onHeaderViewportEnter = () => {
    if (this.isMobile && navigationMenuState.hasMultipleMenus) {
      this.$w(NAVIGATION_TOOLBAR_IDS.menuDropdown).expand();
    }
  };
}
