import React from 'react'
import {BreadcrumbsItem} from 'wix-ui-tpa/dist/cssVars/types/components'
import {Breadcrumbs as TPABreadcrumbs} from 'wix-ui-tpa/cssVars'
import {useExperiments} from '@wix/yoshi-flow-editor'

import {EXPERIMENTS} from '../../utils/constants'
import {useGetLayoutSize} from '../LayoutSizeProvider'

import {classes, st} from './styles.st.css'

interface BreadcrumbsProps {
  items: BreadcrumbsItem[]
  className?: string
}

export const Breadcrumbs = ({items, className}: BreadcrumbsProps) => {
  const {layoutSize} = useGetLayoutSize()
  const {experiments} = useExperiments()
  const showBreadcrumbsEnabled = experiments.enabled(EXPERIMENTS.showBreadcrumbs)

  if (!showBreadcrumbsEnabled) {
    return null
  }

  return (
    <TPABreadcrumbs
      className={st('', {[layoutSize]: true}, className, classes.root)}
      items={items}
      showTrailOnMobileMode
    />
  )
}
