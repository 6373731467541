import React, {
  type FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useEnvironment,
  useTranslation,
  useFedopsLogger,
} from '@wix/yoshi-flow-editor';
import { ButtonPriority } from 'wix-ui-tpa/cssVars';
import type { PaymentsWidgetAPI } from '@wix/cashier-payments-widget';
import type { ViewModeOOI } from '@wix/cashier-payments-widget/dist/src/types/ViewMode';
import { PageType } from '@wix/cashier-common/dist/src/enums/PageType';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { PaymentsWidget } from '@wix/cashier-payments-widget/lazy';
import { Interaction } from '@constants';
import { getPaymentsWidgetLocale } from '@utils/locale';
import { isStorybook } from '@utils/environment';
import {
  BiModalCloseTrigger,
  BiModalType,
  BiToastType,
  useBiLogger,
} from '@services/BiLogger';
import { Button } from '@components/Button';
import { InputDialog } from '@components/Modal';
import { useToastContext } from '@components/Toast';
import { useAppContext } from '@components/AppContext';
import { DataHooks } from './DataHooks';
import { classes, st } from './AddModal.st.css';

type Props = {
  currency: string;
  paymentsWidgetHost?: string;
  onSubmit: () => void;
  onClose: () => void;
};

const modalType = BiModalType.AddPaymentMethod;

export const AddPaymentMethodModal: FC<Props> = (props) => {
  const { currency, paymentsWidgetHost, onSubmit, onClose } = props;
  const paymentsWidgetAPI = useRef<PaymentsWidgetAPI>();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isWidgetLoaded, setIsWidgetLoaded] = useState<boolean>(false);
  const { t } = useTranslation();
  const { language, isMobile, isPreview } = useEnvironment();
  const appContext = useAppContext();
  const { showToast } = useToastContext();
  const { biLogger } = useBiLogger();
  const fedops = useFedopsLogger();

  useEffect(() => {
    fedops.interactionEnded(Interaction.LoadAddPaymentMethodModal);
    biLogger?.reportModalLoaded({
      modalType,
    });
  }, [biLogger, fedops]);

  const createCloseHandler = useCallback(
    (action: BiModalCloseTrigger) => () => {
      biLogger?.reportModalClosed({ modalType, action });
      onClose();
    },
    [biLogger, onClose],
  );

  const handleSubmit = useCallback(async () => {
    if (!paymentsWidgetAPI.current) {
      return;
    }

    try {
      setIsAdding(true);
      const { paymentAgreementId } =
        await paymentsWidgetAPI.current.getSavedPaymentMethod();

      biLogger?.reportPaymentMethodAdded({
        paymentAgreementList: paymentAgreementId,
      });

      showToast(t('app.toast.payment-method-added.text'), {
        action: BiToastType.AddedPaymentMethod,
      });
      onSubmit();
      createCloseHandler(BiModalCloseTrigger.Submit)();
    } catch (error) {
      // In case of error, widget should display the error
    }

    setIsAdding(false);
  }, [biLogger, createCloseHandler, onSubmit, showToast, t]);

  const handleWidgetApiInit = useCallback(
    (api: PaymentsWidgetAPI) => {
      fedops.interactionStarted(Interaction.InitializePaymentsWidget);
      paymentsWidgetAPI.current = api;
    },
    [fedops],
  );

  const handleWidgetFullLoad = useCallback(() => {
    fedops.interactionEnded(Interaction.InitializePaymentsWidget);
    setIsWidgetLoaded(true);
  }, [fedops]);

  return (
    <InputDialog
      isOpen
      withoutDivider
      title={t('app.add-payment-method-modal.title')}
      fullscreen={isMobile}
      data-hook={DataHooks.Root}
      customFooter={
        (isWidgetLoaded || isStorybook()) && (
          <div className={st(classes.actions)}>
            <Button
              isLoading={isAdding}
              disabled={isPreview}
              wiringVariant="sitePrimary"
              data-hook={DataHooks.SubmitButton}
              onClick={handleSubmit}
            >
              {t('app.add-payment-method-modal.submit-button.text')}
            </Button>
            <Button
              wiringVariant="siteSecondary"
              priority={ButtonPriority.basicSecondary}
              data-hook={DataHooks.CloseButton}
              onClick={createCloseHandler(
                BiModalCloseTrigger.ClickCancelButton,
              )}
            >
              {t('app.add-payment-method-modal.cancel-button.text')}
            </Button>
          </div>
        )
      }
      closeButtonAriaLabel={t('app.modal.close-button.aria-label')}
      className={st(classes.root, { isMobile })}
      onClose={createCloseHandler(BiModalCloseTrigger.ClickXButton)}
    >
      <div className={st(classes.wrapper)}>
        <div className={st(classes.content)}>
          <PaymentsWidget
            allowRecurringPaymentOnly
            externalSubmitButton
            isSaveCCEnabled
            host={paymentsWidgetHost}
            configuration={{
              currency,
              amount: '0.00',
              locale: getPaymentsWidgetLocale(language),
              isSignedInUser: true,
              appId: appContext.appDefinitionId,
              appInstanceId: appContext.appInstanceId,
              appInstance: appContext.appInstance,
              appSessionId: appContext.appSessionId,
              msid: appContext.metaSiteId ?? '',
              siteOwnerId: appContext.siteOwnerId,
              visitorId: appContext.visitorId,
              viewMode: appContext.viewMode as ViewModeOOI,
            }}
            singlePaymentMethod={PaymentMethod.CreditCard}
            pageType={PageType.UPMComponent}
            onApiInit={handleWidgetApiInit}
            onFullLoad={handleWidgetFullLoad}
          />
        </div>
      </div>
    </InputDialog>
  );
};
