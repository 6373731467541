import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../../baseCustomCssVars';
import {
  CategoryListHorizontalButtonBorderType,
  CategoryListHorizontalOverflow,
  CategoryListLayout,
} from '../../../constants';
import {CategoryListHeaderBehavior, LongCategoryListLayout} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const categoryTreeCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const shouldIndentCategoryList =
    styles.booleans.gallery_showCategoryListHeader &&
    styles.numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT;

  const shouldLimitLongCategoryList = styles.numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT;
  const verticalCategoryList = styles.numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;

  const getCategoryListItemBorder = (borderWidth: number, borderType: CategoryListHorizontalButtonBorderType) => {
    switch (borderType) {
      case CategoryListHorizontalButtonBorderType.None:
        return '0';
      case CategoryListHorizontalButtonBorderType.Underline:
        return `0 0 ${borderWidth}px 0`;
      case CategoryListHorizontalButtonBorderType.Full:
        return `${borderWidth}px`;
    }
  };

  return {
    shouldLimitLongCategoryList: shouldLimitLongCategoryList ? 1 : 0,
    collapsedCategoryListDisplay: shouldLimitLongCategoryList ? '-webkit-box' : 'block',
    categoryListIndentationPx: verticalCategoryList && shouldIndentCategoryList ? '28px' : '0px',
    categoryListMarginBottom: verticalCategoryList ? '12px' : '0',
    horizontalCategoryListFlexWrap:
      styles.numbers.gallery_categoryListHorizontalOverflow === CategoryListHorizontalOverflow.Scroll
        ? 'nowrap'
        : 'wrap',
    categoryListHorizontalBaseButtonBorderWidth: getCategoryListItemBorder(
      styles.numbers.gallery_categoryListHorizontalBaseButtonBorderWidth,
      styles.numbers.gallery_categoryListHorizontalBaseButtonBorderType
    ),
    categoryListHorizontalHoverButtonBorderWidth: getCategoryListItemBorder(
      styles.numbers.gallery_categoryListHorizontalHoverButtonBorderWidth,
      styles.numbers.gallery_categoryListHorizontalHoverButtonBorderType
    ),
    categoryListHorizontalSelectedButtonBorderWidth: getCategoryListItemBorder(
      styles.numbers.gallery_categoryListHorizontalSelectedButtonBorderWidth,
      styles.numbers.gallery_categoryListHorizontalSelectedButtonBorderType
    ),
  };
};

export const categoryTreeShouldRenderVars = ({numbers}: ShouldRenderVarsFnParams) => {
  return {
    categoryListHeaderGoesBackToParent:
      numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT,
    categoryListHeaderDoesNothing: numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.DO_NOTHING,
    shouldLimitCategoryList: numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT,
  };
};
