import {isMobile} from '../../../../../../commons/selectors/environment'
import {getScheduleItems, getScheduleItemsTotal} from '../../../selectors/schedule'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {MobileScheduleRuntimeProps} from './interfaces'
import {MobileSchedule as Presentation} from './mobile-schedule'

export const mapRuntime = ({
  state,
  actions: {navigateToSchedulePage},
}: DetailsPageAppProps): MobileScheduleRuntimeProps => ({
  items: getScheduleItems(state),
  total: getScheduleItemsTotal(state),
  mobile: isMobile(state),
  navigateToSchedulePage,
})

export const MobileSchedule = connect<{}, MobileScheduleRuntimeProps>(mapRuntime)(Presentation)
