import { makeObservable, computed, observable, action } from 'mobx';
import { DispatchType } from 'root/types/businessTypes';
import { dispatchState } from './DispatchState';
import { context } from '../context/RootContext';

class FulfillmentPickerStore {
  selectedDispatchType: DispatchType | undefined = undefined;
  onDispatchTypeChange?: (dispatchType: DispatchType) => Promise<void>;
  hasPopup: boolean | undefined = undefined;
  constructor() {
    makeObservable(this, {
      selectedDispatchType: observable,
      onDispatchTypeChange: observable,
      hasPopup: observable,
      availableDispatchTypes: computed,
      configuredDispatchTypes: computed,
      isOnlyPickupAvailable: computed,
      operation: computed,
      isLoading: computed,
      setObservableProps: action,
    });
  }

  setObservableProps({
    selectedDispatchType,
    onDispatchTypeChange,
    hasPopup,
  }: {
    selectedDispatchType: DispatchType;
    onDispatchTypeChange: (dispatchType: DispatchType) => Promise<void>;
    hasPopup: boolean | undefined;
  }) {
    this.selectedDispatchType = selectedDispatchType;
    this.onDispatchTypeChange = onDispatchTypeChange;
    this.hasPopup = hasPopup;
  }

  get availableDispatchTypes() {
    return dispatchState.availableDispatchTypes;
  }

  get configuredDispatchTypes() {
    return dispatchState.configuredDispatchTypes;
  }

  get operation() {
    return context.operation;
  }

  get isLoading() {
    return dispatchState.isLoading;
  }

  get isOnlyPickupAvailable() {
    return (
      this.availableDispatchTypes?.length === 1 &&
      this.availableDispatchTypes.includes(DispatchType.PICKUP)
    );
  }
}

const fulfillmentPickerStore = new FulfillmentPickerStore();

export { fulfillmentPickerStore };
