import {Cart, LineItem} from '@wix/ecom_current-cart';
import {ICart} from '@wix/wixstores-graphql-schema';

export class CartModel {
  public id?: string;
  public itemsCount?: number;

  constructor(params: CartModel) {
    this.id = params.id;
    this.itemsCount = params.itemsCount;
  }

  public static fromGQL(cart: ICart): CartModel {
    return new CartModel({
      id: cart?.cartId ?? undefined,
      itemsCount: cart?.items ? calculateItemsCount(cart?.items) : undefined,
    });
  }

  public static fromSDK(cart: Cart): CartModel {
    return new CartModel({
      id: cart?._id ?? undefined,
      itemsCount: cart?.lineItems ? calculateItemsCount(cart?.lineItems) : undefined,
    });
  }
}

function calculateItemsCount(items: Partial<LineItem>[]): number | undefined {
  return items.reduce((total, lineItem) => {
    const {availability, quantity} = lineItem;
    const itemCount =
      availability?.quantityAvailable !== undefined
        ? Math.min(availability.quantityAvailable ?? 0, quantity ?? 0)
        : quantity ?? 0;

    return total + (itemCount || 0);
  }, 0);
}
