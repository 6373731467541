import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { st, classes } from './Item.st.css';
import { useItemLogic } from './Item.logic';
import { Labels } from 'root/components/Menus/Labels/Labels';
import { PriceVariants } from 'root/components/Menus/PriceVariants/PriceVariants';
import { Text, Divider, WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { classes as global } from 'root/components/Menus/Global.st.css';

export type ItemProps = {
  item: ShowcasePopulatedItem;
};

export const Item = ({ item }: ItemProps) => {
  const {
    hasPriceVariants,
    priceContent,
    showItemDescription,
    shouldShowPrice,
    shouldShowItemImage,
    textAlignCss,
    shouldShowLabels,
    shouldShowItemDividers,
    itemImage,
    columnImageWidth,
    itemTitleTheme,
    itemDescriptionTheme,
    itemPriceTheme,
  } = useItemLogic({ item });

  return (
    <div className={classes.root} role="listitem">
      <div
        className={st(classes.itemContainer, shouldShowItemImage ? undefined : classes.noImage)}
        data-hook={dataHooks.item.container}
        style={{ ...textAlignCss, '--column-image-width': columnImageWidth } as React.CSSProperties}
      >
        {shouldShowItemDividers && <Divider className={classes.divider} data-hook={dataHooks.item.divider} />}
        <Text
          className={st(global.fullWidth, classes.itemName)}
          data-hook={dataHooks.item.name}
          tagName={itemTitleTheme.htmlTag}
        >
          {item.name}
        </Text>
        {showItemDescription && item.description && (
          <Text
            className={st(global.fullWidth, classes.itemDescription)}
            data-hook={dataHooks.item.description}
            tagName={itemDescriptionTheme.htmlTag}
          >
            {item.description}
          </Text>
        )}
        {hasPriceVariants ? (
          <PriceVariants variants={item.priceVariants?.variants ?? []} />
        ) : (
          shouldShowPrice && (
            <Text
              className={st(global.fullWidth, classes.itemPrice)}
              data-hook={dataHooks.item.price}
              tagName={itemPriceTheme.htmlTag}
            >
              {priceContent}
            </Text>
          )
        )}
        {shouldShowLabels && <Labels data-hook={dataHooks.item.labels} labels={item.labels!} />}
        {shouldShowItemImage ? (
          <WowImage
            shouldUseLQIP
            alt={item.name}
            className={classes.itemImage}
            data-hook={dataHooks.item.image}
            resize={ImageResizeOptions.cover}
            sourceHeight={itemImage?.height}
            sourceWidth={itemImage?.width}
            src={itemImage?.url ?? ''}
          />
        ) : undefined}
        {shouldShowItemDividers && <Divider className={classes.divider} data-hook={dataHooks.item.divider} />}
      </div>
    </div>
  );
};
