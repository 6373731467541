import model from './model';
import { NavigationMenuController } from './navigationMenuController';
import { hasDataChanged } from '../../utils/utils';
import { navigationMenuState } from 'root/states/NavigationMenuState';

export default model.createController(({ $bindAll, $w, $widget, flowAPI }) => {
  const {
    environment: { isMobile },
  } = flowAPI;
  const navigationMenuController = new NavigationMenuController($w, $bindAll, isMobile);
  navigationMenuController.init();

  $widget.onPropsChanged((prevProps, nextProps) => {
    const shouldInit = hasDataChanged({
      prevData: prevProps.data?.menus,
      nextData: nextProps.data?.menus,
    });
    if (shouldInit) {
      navigationMenuState.setMenus(nextProps.data.menus);
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      navigationMenuState.setMenus($widget.props.data.menus);
    },
    exports: {},
  };
});
