import { useMemo } from 'react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { hasItemDivider } from 'root/utils/presets';
import type { ItemProps } from './Item';
import { isSectionChecked, isPriceShowed } from 'root/utils/display';
import type { MenuCheckbox } from 'root/clientTypes';
import { useGetDisplaySettingsParams } from 'root/hooks/useGetDisplaySettingsParams';
import { useItemsContext } from 'root/contexts/ItemsContext';
import type { ItemImage } from 'root/apiTypes';
import { AlignmentProperty, useGetItemTextAlignment } from 'root/hooks/preset/useGetItemTextAlignment';
import { useGetImageWidth } from 'root/hooks/useGetImageWidth';
import { useGetDesignStylesParams } from 'root/hooks/useGetDesignStylesParams';

export const useItemLogic = ({ item }: ItemProps) => {
  const { sectionId } = useItemsContext();
  const {
    showItemDescription,
    showItemPrice,
    showItemCurrency,
    showItemLabels,
    showItemImage,
    showItemDividers,
    showItemLabelsIcon,
    showItemLabelsName,
  } = useGetDisplayStylesParams();
  const { preset } = useGetLayoutStylesParams();
  const {
    checkPricingMenus,
    checkPlaceholderMenu,
    imagePlaceholder,
    showZeroPrice,
    showZeroPriceToAll,
    showImagePlaceholder,
    showPlaceholderToAll,
  } = useGetDisplaySettingsParams();
  const { itemTitleTheme, itemDescriptionTheme, itemPriceTheme } = useGetDesignStylesParams();

  const textAlignCss = useGetItemTextAlignment(preset, AlignmentProperty.TextAlign);
  const columnImageWidth = useGetImageWidth();

  const hasPriceVariants = (item.priceVariants?.variants ?? []).length > 0;

  const priceContent = showItemCurrency
    ? item.priceInfo?.formattedPrice
    : item.priceInfo?.formattedPriceNoCurrency ?? '';

  const priceOptionSectionChecked =
    showZeroPriceToAll || isSectionChecked(checkPricingMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowPrice = isPriceShowed({
    price: item.priceInfo?.price ?? '',
    showPrice: showItemPrice,
    showZeroPrice: showZeroPrice as boolean,
    sectionChecked: priceOptionSectionChecked as boolean,
  });

  const shouldShowItemDividers = showItemDividers && hasItemDivider(preset);

  const someLabelComponentShown = showItemLabelsIcon || showItemLabelsName;
  const shouldShowLabels = item.labels && item.labels.length > 0 && showItemLabels && someLabelComponentShown;

  const imagePlaceholderSectionChecked =
    showPlaceholderToAll || isSectionChecked(checkPlaceholderMenu as MenuCheckbox[], sectionId ?? '');

  const shouldShowImagePlaceholder = useMemo(
    () => (showImagePlaceholder ? imagePlaceholderSectionChecked : false),
    [imagePlaceholderSectionChecked, showImagePlaceholder]
  );
  const shouldShowItemImage = showItemImage && (item?.image || shouldShowImagePlaceholder);
  const itemImage = useMemo(
    () => (item?.image ? item?.image : shouldShowImagePlaceholder ? imagePlaceholder : undefined) as ItemImage,
    [imagePlaceholder, item?.image, shouldShowImagePlaceholder]
  );

  return {
    hasPriceVariants,
    priceContent,
    showItemDescription,
    shouldShowPrice,
    shouldShowItemImage,
    textAlignCss,
    shouldShowLabels,
    shouldShowItemDividers,
    shouldShowImagePlaceholder,
    itemImage,
    columnImageWidth,
    itemTitleTheme,
    itemDescriptionTheme,
    itemPriceTheme,
  };
};
