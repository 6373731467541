import {ViolationModel, ViolationSeverity} from '../models/Violation.model';
import {ViolationOtherTargetName} from '../models/ViolationOtherTarget.model';

export const calcTopThreeViolations = (
  violations: ViolationModel[],
  supportDeliveryViolationOnCart: boolean
): ViolationModel[] => {
  const shouldDisplayViolation = (violation: ViolationModel): boolean => {
    const violationName = violation.target?.otherTarget?.name;
    return (
      violationName === ViolationOtherTargetName.default ||
      (supportDeliveryViolationOnCart && violationName === ViolationOtherTargetName.delivery)
    );
  };

  return violations?.length > 0
    ? [
        ...violations.filter(
          (violation) => violation.severity === ViolationSeverity.error && shouldDisplayViolation(violation)
        ),
        ...violations.filter(
          (violation) => violation.severity === ViolationSeverity.warning && shouldDisplayViolation(violation)
        ),
      ]
        .slice(0, 3)
        .reverse()
    : [];
};

export const calcVisibleViolations = (
  violations: ViolationModel[],
  supportDeliveryViolationOnCart: boolean
): ViolationModel[] => {
  const top3violations = calcTopThreeViolations(violations, supportDeliveryViolationOnCart);
  return [
    ...top3violations,
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.error && Boolean(violation.target?.lineItemTarget?.id)
    ),
    ...violations.filter(
      (violation) => violation.severity === ViolationSeverity.warning && Boolean(violation.target?.lineItemTarget?.id)
    ),
  ];
};
