import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { useGetMenuTextAlignment } from 'root/hooks/preset/useGetMenuTextAlignment';
import { useGetDesignStylesParams } from 'root/hooks/useGetDesignStylesParams';

export const useMenuLogic = () => {
  const { showMenuName, showMenuDescription } = useGetDisplayStylesParams();
  const { preset } = useGetLayoutStylesParams();
  const { menuTitleTheme, menuDescriptionTheme } = useGetDesignStylesParams();

  const textAlignCss = useGetMenuTextAlignment(preset);

  return { showMenuName, showMenuDescription, textAlignCss, menuTitleTheme, menuDescriptionTheme };
};
