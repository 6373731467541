import React from 'react';
import s from '../../CartItem.scss';
import {CartItemDataHook} from '../../CartItem';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';
import {PaymentOptionType} from '@wix/ecom_current-cart';

export const PaymentTypeLabel = ({lineItem}: {lineItem: LineItemModel}) => {
  const {depositAmount, price} = lineItem;
  const {t} = useTranslation();

  const getLabel = () => {
    switch (lineItem.paymentOption) {
      case PaymentOptionType.DEPOSIT_ONLINE:
        return t('cart.myCartPaymentMethod.deposit.lineItem', {price: depositAmount.formattedConvertedAmount});
      case PaymentOptionType.MEMBERSHIP:
        return t('checkout.myCartPaymentMethod.payWithPlan.lineItem');
      case PaymentOptionType.MEMBERSHIP_OFFLINE:
        return t('cart.myCartPaymentMethod.offlinePlan.lineItemWithoutId');
      case PaymentOptionType.FULL_PAYMENT_OFFLINE:
        return price.convertedAmount > 0 && t('cart.myCartPaymentMethod.offlinePayment.lineItem');
      /* istanbul ignore next */
      default:
        return '';
    }
  };

  return (
    <div>
      <span data-hook={CartItemDataHook.PaymentTypeLabel} className={s.paymentLabel}>
        {getLabel()}
      </span>
    </div>
  );
};
