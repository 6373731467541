import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { AlignmentProperty, useGetItemTextAlignment } from 'root/hooks/preset/useGetItemTextAlignment';

export const useLabelsLogic = () => {
  const styles = useStyles();

  const preset = styles.get(stylesParams.preset);

  const justifySelfCss = useGetItemTextAlignment(preset, AlignmentProperty.JustifySelf);

  return { justifySelfCss };
};
