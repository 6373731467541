import {ExperimentNames, getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import s from './full-date-location.scss'

interface FullDateLocationProps {
  event: wix.events.Event
  dataHook?: string
  dateClassName: string
  locationClassName: string
}

export const FullDateLocation = (props: FullDateLocationProps) => {
  const {
    allBreakpoints: {isListFullDateEnabled, isListLocationEnabled, isListDateVisible_V2, isListLocationVisible_V2},
  } = useVisibilityStyles()
  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)
  const dateVisible = listDateLocationFormatSettingEnabled ? isListDateVisible_V2() : isListFullDateEnabled()
  const locationVisible = listDateLocationFormatSettingEnabled ? isListLocationVisible_V2() : isListLocationEnabled()

  return (
    <FullDateLocationBase
      {...props}
      dateVisibilityClass={s.date}
      locationVisibilityClass={s.location}
      dateVisible={dateVisible}
      locationVisible={locationVisible}
    />
  )
}

export const AdditionalInfoFullDateLocation = (props: FullDateLocationProps) => {
  const {
    allBreakpoints: {
      isListFullDateEnabled,
      isListLocationEnabled,
      isListExtraInfoDateVisible_V2,
      isListExtraInfoLocationVisible_V2,
    },
  } = useVisibilityStyles()
  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)
  const dateVisible = listDateLocationFormatSettingEnabled ? isListExtraInfoDateVisible_V2() : isListFullDateEnabled()
  const locationVisible = listDateLocationFormatSettingEnabled
    ? isListExtraInfoLocationVisible_V2()
    : isListLocationEnabled()

  return (
    <FullDateLocationBase
      {...props}
      dateVisibilityClass={s.extraInfoDate}
      locationVisibilityClass={s.extraInfoLocation}
      dateVisible={dateVisible}
      locationVisible={locationVisible}
    />
  )
}

interface FullDateLocationBaseProps extends FullDateLocationProps {
  dateVisibilityClass: string
  locationVisibilityClass: string
  dateVisible: boolean
  locationVisible: boolean
}

const FullDateLocationBase = ({
  event,
  dataHook,
  dateClassName,
  locationClassName,
  dateVisibilityClass,
  locationVisibilityClass,
  dateVisible,
  locationVisible,
}: FullDateLocationBaseProps) => {
  const {fullDate} = useEventDateInformation(event.id)
  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)

  return (
    <div
      data-hook={dataHook ?? DH.fullDateLocation}
      className={listDateLocationFormatSettingEnabled ? s.updatedVisibilityVars : null}
    >
      {dateVisible ? (
        <div className={classNames(dateVisibilityClass, dateClassName)} data-hook="date">
          {fullDate}
        </div>
      ) : null}
      {locationVisible ? (
        <div className={classNames(locationVisibilityClass, locationClassName)} data-hook="location">
          {getFormattedFullLocation(event)}
        </div>
      ) : null}
    </div>
  )
}
