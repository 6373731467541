import {PriceModel} from './Price.model';
import {PriceSummary} from '@wix/ecom_current-cart';

export class PriceSummaryModel {
  public subtotal: PriceModel;
  public shipping: PriceModel;
  public tax: PriceModel;
  public total: PriceModel;
  public additionalFees: PriceModel;

  constructor(params: PriceSummaryModel) {
    this.subtotal = params.subtotal;
    this.shipping = params.shipping;
    this.tax = params.tax;
    this.total = params.total;
    this.additionalFees = params.additionalFees;
  }

  public static fromGQL({
    convertedTotal,
    formattedConvertedTotal,
    convertedShipping,
    formattedConvertedShipping,
    convertedTax,
    formattedConvertedTax,
    formattedConvertedItemsTotal,
    itemsTotal,
    convertedAdditionalFees,
    formattedConvertedAdditionalFees,
  }: {
    total?: number;
    convertedTotal?: number;
    formattedTotal?: string;
    formattedConvertedTotal?: string;
    convertedShipping?: number;
    formattedConvertedShipping?: string;
    convertedTax?: number;
    formattedConvertedTax?: string;
    formattedConvertedItemsTotal?: string;
    itemsTotal?: number;
    convertedAdditionalFees?: number;
    formattedConvertedAdditionalFees?: string;
  }) {
    return new PriceSummaryModel({
      subtotal: PriceModel.fromGQL({
        convertedAmount: itemsTotal,
        formattedConvertedAmount: formattedConvertedItemsTotal,
      }),
      shipping: PriceModel.fromGQL({
        convertedAmount: convertedShipping,
        formattedConvertedAmount: formattedConvertedShipping,
      }),
      tax: PriceModel.fromGQL({
        convertedAmount: convertedTax,
        formattedConvertedAmount: formattedConvertedTax,
      }),
      total: PriceModel.fromGQL({
        convertedAmount: convertedTotal,
        formattedConvertedAmount: formattedConvertedTotal,
      }),
      additionalFees: PriceModel.fromGQL({
        convertedAmount: convertedAdditionalFees,
        formattedConvertedAmount: formattedConvertedAdditionalFees,
      }),
    });
  }

  public static fromSDK(priceSummary: PriceSummary) {
    return new PriceSummaryModel({
      subtotal: PriceModel.fromSDK(priceSummary.subtotal),
      total: PriceModel.fromSDK(priceSummary.total),
      tax: PriceModel.fromSDK(priceSummary.tax),
      additionalFees: PriceModel.fromSDK(priceSummary.additionalFees),
      shipping: PriceModel.fromSDK(priceSummary.shipping),
    });
  }
}
