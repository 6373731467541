/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ISiteApis, IWixAPI, IWixWindow } from '@wix/yoshi-flow-editor';
import { LIGHTBOX_IDS, LIGHTBOX_QUERY_PARAM_KEY } from '../appConsts/consts';
import type { FedopsLogger } from '../utils/monitoring/FedopsLogger';
import type { DishModalProps, DispatchModalProps } from '../types/widgetsProps';
import type { ADD_TO_CART_ERRORS, CartLineItem, ICartService } from './cartService';
import { context } from '../context/RootContext';
import { getSiteStructure } from 'root/utils/siteStructure';

export interface IModalService {
  openDishModal: (data: DishModalProps) => Promise<any>;
  openDispatchModal: (data?: DispatchModalProps) => Promise<any>;
  openErrorModal: (data?: any) => Promise<any>;
  closeModal: (window: IWixWindow, data: any) => Promise<any>;
}

export class ModalService implements IModalService {
  private lightboxIds = new Map<string, string>();
  constructor(
    private wixCodeApi: IWixAPI,
    private fedopsLogger: FedopsLogger,
    private addQueryParamToUrl: boolean,
    private cartService: ICartService
  ) {}

  private setLightboxIds = async (site: ISiteApis) => {
    const siteStructure = await getSiteStructure(site);
    siteStructure.lightboxes.forEach((lightbox) => {
      this.lightboxIds.set(lightbox.tpaPageId, lightbox.id);
    });
  };

  private getLightboxByTpaId = async (tpaPageId: string) => {
    if (this.lightboxIds.keys.length === 0) {
      await this.setLightboxIds(this.wixCodeApi.site);
    }
    const lightboxId = this.lightboxIds.get(tpaPageId);
    if (!lightboxId) {
      throw new Error(`lightbox ${tpaPageId} not found in ${Array.from(this.lightboxIds)}`);
    }
    return lightboxId;
  };

  private addModalQueryParam = () => {
    if (this.addQueryParamToUrl) {
      this.wixCodeApi.location.queryParams.add({ [LIGHTBOX_QUERY_PARAM_KEY]: true.toString() });
    }
  };

  private removeModalQueryParam = () => {
    if (this.addQueryParamToUrl) {
      this.wixCodeApi.location.queryParams.remove([LIGHTBOX_QUERY_PARAM_KEY]);
      // cart icon get last refreshed num of items after queryParams changes
      this.cartService.reloadCart?.();
    }
  };

  openDishModal = async (data: DishModalProps) => {
    this.fedopsLogger.openItemModalStarted({ publishDebugEvent: true });
    this.addModalQueryParam();
    data.openItemModalByQueryParams &&
      this.wixCodeApi.location.queryParams.add({
        itemId: data.item._id || data.item.id,
        sectionId: data.sectionId || '',
        menuId: data.menuId || '',
      });

    const lightboxId = await this.getLightboxByTpaId(LIGHTBOX_IDS.itemModal);

    const modalValue = await this.wixCodeApi.window.openLightboxById(lightboxId, {
      data,
      onModalOpen: () => this.fedopsLogger.openItemModalEnded({ publishDebugEvent: true }),
      closeModal: (
        window: IWixWindow,
        props: Promise<{ cartItem?: CartLineItem; error?: ADD_TO_CART_ERRORS }>,
        cartLineItem?: CartLineItem
      ) => {
        return this.closeModal(window, props, cartLineItem);
      },
    });
    this.removeModalQueryParam();
    data.openItemModalByQueryParams &&
      this.wixCodeApi.location.queryParams.remove(['itemId', 'sectionId', 'menuId']);
    return modalValue;
  };

  openDispatchModal = async (data?: DispatchModalProps) => {
    this.fedopsLogger.openDispatchModalStarted();
    this.addModalQueryParam();

    const lightboxId = await this.getLightboxByTpaId(LIGHTBOX_IDS.dispatchModal);
    const modalValue = await this.wixCodeApi.window.openLightboxById(lightboxId, {
      data,
      onModalOpen: () => this.fedopsLogger.openDispatchModalEnded(),
      closeModal: (window: IWixWindow, props?: DispatchModalProps) =>
        this.closeModal(window, props),
    });
    this.removeModalQueryParam();
    return modalValue;
  };

  openErrorModal = async (data?: any) => {
    this.fedopsLogger.openErrorModalStarted();
    this.addModalQueryParam();

    const lightboxId = await this.getLightboxByTpaId(LIGHTBOX_IDS.errorModal);
    const modalValue = await this.wixCodeApi.window.openLightboxById(lightboxId, {
      data,
      onModalOpen: () => this.fedopsLogger.openErrorModalEnded(),
      closeModal: (window: IWixWindow, props?: object) => this.closeModal(window, props),
    });
    this.removeModalQueryParam();
    return modalValue;
  };

  closeModal = async (
    window: IWixWindow,
    data?: Promise<object> | object,
    additionaldata?: object
  ) => {
    // @ts-expect-error
    return window.lightbox.close({ data, additionaldata });
  };
}

export const setModalMethodsSpy = (spyModalMethods?: (keyof ModalService)[]) => {
  const spyMethods = new Map<keyof IModalService, jest.SpyInstance>();
  for (const method of spyModalMethods ?? []) {
    spyMethods.set(method, jest.spyOn(context.ModalService!, method));
  }
  return spyMethods;
};
