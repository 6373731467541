// eslint-disable-next-line import/no-deprecated
import {guidToInt, ICart as ICartFromCartApi} from '@wix/wixstores-client-storefront-sdk';
import {LineItemModel} from './LineItem.model';
import {AppliedCouponModel} from './AppliedCoupon.model';
import {ContactInfoModel} from './ContactInfo.model';
import {PriceModel} from './Price.model';
import {Cart, CartDiscount, DiscountRule, LineItem, MultiCurrencyPrice} from '@wix/ecom_current-cart';
import {toAppliedCouponSDK} from '../utils/coupon';
import {IOptionsSelectionsValue, IRenderingConfig} from '../../types/app.types';

type CartDiscountWithPrivateFields = CartDiscount & {discountRule?: DiscountRule; lineItemIds: string[]};
export type LineItemWithPrivateFields = LineItem & {
  lineItemPrice: MultiCurrencyPrice;
};

export class CartModel {
  public id: string;
  public lineItems: LineItemModel[];
  public subtotal: PriceModel;
  public buyerNote: string;
  public currencyCode: string;
  public convertedCurrencyCode: string;
  public checkoutId: string;
  public appliedCoupon: AppliedCouponModel;
  public purchaseFlowId: string;
  public contactInfo: ContactInfoModel;
  public subtotalAfterDiscounts: PriceModel;

  constructor(params: CartModel) {
    this.id = params.id;
    this.lineItems = params.lineItems;
    this.subtotal = params.subtotal;
    this.buyerNote = params.buyerNote;
    this.currencyCode = params.currencyCode;
    this.convertedCurrencyCode = params.convertedCurrencyCode;
    this.checkoutId = params.checkoutId;
    this.appliedCoupon = params.appliedCoupon;
    this.purchaseFlowId = params.purchaseFlowId;
    this.contactInfo = params.contactInfo;
    this.subtotalAfterDiscounts = params.subtotalAfterDiscounts;
  }

  public static fromGQL(cart: ICartFromCartApi): CartModel {
    return new CartModel({
      id: cart?.cartId ?? undefined,
      lineItems: (cart?.items ?? []).map((item) => LineItemModel.fromGQL(item)),
      subtotal: PriceModel.fromGQL({
        convertedAmount: cart?.convertedTotals?.itemsTotal,
        formattedConvertedAmount: cart?.convertedTotals?.formattedItemsTotal,
      }),
      buyerNote: cart?.buyerNote ?? undefined,
      currencyCode: cart?.currencyFormat?.code ?? undefined,
      convertedCurrencyCode: cart?.convertedCurrencyFormat?.code ?? undefined,
      checkoutId: cart?.checkoutId ?? undefined,
      appliedCoupon: cart?.appliedCoupon
        ? AppliedCouponModel.fromGQL(cart?.appliedCoupon, {
            convertedAmount: cart.convertedTotals.discount,
            formattedConvertedAmount: cart.convertedTotals.formattedDiscount,
          })
        : undefined,
      purchaseFlowId: cart?.purchaseFlowId ?? undefined,
      contactInfo: cart?.destination ? ContactInfoModel.fromGQL(cart?.destination) : undefined,
      subtotalAfterDiscounts: undefined,
    });
  }

  public static fromSDK(
    cart: Cart & {subtotalAfterDiscounts: MultiCurrencyPrice; subtotal: MultiCurrencyPrice},
    renderingConfigMap?: Record<string, IRenderingConfig>,
    bookingsOptionsMap?: Record<string, IOptionsSelectionsValue[]>
  ): CartModel {
    const lineItemIdToRules = mapLineItemIdToRules(cart.appliedDiscounts as CartDiscountWithPrivateFields[]);
    return new CartModel({
      id: cart._id,
      lineItems: cart.lineItems.map((lineItem: LineItem) =>
        LineItemModel.fromSDK(
          lineItem as LineItemWithPrivateFields,
          lineItemIdToRules[lineItem._id],
          // eslint-disable-next-line import/no-deprecated
          renderingConfigMap?.[guidToInt(lineItem._id)],
          // eslint-disable-next-line import/no-deprecated
          bookingsOptionsMap?.[guidToInt(lineItem._id)]
        )
      ),
      subtotal: PriceModel.fromSDK(cart.subtotal),
      buyerNote: cart.buyerNote,
      currencyCode: cart.currency,
      convertedCurrencyCode: cart.conversionCurrency,
      checkoutId: cart.checkoutId,
      appliedCoupon: toAppliedCouponSDK(cart.appliedDiscounts),
      purchaseFlowId: cart.purchaseFlowId,
      contactInfo: cart.contactInfo ? ContactInfoModel.fromSDK(cart.contactInfo) : undefined,
      subtotalAfterDiscounts: PriceModel.fromSDK(cart.subtotalAfterDiscounts),
    });
  }
}

function mapLineItemIdToRules(appliedDiscounts: CartDiscountWithPrivateFields[]): Record<string, string[]> {
  if (!appliedDiscounts) {
    return {};
  }
  return appliedDiscounts.reduce((acc, discount) => {
    if (!discount.discountRule) {
      return acc;
    }
    discount.lineItemIds?.forEach((lineItemId) => {
      acc[lineItemId] = acc[lineItemId] ?? [];
      acc[lineItemId].push(discount.discountRule.name.translated);
    });
    return acc;
  }, {});
}
