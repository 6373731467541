import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import c from '../../classnames.scss'
import s from './description.scss'
import {DescriptionProps} from './interfaces'

export const Description = ({description}: DescriptionProps) => {
  const {
    allBreakpoints: {isDescriptionVisible},
  } = useVisibilityStyles()

  if (!description || !isDescriptionVisible()) {
    return null
  }

  return (
    <div
      className={classNames(s.description, c.evDescriptionFont, c.evDescriptionColor)}
      data-hook={DH.headerEventDescription}
    >
      {description}
    </div>
  )
}
