import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { hasPriceVariantDividers } from 'root/utils/presets';

export const usePriceVariantsLogic = () => {
  const styles = useStyles();
  const preset = styles.get(stylesParams.preset);

  const { showItemVariantsDivider } = useGetDisplayStylesParams();

  const showDivider = hasPriceVariantDividers(preset) && showItemVariantsDivider;

  return { showDivider };
};
