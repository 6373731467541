import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import type { SavedPaymentMethod, SavedPaymentMethodWithMeta } from '@appTypes';
import {
  getPaymentMethodMetaData,
  type PaymentMethodBrand,
} from '@utils/metaData';

export const getPaymentMethodBrand = (
  paymentMethod: SavedPaymentMethod['paymentMethod'],
): PaymentMethodBrand | undefined => {
  if (paymentMethod.typeId === PaymentMethod.CreditCard) {
    return paymentMethod?.cardInfo?.brand;
  }

  if (paymentMethod.typeId === PaymentMethod.PayPal) {
    return PaymentMethod.PayPal;
  }
};

export const concatWithMetaData = (
  savedPaymentMethods: SavedPaymentMethod[],
): SavedPaymentMethodWithMeta[] => {
  const methodsWithMeta: SavedPaymentMethodWithMeta[] = [];

  savedPaymentMethods.forEach((savedPaymentMethod) => {
    const { paymentMethod } = savedPaymentMethod;

    if (paymentMethod) {
      const { cardInfo, accountInfo, ...paymentMethodDetails } = paymentMethod;
      const paymentMethodBrand = getPaymentMethodBrand(paymentMethod);
      const paymentMethodMeta = getPaymentMethodMetaData(paymentMethodBrand);

      methodsWithMeta.push({
        ...savedPaymentMethod,
        ...paymentMethodMeta,
        paymentMethod: {
          ...paymentMethodDetails,
          // @ts-expect-error TODO fix after discussion
          //  https://wix.slack.com/archives/CFRR5LLAE/p1725614296101079?thread_ts=1722605830.020839&cid=CFRR5LLAE
          paymentInfo: cardInfo || accountInfo,
        },
      });
    }
  });

  return methodsWithMeta;
};
