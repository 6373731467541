import { createAsyncThunk } from '@reduxjs/toolkit';
import { Interaction } from '@constants';
import { BiToastType } from '@services/BiLogger';
import { toastsActions } from '../../toast';
import type { ThunkApiConfig } from '../../store.types';
import type { MarkPrimaryThunk } from './types';

export const markSavedPaymentMethodPrimary = createAsyncThunk<
  void,
  MarkPrimaryThunk,
  ThunkApiConfig
>(
  'savedPaymentMethods/markPrimary',
  async ({ paymentMethodId }, { extra, dispatch, rejectWithValue }) => {
    try {
      const { flowAPI, savedPaymentMethodsApi } = extra;
      const { environment, fedops } = flowAPI;

      if (environment.isViewer) {
        await savedPaymentMethodsApi.markAsPrimary(paymentMethodId);
      }

      fedops.interactionEnded(Interaction.MarkAsPrimary);
    } catch (error) {
      if (error instanceof Error) {
        extra.flowAPI.errorMonitor.captureException(error);
      }

      dispatch(
        toastsActions.showToast({
          text: extra.flowAPI.translations.t(
            'app.toast.make-primary.error.text',
          ),
          meta: { action: BiToastType.MarkPrimaryError, skin: 'error' },
        }),
      );

      throw rejectWithValue({ error });
    }
  },
);
