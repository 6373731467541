import {NavigationService} from '../../services/checkout/NavigationService';
import {navigateToThankYouPageParams, NavigationStoreProps} from '../../../types/checkoutApp.types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {BIService} from '../../services/checkout/BIService';
import {CheckoutOrigin} from '../../utils/common/bi.util.common';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {MemberService} from '../../services/checkout/MemberService';
import {ErrorDetailsFragment} from '../../../gql/graphql';
import {THEMES} from '../../../components/Checkout/constants';

export class NavigationStore {
  private readonly biService: BIService;
  private readonly navigationService: NavigationService;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly memberService: MemberService;
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly updateComponent: () => void;
  private readonly isSSR: boolean;
  private readonly isContinueShoppingEnabled: boolean;

  constructor({
    navigationService,
    siteStore,
    checkoutService,
    memberService,
    biService,
    controllerConfig,
    updateComponent,
    isSSR,
  }: {
    navigationService: NavigationService;
    siteStore: SiteStore;
    checkoutService: CheckoutService;
    memberService: MemberService;
    biService: BIService;
    controllerConfig: IWidgetControllerConfig;
    updateComponent: () => void;
    isSSR: boolean;
  }) {
    this.navigationService = navigationService;
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.memberService = memberService;
    this.biService = biService;
    this.controllerConfig = controllerConfig;
    this.updateComponent = updateComponent;
    this.isSSR = isSSR;
    this.isContinueShoppingEnabled = navigationService.isContinueShoppingEnabled;
  }

  public readonly onCheckoutNotFound = async () => {
    let user;
    try {
      user = await this.navigationService.login();
    } catch (e) {
      //
    }

    if (user) {
      this.navigationService.reload();
    } else {
      return this.navigateOnFailure();
    }
  };

  public async onLoadFailure(e: {response: {data: {details: ErrorDetailsFragment}}}) {
    if (e?.response?.data?.details?.applicationError?.code === 'CHECKOUT_NOT_FOUND' && !this.memberService.isMember()) {
      void this.onCheckoutNotFound();
      return;
    }

    await this.navigateOnFailure();
  }

  public async navigateOnFailure() {
    this.biService.loadCheckoutFailed();

    return this.navigationService.navigateOnFailure();
  }

  private readonly clickOnContinueShopping = (origin?: CheckoutOrigin) => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout, origin);
  };

  private readonly clickOnBackToSite = () => {
    this.biService.clickOnContinueShopping(this.checkoutService.checkout);
    return this.navigationService.navigateToContinueShopping();
  };

  private readonly clickOnReturnToCart = async () => {
    return this.navigationService.navigateToCart(this.checkoutService.checkout.cartId);
  };

  private readonly clickOnEditCart = (mobilePosition?: string) => {
    this.biService.clickOnEditCart(this.checkoutService.checkout, mobilePosition);
  };

  private readonly navigateToThankYouPage = (params: navigateToThankYouPageParams) =>
    this.navigationService.navigateToThankYouPage(params);

  private readonly onLogout = async (): Promise<void> => {
    /* eslint-disable  @typescript-eslint/await-thenable */
    const didRedirect = await this.controllerConfig.wixCodeApi.user.logout();

    if (!didRedirect) {
      await this.navigationService.navigateToHomepage();
    }
  };

  private readonly onLogin = async (): Promise<void> => {
    this.biService.checkoutClickLoginInCheckout(this.checkoutService.checkout);
    const user = await this.navigationService.login();
    if (user) {
      this.navigationService.reload();
    }
  };

  private readonly onSignup = async (): Promise<void> => {
    this.biService.checkoutClickLoginInCheckout(this.checkoutService.checkout);
    const user = await this.navigationService.signup();
    if (user) {
      this.navigationService.reload();
    }
  };

  public isDonationsTheme = (): boolean => {
    return this.navigationService.theme === THEMES.DONATIONS;
  };

  public isPreselectedFlow = (): boolean => {
    return this.navigationService.isPreselectedFlow;
  };

  public toProps(): NavigationStoreProps {
    return {
      clickOnBackToSite: this.clickOnBackToSite,
      clickOnContinueShopping: this.clickOnContinueShopping,
      clickOnReturnToCart: this.clickOnReturnToCart,
      clickOnEditCart: this.clickOnEditCart,
      continueShoppingUrl: this.navigationService.continueShoppingUrl,
      editCartUrl: this.navigationService.editCartUrl,
      successUrl: this.navigationService.successUrl,
      isDonationsTheme: this.isDonationsTheme(),
      navigateToThankYouPage: this.navigateToThankYouPage,
      trackEvent: (...args) => {
        this.controllerConfig.wixCodeApi.window.trackEvent(...args);
      },
      isSSR: this.isSSR,
      isContinueShoppingEnabled: this.isContinueShoppingEnabled,
      onLogin: this.onLogin,
      onSignup: this.onSignup,
      onLogout: this.onLogout,
    };
  }
}
