import type { CSSProperties } from 'react';
import { Alignment } from 'root/consts';
import { getAlignmentText } from 'root/utils/alignment';
import { Preset } from 'root/utils/presets';
import { useGetAlignment } from 'root/hooks/useGetAlignment';

export const useGetSectionTextAlignment = (preset: Preset) => {
  const gridSectionAlignment = useGetAlignment('gridSectionAlignment');
  const columnsSectionAlignment = useGetAlignment('columnsSectionAlignment');
  const sideBySideSectionAlignment = useGetAlignment('sideBySideSectionAlignment');

  const textAlignCssMap: Record<Preset, string> = {
    [Preset.Grid]: gridSectionAlignment,
    [Preset.Columns]: columnsSectionAlignment,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.SideBySide]: sideBySideSectionAlignment,
  };

  return { textAlign: textAlignCssMap[preset] as CSSProperties['textAlign'] };
};
