import model from './model';
import { DishesController } from './dishesController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type { DishesWidgetProps } from '../../types/menusTypes';
import type { Item } from 'root/types/menusTypes';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from 'root/api/consts';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { controllerConfig, translations, environment, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const { isMobile, isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;
  const { timezone = DEFAULT_TIMEZONE, regionalSettings } = wixCodeApi.site;
  const locale = regionalSettings || DEFAULT_LOCALE;

  const dishesController = new DishesController(
    $w,
    t,
    isMobile,
    experiments,
    timezone,
    locale,
    isMemberLoggedIn
  );

  const prevItems: Item[] = [];

  let didInit = false;

  $widget.onPropsChanged((prevProps, nextProps) => {
    const { menuId, sectionId, isTruncated, size, itemIds }: DishesWidgetProps = nextProps.data;

    if (itemIds.length > 0) {
      if (!didInit) {
        dishesController.init(isEditor, menuId, sectionId, isTruncated, size, itemIds);
        didInit = true;
      }
    } else {
      dishesController.setEmptyState();
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { menuId, sectionId, isTruncated, size, itemIds }: DishesWidgetProps =
        $widget.props.data;
      if (isEditor && itemIds.length > 0) {
        dishesController.deleteItemCounterInEditor(menuId);
      }
      if (itemIds.length > 0) {
        if (!didInit) {
          dishesController.init(isEditor, menuId, sectionId, isTruncated, size, itemIds);
          didInit = true;
        }
      } else {
        dishesController.setEmptyState();
      }
    },
    exports: {},
  };
});
