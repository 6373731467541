import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {useMemo} from 'react'
import {useExperiments} from '@wix/yoshi-flow-editor'

import {getIsLocationCompletelyManual} from './reservationLocation'
import {getShouldShowAutoApproveText} from './getShouldShowAutoApproveText'
import {ApprovalTextEditorState, EXPERIMENTS} from './constants'

export const useApproveTextView = (
  enabledReservationLocations: ReservationLocation[],
  approvalTextEditorState: ApprovalTextEditorState,
) => {
  const {experiments} = useExperiments()
  const shouldUseNewApprovalFields = experiments.enabled(EXPERIMENTS.useNewApprovalFields)
  const isAllLocationsFullyManual = useMemo(
    () =>
      enabledReservationLocations.every((location) =>
        getIsLocationCompletelyManual(location, shouldUseNewApprovalFields),
      ),
    [enabledReservationLocations],
  )

  const shouldShowAutoApproveText = getShouldShowAutoApproveText(
    approvalTextEditorState,
    isAllLocationsFullyManual,
  )

  return {shouldShowAutoApproveText}
}
