import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import stylesParams from 'root/components/Menus/stylesParams';
import type { ImageSizeCategories } from 'root/consts';
import { ImageSize } from 'root/consts';

export const useGetImageWidth = () => {
  const styles = useStyles();
  const { isMobile } = useEnvironment();

  const imageWidthEnum: ImageSizeCategories = styles.get(stylesParams.columnsItemImageSize);

  const widthMap = isMobile ? ImageSize.MOBILE : ImageSize.DESKTOP;

  return widthMap[imageWidthEnum];
};
