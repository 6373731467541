import { createAsyncThunk } from '@reduxjs/toolkit';
import { Interaction } from '@constants';
import { WarmupDataKey } from '@services/WarmupData';
import { canAddNewPaymentMethod } from '@utils/app';
import type { ThunkApiConfig } from '../store.types';

export const getIsRecurringSupported = createAsyncThunk<
  boolean,
  string,
  ThunkApiConfig
>('paymentMethods/fetch', async (accountId, { extra }) => {
  try {
    const { flowAPI, warmupData, paymentMethodsApi } = extra;
    const { environment, fedops } = flowAPI;

    fedops.interactionStarted(Interaction.LoadRecurringPaymentMethods);

    if (environment.isViewer) {
      const methods = await paymentMethodsApi.getRecurringPaymentMethods(
        accountId,
      );
      fedops.interactionEnded(Interaction.LoadRecurringPaymentMethods);

      const canAddNewMethod = canAddNewPaymentMethod(methods || []);
      warmupData.set(WarmupDataKey.CanAddNewPaymentMethod, canAddNewMethod);

      return canAddNewMethod;
    }

    fedops.interactionEnded(Interaction.LoadRecurringPaymentMethods);
    return true;
  } catch (error) {
    if (error instanceof Error) {
      extra.flowAPI.errorMonitor.captureException(error);
    }

    return false;
  }
});
