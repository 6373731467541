import {LIST_LAYOUT, MOBILE_LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {MOBILE_WIDTH} from '../components/list-layout/constants'

export const isCards = (listLayout: LIST_LAYOUT) => [LIST_LAYOUT.GRID, LIST_LAYOUT.NEW_GRID].includes(listLayout)

export const isList = (listLayout: LIST_LAYOUT) => listLayout === LIST_LAYOUT.LIST

export const isSideBySide = (listLayout: LIST_LAYOUT) => listLayout === LIST_LAYOUT.SIDE_BY_SIDE

export const isFullMobile = (mobileListLayout: MOBILE_LIST_LAYOUT) => mobileListLayout === MOBILE_LIST_LAYOUT.FULL

export const isGridMobile = (mobileLayout: MOBILE_LIST_LAYOUT, desktopLayout: LIST_LAYOUT) =>
  isFullMobile(mobileLayout) && !isList(desktopLayout)

export const isCompactMobile = (mobileListLayout: MOBILE_LIST_LAYOUT) => mobileListLayout === MOBILE_LIST_LAYOUT.COMPACT

export const isNarrow = (listLayout: LIST_LAYOUT, width: number | string, isMobile: boolean) =>
  !isMobile && isList(listLayout) && typeof width === 'number' && width < MOBILE_WIDTH
