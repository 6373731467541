import {BaseCartController} from '../../domain/controllers/BaseCartController';
import {CART_ORIGIN} from '../../common/constants';
import {defaultStyles} from './defaultStyles';
import {settingsParams} from './settingsParams';
import {wixcodePublicApi} from './WixCode/wixcodePublicApi';
import {ICartStyleSettings} from '../../types/app.types';
import {getFreeTextKeys} from '../../domain/utils/settings.utils';
import {SPECS} from '../../domain/specs';

const CART_LAYOUT = 'cart';
export class CartController extends BaseCartController {
  protected getOrigin() {
    return CART_ORIGIN;
  }

  protected getCartLayout(): 'cart' | 'sideCart' {
    return CART_LAYOUT;
  }

  protected getDefaultSettings(): ICartStyleSettings {
    return defaultStyles;
  }

  public exports = () => wixcodePublicApi(this.siteStore.experiments, this.setVeloInputs);

  public getFreeTexts(): string[] {
    return getFreeTextKeys(settingsParams);
  }

  public getOptionsOverrides() {
    return this.siteStore.experiments.enabled(SPECS.UseGetSettingsWithDefaults)
      ? {}
      : {shouldCallAppSettingsOnlyWhenMultilingualIsNeeded: false};
  }
}
