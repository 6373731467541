import {ExperimentNames, getFormattedFullLocation, getFormattedLocation} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import s from './title-location.scss'

interface TitleProps {
  event: wix.events.Event
}

export const TitleLocation = ({event}: TitleProps) => {
  const {
    allBreakpoints: {isListShortLocationVisible_V2, isListFullLocationVisible_V2, isListLocationVisible_V2},
  } = useVisibilityStyles()

  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)

  return (
    <div className={classNames(s.container, {[s.updatedVisibilityVars]: listDateLocationFormatSettingEnabled})}>
      <div className={s.title} data-hook="ev-list-item-title">
        {event.title}
      </div>
      {isListLocationVisible_V2() && (
        <>
          <div className={classNames(s.title, s.locationVisibility)} aria-hidden="true">
            &nbsp;/&nbsp;
          </div>
          <div className={classNames(s.location, s.locationVisibility)} data-hook="ev-list-item-location">
            {listDateLocationFormatSettingEnabled ? (
              <>
                {isListShortLocationVisible_V2() && (
                  <span className={s.shortLocationVisibility}>{getFormattedLocation(event)}</span>
                )}
                {isListFullLocationVisible_V2() && (
                  <span className={s.fullLocationVisibility}>{getFormattedFullLocation(event)}</span>
                )}
              </>
            ) : (
              event.location.name || event.location.address
            )}
          </div>
        </>
      )}
    </div>
  )
}
