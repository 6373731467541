import {hasImage} from '@wix/wix-events-commons-statics'
import {isFullWidth, isResponsive} from '../../../../../../../commons/selectors/environment'
import {getEvent} from '../../../../selectors/event'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {SideBySideLayoutOwnProps, SideBySideLayoutRuntimeProps} from './interfaces'
import {SideBySideLayout as SideBySideLayoutPresentation} from './side-by-side'

const mapRuntime = ({state, host}: DetailsPageAppProps): SideBySideLayoutRuntimeProps => ({
  imageVisible: hasImage(getEvent(state)),
  fullWidth: isFullWidth(state, host.dimensions),
  responsive: isResponsive(host),
})

export const SideBySideLayout = connect<SideBySideLayoutOwnProps, SideBySideLayoutRuntimeProps>(mapRuntime)(
  SideBySideLayoutPresentation,
)
