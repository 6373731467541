import { Alignment, AlignmentText } from 'root/consts';

export const getAlignmentText = (alignment: Alignment): string => {
  switch (alignment) {
    case Alignment.Center:
      return AlignmentText.Center;
    case Alignment.End:
      return AlignmentText.End;
    case Alignment.Start:
    default:
      return AlignmentText.Start;
  }
};
