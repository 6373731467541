import {DataExtensionsService, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutSettingsApi} from '../../apis/checkout/CheckoutSettingsApi';
import {CheckoutSettingsModel} from '../../models/checkout/checkoutSettings/CheckoutSettings.model';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {FORMS_CHECKOUT_DEXT_FQDN, FORMS_TEMPLATE_IDS} from '../../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {getFieldPropertiesByTarget} from '@wix/form-viewer/controller';
import {StoreCheckoutSettingsAndPayments} from '../../utils/types.util';
import {toMonitorError} from '../../utils/errorMonitor.utils';
import {type CountryCode} from '@wix/form-multiline-address';
import {FormFieldTarget} from '../../../types/checkoutApp.types';
import {ContentService} from './ContentService';
import {SPECS} from '../../../common/constants';
import {CheckoutOrigin} from '../../utils/common/bi.util.common';

export const USER_DEFINED_FIELDS_NAMESPACE = '_user_fields';
export class CheckoutSettingsService {
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsApi: CheckoutSettingsApi;
  private readonly dataExtensionsService: DataExtensionsService;
  private readonly contentService: ContentService;
  public checkoutSettings!: CheckoutSettingsModel;
  public showCouponSP!: boolean;
  public showExpressCheckoutSP!: boolean;
  public doesFastFlowFormHaveFieldsToDisplay!: boolean;
  private readonly flowAPI: ControllerFlowAPI;
  private readonly isFastFlow: boolean;

  constructor({
    controllerConfig,
    siteStore,
    flowAPI,
    dataExtensionsService,
    contentService,
    isFastFlow,
  }: {
    controllerConfig: IWidgetControllerConfig;
    siteStore: SiteStore;
    flowAPI: ControllerFlowAPI;
    dataExtensionsService: DataExtensionsService;
    contentService: ContentService;
    isFastFlow: boolean;
  }) {
    this.controllerConfig = controllerConfig;
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.isFastFlow = isFastFlow;
    this.dataExtensionsService = dataExtensionsService;
    this.contentService = contentService;
    this.checkoutSettingsApi = new CheckoutSettingsApi({siteStore, origin: CheckoutOrigin.checkout});
  }

  public async fetchSiteSettings(): Promise<void> {
    const checkoutSettingsAndPayments: StoreCheckoutSettingsAndPayments =
      await this.checkoutSettingsApi.getCheckoutSettings();

    const shouldUseCheckoutHeaderOnCheckout = this.siteStore.experiments.enabled(SPECS.UseCheckoutHeaderOnCheckout);

    this.checkoutSettings = new CheckoutSettingsModel({
      checkoutSettingsAndPayments,
      taxName: this.siteStore.taxName,
      shouldUseCheckoutHeaderOnCheckout,
    });
  }

  public async fetchDoesFastFlowFormHaveFieldsToDisplay(): Promise<boolean> {
    const shouldUseTemporaryTemplate = this.siteStore.experiments.enabled(SPECS.MigrateSubscriptionToCheckoutForm);
    const extendedFieldsFieldProps = await getFieldPropertiesByTarget({
      options: {fqdn: FORMS_CHECKOUT_DEXT_FQDN},
      httpClient: this.flowAPI.essentials.httpClient,
      formId: shouldUseTemporaryTemplate
        ? /* istanbul ignore next */ FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_SUBSCRIPTION
        : FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO,
    });

    const otherFieldProps = await getFieldPropertiesByTarget({
      httpClient: this.flowAPI.essentials.httpClient,
      formId: shouldUseTemporaryTemplate
        ? /* istanbul ignore next */ FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_SUBSCRIPTION
        : FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO,
    });

    const fieldValues = extendedFieldsFieldProps
      ? Object.values(extendedFieldsFieldProps)
      : /* istanbul ignore next */ [];
    /* istanbul ignore next */
    const doesFormHaveExtendedFields = fieldValues.filter((fieldValue) => fieldValue?.hidden === false).length > 0;
    const doesFormHaveAdditionalInfoField =
      otherFieldProps &&
      /* istanbul ignore next */ otherFieldProps[FormFieldTarget.additional_info_1]?.hidden === false;

    const doesFastFlowFormHaveFieldsToDisplay = doesFormHaveExtendedFields || Boolean(doesFormHaveAdditionalInfoField);
    this.doesFastFlowFormHaveFieldsToDisplay = doesFastFlowFormHaveFieldsToDisplay;
    return doesFastFlowFormHaveFieldsToDisplay;
  }

  public async init(): Promise<void> {
    await this.fetchSiteSettings();
    await this.contentService.fetchCheckoutContent();
    this.isFastFlow && (await this.fetchDoesFastFlowFormHaveFieldsToDisplay());
    await this.dataExtensionsService.fetchDataExtensionsSchema().catch((e) => {
      this.flowAPI.errorMonitor.captureException(
        ...toMonitorError(e, 'CheckoutSettingsService failed to fetch data extensions schema')
      );
    });

    this.showCouponSP = this.controllerConfig.config.style.styleParams.booleans.checkout_showCoupon !== false;
    this.showExpressCheckoutSP =
      this.controllerConfig.config.style.styleParams.booleans.checkout_showExpressCheckout !== false;
  }

  public addEnabledDeliveryCountry(country: CountryCode) {
    /* istanbul ignore else */
    if (!this.checkoutSettings.enabledDeliveryCountries.includes(country)) {
      this.checkoutSettings.enabledDeliveryCountries.push(country);
    }
  }

  public get checkoutContent() {
    return this.contentService.checkoutContent;
  }
}
