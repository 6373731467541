import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { hasSectionBottomDivider, hasSectionTopDivider } from 'root/utils/presets';
import { useGetSectionTextAlignment } from 'root/hooks/preset/useGetSectionTextAlignment';
import { useGetDesignStylesParams } from 'root/hooks/useGetDesignStylesParams';

export const useSectionLogic = () => {
  const { showSectionName, showSectionDescription, showSectionDividers } = useGetDisplayStylesParams();
  const { preset } = useGetLayoutStylesParams();
  const { sectionTitleTheme, sectionDescriptionTheme } = useGetDesignStylesParams();

  const shouldShowTopDivider = showSectionDividers && hasSectionTopDivider(preset);
  const shouldShowBottomDivider = showSectionDividers && hasSectionBottomDivider(preset);

  const textAlignCss = useGetSectionTextAlignment(preset);

  return {
    showSectionName,
    showSectionDescription,
    textAlignCss,
    shouldShowTopDivider,
    shouldShowBottomDivider,
    sectionDescriptionTheme,
    sectionTitleTheme,
  };
};
