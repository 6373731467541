import { listMenus, getMenu } from '@wix/ambassador-restaurants-menus-v1-menu/http';
import type { Menu } from 'root/apiTypes';
import type { HttpClientFactory } from './utils/types';
import { sortByCreatedDate } from '@wix/restaurants-normalizers';

export const MenusClient: HttpClientFactory<Menu> = (httpClient) => ({
  get: ({ id }) => {
    return httpClient
      .request(getMenu({ menuId: id }))
      .then((res) => {
        const {
          data: { menu = {} },
        } = res;

        return { data: menu as Menu };
      })
      .catch((e) => {
        throw new Error(`Error when calling getMenu with id ${id}- ${e}`);
      });
  },
  getAll: ({ paging, onlyVisible = true }) => {
    return httpClient
      .request(listMenus({ paging, onlyVisible }))
      .then((res) => {
        const sortedMenus = sortByCreatedDate((res?.data?.menus || []) as (Menu & { createdDate: Date })[]);
        return { data: sortedMenus as Menu[], pagingMetadata: res?.data?.pagingMetadata };
      })
      .catch((e) => {
        throw new Error(`Error when calling getMenus- ${e}`);
      });
  },
});
