import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {useSettings} from '../../../../hooks/settings'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import {settingsParams} from '../../../../settingsParams'
import {Subtitle} from '../../subtitle'
import {Footer} from './footer'
import {ScheduleProps} from './interfaces'
import {List} from './list'
import s from './schedule.scss'

export const Schedule = ({t, items, total, navigateToSchedulePage}: ScheduleProps) => {
  const {
    allBreakpoints: {isScheduleVisible},
  } = useVisibilityStyles()
  const settings = useSettings()
  const scheduleTitleText = settings.get(settingsParams.scheduleTitleText)

  if (!isScheduleVisible()) {
    return null
  }

  return (
    <div className={s.container} data-hook={DH.SCHEDULE}>
      <Subtitle dataHook={DH.SCHEDULE_SUBTITLE} text={scheduleTitleText} />
      <List t={t} items={items} />
      <Footer total={total} t={t} navigateToSchedulePage={navigateToSchedulePage} />
    </div>
  )
}
