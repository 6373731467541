import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {
  Reservation,
  Status,
  Reservee,
} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {
  ApprovalMode,
  ReservationLocation,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {Order} from '@wix/ambassador-ep-orders-proxy/http'
import {SiteStructure} from '@wix/native-components-infra/dist/src/types/types'
import {BreadcrumbsItem} from 'wix-ui-tpa/dist/cssVars/types/components'

import {reservationLocationsService} from '../../services/reservationLocationsService'
import {reservationsService} from '../../services/reservationsService'
import {goToNewReservation} from '../../utils/navigation'
import {noop} from '../../utils/helpers'
import {getRegionalSettings} from '../../utils/flowAPI'
import {RequestStatus, wrapRequest} from '../../utils/wrapRequest'
import {createStorageProvider} from '../../utils/storageContext'
import {getReservationLocationsMock} from '../../editor/editorMocks/getReservationLocationsMock'
import {getReservationMock} from '../../editor/editorMocks/getReservationMock'
import {ApprovalTextEditorState, EXPERIMENTS} from '../../utils/constants'
import {checkoutService} from '../../services/checkoutService'
import {trackTableReservedEvent, trackPurchaseEvent} from '../../utils/trackEvent'

interface ReservationConfirmationData {
  getReservationLocationsStatus: RequestStatus
  getReservationStatus: RequestStatus
  getOrderDetailsStatus: RequestStatus
  cancelReservationStatus: RequestStatus
  cancelReservationError?: any

  regionalSettings?: string
  reservationId: string | undefined
  reservationLocations: ReservationLocation[]
  reservation: Reservation | undefined
  orderId: string | undefined
  orderDetails: Order | undefined
  fitToContentHeight: boolean
  approvalTextEditorState: ApprovalTextEditorState.unknown
  isManualApproval: boolean
}

interface ReservationConfirmationActions {
  getReservationLocations: () => Promise<ReservationLocation[] | undefined>
  getReservation: (reservationId: string) => Promise<Reservation | undefined>
  getOrderDetails: (orderId: string) => Promise<Order | undefined>
  cancelReservation: (param: {
    reservationId: string
    revision: string
    phone?: string
  }) => Promise<Reservation | undefined>
  resetCancelReservationStatus: () => void
  goToNewReservation: (reservation?: Reservation, timeZone?: string | null) => void
  trackTableReservedEvent: (reservationId: string, reservee?: Reservee) => void
  trackPurchaseEvent: (reservationId: string, order: Order) => void
}

export interface ReservationConfirmationStorage
  extends ReservationConfirmationData,
    ReservationConfirmationActions {}

export const defaultReservationConfirmationStorage: ReservationConfirmationStorage = {
  getReservationLocationsStatus: RequestStatus.DEFAULT,
  getReservationStatus: RequestStatus.DEFAULT,
  getOrderDetailsStatus: RequestStatus.DEFAULT,
  cancelReservationStatus: RequestStatus.DEFAULT,
  cancelReservationError: undefined,
  reservationId: undefined,
  reservation: undefined,
  orderId: undefined,
  orderDetails: undefined,
  reservationLocations: [],
  getReservationLocations: noop,
  getReservation: noop,
  getOrderDetails: noop,
  cancelReservation: noop,
  resetCancelReservationStatus: noop,
  goToNewReservation: noop,
  fitToContentHeight: true,
  approvalTextEditorState: ApprovalTextEditorState.unknown,
  isManualApproval: false,
  trackTableReservedEvent: noop,
  trackPurchaseEvent: noop,
}

const {withStorageProvider, useStorage} = createStorageProvider(
  defaultReservationConfirmationStorage,
)

const initReservationConfirmationStorage = (
  flowAPI: ControllerFlowAPI,
): ReservationConfirmationStorage => {
  const query = flowAPI.controllerConfig?.wixCodeApi?.location?.query ?? {}
  const isWithQuery = !!query.reservationId
  const isWithOrder = !!query.orderId

  return {
    ...defaultReservationConfirmationStorage,
    regionalSettings: getRegionalSettings(flowAPI),
    reservationId: isWithQuery ? query.reservationId : undefined,
    orderId: isWithOrder ? query.orderId : undefined,
    getReservationLocations: wrapRequest(
      flowAPI,
      reservationLocationsService.getReservationLocations,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
    getReservation: wrapRequest(
      flowAPI,
      reservationsService.getReservation,
      'reservation',
      'getReservationStatus',
    ),
    getOrderDetails: wrapRequest(
      flowAPI,
      checkoutService.getOrderDetails,
      'orderDetails',
      'getOrderDetailsStatus',
    ),
    cancelReservation: wrapRequest(
      flowAPI,
      reservationsService.cancelReservation,
      'reservation',
      'cancelReservationStatus',
      'cancelReservationError',
    ),
    resetCancelReservationStatus: () => {
      flowAPI.controllerConfig.setProps({
        cancelReservationStatus: RequestStatus.DEFAULT,
        cancelReservationError: undefined,
      })
    },
    goToNewReservation: (reservation?: Reservation) =>
      goToNewReservation({
        flowAPI,
        reservationData: reservation?.details,
      }),
    trackTableReservedEvent: (reservationId: string, reservee?: Reservee) => {
      trackTableReservedEvent(flowAPI, reservationId, reservee)
    },
    trackPurchaseEvent: (reservationId: string, order: Order) => {
      trackPurchaseEvent(flowAPI, reservationId, order)
    },
  }
}

const mockReservationConfirmationStorage = async (
  flowAPI: ControllerFlowAPI,
): Promise<ReservationConfirmationStorage> => {
  const reservationLocations = await getReservationLocationsMock(flowAPI)
  const reservation = getReservationMock(flowAPI, Status.RESERVED, reservationLocations[0].id!)
  const shouldUseNewApprovalFields = flowAPI.experiments.enabled(EXPERIMENTS.useNewApprovalFields)

  const isManualApproval = shouldUseNewApprovalFields
    ? reservationLocations[0].configuration?.onlineReservations?.approval?.mode ===
        ApprovalMode.MANUAL || false
    : reservationLocations[0].configuration?.onlineReservations?.manualApproval?.enabled || false

  return {
    ...defaultReservationConfirmationStorage,
    reservationLocations,
    regionalSettings: getRegionalSettings(flowAPI),
    getReservationLocationsStatus: RequestStatus.RESOLVED,
    reservation,
    reservationId: reservation.id!,
    isManualApproval,
    cancelReservation: async () => {
      return {}
    },
  }
}

export {
  initReservationConfirmationStorage,
  mockReservationConfirmationStorage,
  withStorageProvider as withReservationConfirmationStorageProvider,
  useStorage as useReservationConfirmationStorage,
}
