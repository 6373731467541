import {
  ApprovalMode,
  ReservationLocation,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {DropdownOptionProps} from 'wix-ui-tpa/cssVars'

import {getIsLocationCompletelyAuto, getIsLocationCompletelyManual} from './reservationLocation'

interface GetRange {
  start: number
  end: number
  step?: number
  size?: number
}

export const getRange = ({
  start,
  end,
  step = 1,
  size = Math.ceil((end - start) / step) + 1,
}: GetRange) => Array.from({length: size}, (_, i) => i * step + start)

export const getPartySizeOptionsForReservationLocation = (
  t: Function,
  reservationLocation: ReservationLocation | undefined,
  shouldUseNewApprovalFields?: boolean,
): DropdownOptionProps[] => {
  if (!reservationLocation) {
    return []
  }
  const minPartySize = reservationLocation?.configuration?.onlineReservations?.partySize?.min ?? 1
  const maxPartySize = reservationLocation?.configuration?.onlineReservations?.partySize?.max ?? 1
  const isLargePartiesCallbackEnabled =
    reservationLocation?.configuration?.onlineReservations?.showPhoneNumber ?? false

  const manualApprovalPartySizeThreshold = shouldUseNewApprovalFields
    ? reservationLocation?.configuration?.onlineReservations?.approval?.manualForLargePartiesOptions
        ?.partySizeThreshold || 0
    : reservationLocation?.configuration?.onlineReservations?.manualApproval?.partySizeThreshold ||
      0

  const isLocationCompletelyManual = getIsLocationCompletelyManual(
    reservationLocation,
    shouldUseNewApprovalFields,
  )

  const isLocationCompletelyAuto = getIsLocationCompletelyAuto(
    reservationLocation,
    shouldUseNewApprovalFields,
  )

  const partySizeRange = getRange({
    start: minPartySize,
    end: maxPartySize,
  })

  const options = partySizeRange.map((size) => {
    const value = t('uou-reservations.shared.guests', {number: size})

    const subtitle =
      isLocationCompletelyManual || isLocationCompletelyAuto
        ? undefined
        : size >= manualApprovalPartySizeThreshold
        ? t('uou-reservations.shared.guests.requests-only')
        : t('uou-reservations.shared.guests.auto-approve')

    return {
      id: size.toString(),
      value,
      isSelectable: true,
      subtitle,
    }
  }) as DropdownOptionProps[]

  if (isLargePartiesCallbackEnabled) {
    const largeParty = maxPartySize + 1

    const value = t('uou-reservations.shared.large-parties-guests', {number: largeParty})

    options.push({
      id: largeParty.toString(),
      value,
      isSelectable: true,
    })
  }

  return options
}

export const DEFAULT_PARTY_SIZE = 2
export const getInitialPartySizeFromOptions = (options: DropdownOptionProps[]): number =>
  options.find(({id}) => Number(id) === DEFAULT_PARTY_SIZE)
    ? DEFAULT_PARTY_SIZE
    : Number(options[0].id)
