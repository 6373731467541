import React, { type ReactNode, type FC, useCallback } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import type { AppError } from '@constants';
import { Feedback } from '@components/Feedback';
import { Button } from '@components/Button';

type Props = {
  error?: AppError;
  children: ReactNode;
  onRetry: () => void;
};

export const ApplicationError: FC<Props> = ({ children, error, onRetry }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onRetry();
  }, [onRetry]);

  if (!error) {
    return <>{children}</>;
  }

  return (
    <Feedback
      title={t('app.error-page.fetch-failed.title')}
      subtitle={t('app.error-page.fetch-failed.description')}
      actions={
        <Button wiringVariant="primary" onClick={handleClick}>
          {t('app.error-page.fetch-failed.cta.text')}
        </Button>
      }
    />
  );
};
