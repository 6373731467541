import {
  Status,
  TimeSlot,
  GetTimeSlotsRequest,
} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import addMinutes from 'date-fns/addMinutes'

import {ITimeSlotsByDays} from '../../components/Reservations/constants'
import {getAppropriateTimeSlots, timeSlotsService} from '../../services/timeSlotsService'

import {EDITOR_MOCK_RESERVATION_LOCATION_ID} from './getReservationLocationsMock'

export const getTimeSlotsMock = async (
  flowApi: ControllerFlowAPI,
  params: GetTimeSlotsRequest,
  tz?: string | null,
  statuses: Status[] = Object.values(Status),
): Promise<ITimeSlotsByDays> => {
  if (!params.date) {
    return {}
  }

  let timeSlotsFromApi: ITimeSlotsByDays = {}

  if (params.reservationLocationId !== EDITOR_MOCK_RESERVATION_LOCATION_ID) {
    try {
      timeSlotsFromApi = await timeSlotsService.getTimeSlots(flowApi, params, tz, statuses)
    } catch (e) {}
  }

  if (Object.keys(timeSlotsFromApi).length) {
    return timeSlotsFromApi
  } else {
    const selectedTime = params.date

    const intervals = eachMinuteOfInterval(
      {
        start: addMinutes(selectedTime, -7 * 15),
        end: addMinutes(selectedTime, 7 * 15),
      },
      {step: 15},
    )

    return {
      [selectedTime.toISOString()]: getAppropriateTimeSlots(
        generateTimeSlots(intervals.slice(0, 15)),
        params.date,
        15,
        statuses,
      ),
    }
  }
}

const generateTimeSlot = (startDate: Date, status: Status): TimeSlot => ({
  startDate,
  duration: 30,
  status,
  tableCombinations: [],
})

const generateTimeSlots = (dates: Date[]): TimeSlot[] =>
  Array.from({length: dates.length}).map((_, index) =>
    generateTimeSlot(dates[index], Status.AVAILABLE),
  )
