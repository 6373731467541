import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import sc from '../../../classes.scss'
import s from './date.scss'

interface DateProps {
  customColorClass?: string
  event: wix.events.Event
  scheduleTbd: boolean
}

export const Date = ({scheduleTbd, event}: DateProps) => {
  const {
    allBreakpoints: {
      isListDateEnabled,
      isListDateVisible_V2,
      isListShortDateLocationVisible_V2,
      isListFullDateLocationVisible_V2,
    },
  } = useVisibilityStyles()
  const {shortStartDate, fullDate} = useEventDateInformation(event.id)
  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)

  const dateVisible = listDateLocationFormatSettingEnabled ? isListDateVisible_V2() : isListDateEnabled()

  if (!dateVisible) {
    return null
  }

  return (
    <div
      className={classNames(s.root, sc.textNoMargin, s.dateFont, {
        [s.updatedVisibilityVars]: listDateLocationFormatSettingEnabled,
      })}
      data-hook={scheduleTbd ? 'ev-date-tbd' : 'ev-date'}
    >
      {listDateLocationFormatSettingEnabled ? (
        <>
          {isListShortDateLocationVisible_V2() && <span className={s.shortDateVisibility}>{shortStartDate}</span>}
          {isListFullDateLocationVisible_V2() && <span className={s.fullDateVisibility}>{fullDate}</span>}
        </>
      ) : (
        shortStartDate
      )}
    </div>
  )
}
