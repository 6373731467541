import {hasImage} from '@wix/wix-events-commons-statics'
import {getEvent} from '../../../../selectors/event'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {ClassicLayout as ClassicLayoutPresentation} from './classic-layout'
import {ClassicHeaderLayoutOwnProps, ClassicHeaderLayoutRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): ClassicHeaderLayoutRuntimeProps => ({
  imageVisible: hasImage(getEvent(state)),
})

export const ClassicLayout = connect<ClassicHeaderLayoutOwnProps, ClassicHeaderLayoutRuntimeProps>(mapRuntime)(
  ClassicLayoutPresentation,
)
