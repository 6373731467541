import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {BuyerNoteSection} from './BuyerNoteSection/BuyerNoteSection';
import * as s from './TotalsSection.scss';
import {classes} from './TotalsSectionOverride.st.css';
import {useTranslation} from '@wix/yoshi-flow-editor';
import settingsParams from '../../../settingsParams';
import {Divider} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {shorten} from './utils';
import {shouldShowShipping} from '../Layout/utils';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import {getSubscriptionDetailsTranslationKey} from '../ProductLineItemsSection/utils';
import {Totals} from './Subtotals/Totals';
import {Totals as TotalsOld} from '@wix/wixstores-client-common-components/dist/es/src/Subtotals/Totals';

export enum TotalsSectionDataHook {
  root = 'TotalsSectionDataHook.root',
  buyerNoteWrapper = 'TotalsSectionDataHook.buyerNoteWrapper',
  totalsWrapper = 'TotalsSectionDataHook.totalsWrapper',
  paymentStatus = 'TotalsSectionDataHook.paymentStatus',
  offlinePaymentStatus = 'TotalsSectionDataHook.offlinePaymentStatus',
  paid = 'TotalsSectionDataHook.offlinePaymentPaid',
  offlinePaymentDueLater = 'TotalsSectionDataHook.offlinePaymentDueLater',
  buyerNoteLabel = 'TotalsSectionDataHook.buyerNoteLabel',
  buyerNote = 'TotalsSectionDataHook.buyerNote',
  additionalFeesRoot = 'TotalsSectionDataHook.additionalFeesRoot',
  afterFreeTrial = 'TotalsSectionDataHook.afterFreeTrial',
  totalPrice = 'TotalsSectionDataHook.TotalPrice',
  delayedPayments = 'TotalsSectionDataHook.delayedPayments',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function TotalsSection() {
  const {t} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {
    isOfflinePayment,
    formattedTotalsBalance,
    formattedTotalsPaid,
    formattedTaxPrice,
    formattedShippingPrice,
    formattedSubTotalPrice,
    formattedTotalPrice,
    formattedDiscount,
    couponCode,
    isSubscription,
    subscriptionFrequency,
    subscriptionInterval,
    isPickupSelected,
    shippingPrice,
    formattedTotalGiftCardAmount,
    hasGiftCard,
    totalGiftCardAmount,
    taxName,
    address: shippingAddress,
    pickupAddress,
    shouldDisplayAdditionalFees,
    additionalFees,
    useNewSubscriptionView,
    useProductLineItemFromTYP,
    formattedPayAfterFreeTrialPrice,
    supportFreeTrialTYP,
  } = useControllerProps().thankYouPageStore;

  const showShipping = shouldShowShipping({isPickupSelected, pickupAddress, shippingAddress});
  const shouldDisplayFrequencyUnderTotals = isSubscription && !useNewSubscriptionView;
  const shouldDisplayAfterFreeTrial = formattedPayAfterFreeTrialPrice && supportFreeTrialTYP;

  const getSubscriptionDetails = () => {
    const translationKey = getSubscriptionDetailsTranslationKey(subscriptionInterval, subscriptionFrequency);
    const translation = t(translationKey, {
      numberOfFrequencyUnits: subscriptionInterval,
    });
    return `/ ${translation}`;
  };

  const getTotalTitle = () => {
    return (
      getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_TOTAL_COST_LABEL) ?? localeKeys.thankYouPage.total.label()
    );
  };

  const getShippingTitle = () => {
    return isPickupSelected ? localeKeys.thankYouPage.pickup.label() : localeKeys.thankYouPage.shipping.label();
  };

  const getShippingValue = () => {
    return shippingPrice === 0 ? localeKeys.thankYouPage.deliveryMethod.free.label() : formattedShippingPrice;
  };

  const getAdditionalFees: Function = (): JSX.Element[] => {
    return additionalFees.map((additionalFee) => (
      <Totals.SubtotalRow
        key={additionalFee.code}
        title={additionalFee.name}
        value={additionalFee.convertedFormattedTotalPriceBeforeTax}
        className={classes.mySubtotalRow}
        dataHook={TotalsSectionDataHook.additionalFeesRoot}
      />
    ));
  };

  return useProductLineItemFromTYP ? (
    <div className={s.root} data-hook={TotalsSectionDataHook.root}>
      <BuyerNoteSection />
      {
        <div className={s.totals}>
          <div className={s.section} data-hook={TotalsSectionDataHook.totalsWrapper}>
            <Totals>
              <Totals.SubtotalRow
                title={localeKeys.thankYouPage.subtotal.label()}
                value={formattedSubTotalPrice}
                className={classes.mySubtotalRow}
              />
              {shouldDisplayAdditionalFees && getAdditionalFees()}
              {showShipping && (
                <Totals.SubtotalRow
                  title={getShippingTitle()}
                  value={getShippingValue()}
                  className={classes.mySubtotalRow}
                />
              )}
              <Totals.SubtotalRow
                title={taxName || localeKeys.thankYouPage.tax.label()}
                value={formattedTaxPrice}
                className={classes.mySubtotalRow}
              />
              {!!couponCode && (
                <Totals.SubtotalRow
                  title={localeKeys.thankYouPage.promoCode.label({couponCode: shorten(couponCode, 10, '...')})}
                  value={`-${formattedDiscount}`}
                  className={classes.mySubtotalRow}
                />
              )}
              {!!hasGiftCard && !!totalGiftCardAmount && totalGiftCardAmount > 0 && (
                <Totals.SubtotalRow
                  title={localeKeys.thankYouPage.giftCard.label()}
                  value={`-${formattedTotalGiftCardAmount}`}
                  className={classes.mySubtotalRow}
                />
              )}
            </Totals>
            <Divider className={s.divider} />
            <Totals>
              <Totals.TotalRow
                dataHook={TotalsSectionDataHook.totalPrice}
                className={classes.myTotalRow}
                title={getTotalTitle()}
                value={formattedTotalPrice}
                secondaryValue={shouldDisplayFrequencyUnderTotals ? getSubscriptionDetails() : undefined}
              />
            </Totals>
            {(shouldDisplayAfterFreeTrial || isOfflinePayment) && (
              <div data-hook={TotalsSectionDataHook.delayedPayments}>
                <Divider className={s.divider} />
                <Totals>
                  <Totals.TotalRow
                    className={classes.myTotalRow}
                    title={localeKeys.thankYouPage.paid.label()}
                    value={formattedTotalsPaid}
                    dataHook={TotalsSectionDataHook.paid}
                  />
                  {shouldDisplayAfterFreeTrial && (
                    <Totals.SubtotalRow
                      className={classes.mySubTotal}
                      title={localeKeys.thankYouPage.pricingPlans.afterFreeTrial.label()}
                      value={formattedPayAfterFreeTrialPrice}
                      dataHook={TotalsSectionDataHook.afterFreeTrial}
                    />
                  )}
                  {isOfflinePayment && (
                    <Totals.SubtotalRow
                      title={localeKeys.thankYouPage.dueLater.label()}
                      value={formattedTotalsBalance}
                      className={classes.mySubtotalRow}
                      dataHook={TotalsSectionDataHook.offlinePaymentDueLater}
                    />
                  )}
                </Totals>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  ) : (
    <div className={s.root} data-hook={TotalsSectionDataHook.root}>
      <BuyerNoteSection />
      {
        <div className={s.totals}>
          <div className={s.section} data-hook={TotalsSectionDataHook.totalsWrapper}>
            <TotalsOld>
              <TotalsOld.SubtotalRow
                title={localeKeys.thankYouPage.subtotal.label()}
                value={formattedSubTotalPrice}
                className={classes.mySubtotalRowOld}
              />
              {shouldDisplayAdditionalFees && getAdditionalFees()}
              {showShipping && (
                <TotalsOld.SubtotalRow
                  title={getShippingTitle()}
                  value={getShippingValue()}
                  className={classes.mySubtotalRowOld}
                />
              )}
              <TotalsOld.SubtotalRow
                title={taxName || localeKeys.thankYouPage.tax.label()}
                value={formattedTaxPrice}
                className={classes.mySubtotalRowOld}
              />
              {!!couponCode && (
                <TotalsOld.SubtotalRow
                  title={localeKeys.thankYouPage.promoCode.label({couponCode: shorten(couponCode, 10, '...')})}
                  value={`-${formattedDiscount}`}
                  className={classes.mySubtotalRowOld}
                />
              )}
              {!!hasGiftCard && !!totalGiftCardAmount && totalGiftCardAmount > 0 && (
                <TotalsOld.SubtotalRow
                  title={localeKeys.thankYouPage.giftCard.label()}
                  value={`-${formattedTotalGiftCardAmount}`}
                  className={classes.mySubtotalRowOld}
                />
              )}
              <TotalsOld.SummeryDivider className={classes.mySummaryDividerOld} />
              <TotalsOld.TotalRow
                className={classes.myTotalRowOld}
                title={getTotalTitle()}
                value={formattedTotalPrice}
                secondaryValue={isSubscription ? getSubscriptionDetails() : undefined}
              />
            </TotalsOld>
          </div>
          {isOfflinePayment && (
            <div data-hook={TotalsSectionDataHook.offlinePaymentStatus}>
              <Divider className={s.divider} />
              <TotalsOld>
                <TotalsOld.SubtotalRow
                  title={localeKeys.thankYouPage.paid.label()}
                  value={formattedTotalsPaid}
                  className={classes.mySubtotalRowOld}
                />
                <TotalsOld.SubtotalRow
                  title={localeKeys.thankYouPage.dueLater.label()}
                  value={formattedTotalsBalance}
                  className={classes.mySubtotalRowOld}
                />
              </TotalsOld>
            </div>
          )}
        </div>
      }
    </div>
  );
}
