import React from 'react';
import { dataHooks } from 'root/dataHooks';
import { WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { DEFAULT_MENUS_SIDE_IMAGE } from 'root/assets/images/MenusSideImage';
import { useSideImageLogic } from 'root/components/Menus/SideImage/SideImage.logic';
import { classes } from './SideImage.st.css';

export const SideImage = ({ mobileImage }: { mobileImage: boolean }) => {
  const { shouldShowMenusSideImage, menusSideImage } = useSideImageLogic({ mobileImage });
  return (
    <>
      {shouldShowMenusSideImage && (
        <div className={classes.imageContainer}>
          <WowImage
            shouldUseLQIP
            data-hook={dataHooks.menu.sideImage}
            resize={ImageResizeOptions.cover}
            sourceHeight={menusSideImage?.height}
            sourceWidth={menusSideImage?.width}
            src={menusSideImage.url ?? DEFAULT_MENUS_SIDE_IMAGE}
          />
        </div>
      )}
    </>
  );
};
