import { TimeSlotRepository } from 'root/repositories/TimeSlotRepository';
import {
  getBusinessDaysDatesRange,
  getMonthlyDateRange,
  safeDateTimeConversion,
  mergeDateRanges,
} from 'root/utils/dateTimeUtils';
import type { DateRange } from 'root/utils/dateTimeUtils';
import type { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import { SchedulingType, DispatchType } from 'root/types/businessTypes';
import type { Operation, AddressInputAddress, Address, TimeSlot } from 'root/types/businessTypes';
import { dispatchState } from './DispatchState';
import { makeAutoObservable, toJS } from 'mobx';
import type { PlatformControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';
import { monitorCallback } from 'root/utils/utils';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { DateTime } from 'luxon';
import { isToday } from 'root/components/Header/headerUtils';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { SchedulingTypeModalState } from 'root/components/DispatchModal/dispatchModalUtils';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';

export function convertAddressInputToAddress(address: AddressInputAddress): Address {
  const { formatted, location, subdivisions, ...rest } = address;
  return {
    formattedAddress: formatted,
    geocode: location,
    ...rest,
  };
}
export function convertAddressToAddressInput(
  address?: Address,
  addressFormatter?: PlatformControllerFlowAPI['formatAddress']
): AddressInputAddress | undefined {
  if (!address) {
    return undefined;
  }
  const mutualFields = [
    'country',
    'streetAddress',
    'city',
    'subdivision',
    'postalCode',
    'addressLine',
  ];
  const { formattedAddress, geocode, addressLine, streetAddress } = address as Address;
  const formatted =
    (formattedAddress ||
      addressFormatter?.({ address }, { appendCountry: false })
        .filter((part: string) => part && /\S/.test(part))
        .join(', ') ||
      addressLine ||
      streetAddress?.formattedAddressLine) ??
    '';
  return mutualFields.reduce(
    (result, key) => {
      // @ts-expect-error
      if (address[key]) {
        // @ts-expect-error
        result[key] = address[key];
      }
      return result;
    },
    {
      formatted,
      location: geocode,
    }
  );
}

export function areDatesEqual(date1?: Date, date2?: Date) {
  if (!date1 || !date2) {
    return false;
  }
  return date1.getTime() === date2.getTime();
}

export class DispatchModalStore {
  private timeSlotRepository!: TimeSlotRepository;
  private timezone = DEFAULT_TIMEZONE;
  private reportError?: ReportError;
  private sentry?: ErrorMonitor;
  currentDate?: Date;
  timeSlots: TimeSlot[] = [];
  asapTimeSlot?: TimeSlot;
  todaysTimeSlots?: TimeSlot[];
  availableDates: DateTime[] = [];
  availableDispatchTypes: DispatchType[] = [];
  isLoading = false;
  constructor(
    private operation: Operation,
    fulfillmentsClient: FulfillmentsClient,
    private addressFormatter?: PlatformControllerFlowAPI['formatAddress'],
    flowAPI?: PlatformControllerFlowAPI,
    fedopsLogger?: FedopsLogger
  ) {
    this.reportError = flowAPI?.reportError;
    this.sentry = flowAPI?.errorMonitor;
    this.sentry?.addBreadcrumb({
      category: 'DispatchModalStore',
      message: 'constructor',
    });
    try {
      this.timeSlotRepository = new TimeSlotRepository(fulfillmentsClient, fedopsLogger, flowAPI);
      this.availableDispatchTypes = dispatchState.availableDispatchTypes;
    } catch (error) {
      this.reportError?.(error as Error);
      this.sentry?.captureException(error as Error);
      console.error(error);
    }
    this.timezone = flowAPI?.controllerConfig.wixCodeApi.site.timezone ?? DEFAULT_TIMEZONE;
    this.monitorClassMethods();
    makeAutoObservable(this);
  }

  private monitorClassMethods() {
    this.initTimeAndDates = monitorCallback(
      this.initTimeAndDates.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'initTimeAndDates',
      },
      this.reportError,
      this.sentry
    );
    this.setDate = monitorCallback(
      this.setDate.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setDate',
      },
      this.reportError,
      this.sentry
    );
    this.setAddress = monitorCallback(
      this.setAddress.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setAddress',
      },
      this.reportError,
      this.sentry
    );

    this.setAvailableDates = monitorCallback(
      this.setAvailableDates.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setAvailableDates',
      },
      this.reportError,
      this.sentry
    );

    this.setDispatchType = monitorCallback(
      this.setDispatchType.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setDispatchType',
      },
      this.reportError,
      this.sentry
    );
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async initTimeAndDates() {
    this.timeSlots = [];
    this.availableDates = [];
    this.currentDate = undefined;
    this.asapTimeSlot = undefined;
    this.todaysTimeSlots = [];
    if (dispatchState.dispatchInfo.address) {
      const startTime = dispatchState.dispatchInfo.selectedTimeSlot?.startTime
        .setZone(this.timezone)
        .startOf('day');
      const date = startTime
        ? new Date(startTime.year, startTime.month - 1, startTime.day)
        : undefined;
      const setDatePromise = date ? this.setDate(date) : Promise.resolve();

      const isPreOrder = this.schedulingType === SchedulingType.PRE_ORDER;
      const shouldFetchAvailableDates =
        isPreOrder ||
        (this.operation.allowAsapFutureHandling &&
          Number(this.operation.businessDaysAheadHandlingOptions) >= 0);

      let setAvailableDatesPromise = Promise.resolve();

      if (shouldFetchAvailableDates) {
        const dateRange = isPreOrder
          ? getMonthlyDateRange(dispatchState.dispatchInfo)
          : getBusinessDaysDatesRange(
              this.timezone,
              Number(this.operation.businessDaysAheadHandlingOptions)
            );
        setAvailableDatesPromise = this.setAvailableDates(dateRange);
      }

      await Promise.all([setDatePromise, setAvailableDatesPromise]).then(async () => {
        !isPreOrder && (await this.setAsapTimeSlot());
        if (
          !isPreOrder &&
          this.availableDates.length > 0 &&
          isToday(this.availableDates[0], this.timezone)
        ) {
          const todayDate = safeDateTimeConversion(this.availableDates[0]);
          this.todaysTimeSlots = await this.timeSlotRepository.getTimeSlots({
            date: todayDate,
          });
        }
      });
    }
  }

  get address() {
    return convertAddressToAddressInput(
      toJS(dispatchState.dispatchInfo.address),
      this.addressFormatter
    );
  }

  get dispatchType() {
    return dispatchState.selectedDispatchType;
  }

  get schedulingType(): SchedulingType {
    if (this.operation.operationType === 'ASAP') {
      return this.operation.allowAsapFutureHandling
        ? SchedulingType.ASAP_AND_FUTURE
        : SchedulingType.ASAP;
    }
    return SchedulingType.PRE_ORDER;
  }

  get schedulingTypeModalState(): SchedulingTypeModalState {
    const dates = this.hasOnlyAsapToday ? this.availableDates.slice(1) : this.availableDates;
    if (this.operation.operationType === 'ASAP') {
      return dates.length > 0
        ? SchedulingTypeModalState.ASAP_AND_FUTURE
        : SchedulingTypeModalState.ASAP_ONLY;
    }
    return SchedulingTypeModalState.PRE_ORDER;
  }

  get hasOnlyAsapToday(): boolean {
    return !!(
      this.todaysTimeSlots?.length === 1 &&
      this.todaysTimeSlots[0]?.startsNow &&
      isToday(this.availableDates[0], this.timezone)
    );
  }

  get selectedTimeSlot() {
    return toJS(dispatchState.dispatchInfo.selectedTimeSlot);
  }

  get asapTimeExact() {
    const asapTimeSlot = toJS(this.asapTimeSlot);
    return (this.selectedTimeSlot?.startsNow ? this.selectedTimeSlot : asapTimeSlot)
      ?.fulfillmentDetails.maxTimeOptions;
  }

  get asapTimeRange() {
    const asapTimeSlot = toJS(this.asapTimeSlot);
    return (this.selectedTimeSlot?.startsNow ? this.selectedTimeSlot : asapTimeSlot)
      ?.fulfillmentDetails.durationRangeOptions;
  }

  get pickupAddress() {
    return dispatchState.dispatchesInfo[DispatchType.PICKUP]?.address;
  }

  get hasMoreThanOneDispatchType() {
    return this.availableDispatchTypes.length > 1;
  }

  get availableDateRanges(): DateRange[] {
    return mergeDateRanges(toJS(this.availableDates));
  }

  get hasAvailableTimeSlots() {
    return this.timeSlots.length > 0;
  }

  async setAsapTimeSlot() {
    let timeSlot = this.timeSlots.find((slot) => slot.startsNow);
    if (!timeSlot) {
      const isFirstOptionToday =
        this.availableDates.length > 1 && isToday(this.availableDates[0], this.timezone);
      const firstDateTimeSlots = isFirstOptionToday
        ? await this.timeSlotRepository.getTimeSlots({
            date: safeDateTimeConversion(this.availableDates[0]),
          })
        : [];

      timeSlot = firstDateTimeSlots.find((slot) => slot.startsNow);
    }
    this.asapTimeSlot = timeSlot;
  }

  setTimeSlots(timeSlots: TimeSlot[]) {
    this.timeSlots = timeSlots;
  }

  async setDispatchType(dispatchType: DispatchType) {
    dispatchState.update(dispatchType, {});
    return this.initTimeAndDates();
  }

  private setCurrentDate(date?: Date) {
    this.currentDate = date;
  }

  async setDate(date: Date) {
    if (!areDatesEqual(this.currentDate, date)) {
      this.setCurrentDate(date);
      const timeSlots = await this.timeSlotRepository.getTimeSlots({ date });
      this.setTimeSlots(timeSlots);
      timeSlots.length > 0 && this.setTimeSlot(this.selectedTimeSlot?.id);
    }
  }

  async setAddress(selectedAddress: AddressInputAddress) {
    const address = convertAddressInputToAddress(selectedAddress);
    this.timeSlotRepository.resetCache(this.dispatchType);
    const selectedTimeSlot = await this.timeSlotRepository.getFirstAvailableTimeSlot(address);
    dispatchState.update(dispatchState.selectedDispatchType, {
      address,
      selectedTimeSlot,
    });
    if (selectedTimeSlot) {
      await this.initTimeAndDates();
    } else {
      this.setCurrentDate();
    }
  }

  async switchToAsapTimeSlot() {
    dispatchState.update(dispatchState.selectedDispatchType, {
      selectedTimeSlot: this.asapTimeSlot,
    });
    if (this.currentDate && !isToday(DateTime.fromJSDate(this.currentDate), this.timezone)) {
      await this.setDate(safeDateTimeConversion(this.availableDates[0]));
    }
  }

  setTimeSlot(timeSlotId?: string) {
    const timeSlot = this.timeSlots.find((slot) => slot.id === timeSlotId);
    if (timeSlot?.id !== this.selectedTimeSlot?.id) {
      if (timeSlot) {
        dispatchState.update(dispatchState.selectedDispatchType, {
          selectedTimeSlot: timeSlot,
        });
      } else {
        dispatchState.update(dispatchState.selectedDispatchType, {
          selectedTimeSlot: this.timeSlots[0],
        });
      }
    }
  }

  setDates(dates: DateTime[]) {
    this.availableDates = dates;
  }

  async setAvailableDates({ from, until }: { from: Date; until: Date }) {
    const dates = await this.timeSlotRepository.getAvailableDates({
      from,
      until,
    });
    this.setDates(dates);
  }
}
