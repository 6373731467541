import {ShippingInfoModel} from './ShippingInfo.model';
import {ICart as ICartFromCartApi} from '@wix/wixstores-client-storefront-sdk';
import {PriceSummaryModel} from './PriceSummary.model';
import {ViolationModel} from './Violation.model';
import {AdditionalFeeModel} from './AdditionalFee.model';
import {MinimumOrderAmountModel} from './MinimumOrderAmount.model';
import {EstimateTotalsResponse} from '@wix/ecom_current-cart';
import {AppliedCouponModel} from './AppliedCoupon.model';
import {toAppliedCouponSDK} from '../utils/coupon';

export class EstimatedTotalsModel {
  public priceSummary: PriceSummaryModel;
  public shippingInfo: ShippingInfoModel;
  public payNow: PriceSummaryModel;
  public payLater: PriceSummaryModel;
  public additionalFees: AdditionalFeeModel[];
  public violations: ViolationModel[];
  public minimumOrderAmount: MinimumOrderAmountModel;
  public estimatedAmountToPay: number;
  public appliedCoupon: AppliedCouponModel;

  constructor(params: EstimatedTotalsModel) {
    this.priceSummary = params.priceSummary;
    this.shippingInfo = params.shippingInfo;
    this.payNow = params.payNow;
    this.payLater = params.payLater;
    this.additionalFees = params.additionalFees;
    this.violations = params.violations;
    this.minimumOrderAmount = params.minimumOrderAmount;
    this.estimatedAmountToPay = params.estimatedAmountToPay;
    this.appliedCoupon = params.appliedCoupon;
  }

  public static fromGQL(estimatedTotals: ICartFromCartApi): EstimatedTotalsModel {
    return new EstimatedTotalsModel({
      priceSummary: PriceSummaryModel.fromGQL({
        convertedTax: estimatedTotals?.convertedTotals?.tax,
        formattedConvertedTax: estimatedTotals?.convertedTotals?.formattedTax,
        convertedTotal: estimatedTotals?.convertedTotals?.total,
        formattedConvertedTotal: estimatedTotals?.convertedTotals?.formattedTotal,
        convertedShipping: estimatedTotals?.convertedTotals?.shipping,
        formattedConvertedShipping: estimatedTotals?.convertedTotals?.formattedShipping,
        formattedConvertedItemsTotal: estimatedTotals?.convertedTotals?.formattedItemsTotal,
        itemsTotal: estimatedTotals?.convertedTotals?.itemsTotal,
        convertedAdditionalFees: estimatedTotals?.convertedTotals?.additionalFeesTotal,
        formattedConvertedAdditionalFees: estimatedTotals?.convertedTotals?.formattedAdditionalFeesTotal,
      }),
      shippingInfo: estimatedTotals?.shippingRuleInfo
        ? ShippingInfoModel.fromGQL(estimatedTotals?.shippingRuleInfo, estimatedTotals?.selectedShippingOption?.id)
        : undefined,
      payNow: PriceSummaryModel.fromGQL({
        total: estimatedTotals?.totals?.payNow,
        convertedTotal: estimatedTotals?.convertedTotals?.payNow,
        formattedTotal: estimatedTotals?.totals?.formattedPayNow,
        formattedConvertedTotal: estimatedTotals?.convertedTotals?.formattedPayNow,
      }),
      payLater: PriceSummaryModel.fromGQL({
        total: estimatedTotals?.totals?.payLater,
        convertedTotal: estimatedTotals?.convertedTotals?.payLater,
        formattedTotal: estimatedTotals?.totals?.formattedPayLater,
        formattedConvertedTotal: estimatedTotals?.convertedTotals?.formattedPayLater,
      }),
      additionalFees: (estimatedTotals?.additionalFees ?? []).map(AdditionalFeeModel.fromGQL),
      violations: (estimatedTotals?.violations ?? []).map(ViolationModel.fromGQL),
      minimumOrderAmount: estimatedTotals?.minimumOrderAmount
        ? MinimumOrderAmountModel.fromGQL(estimatedTotals?.minimumOrderAmount)
        : undefined,
      estimatedAmountToPay: estimatedTotals?.totals?.total,
      appliedCoupon: estimatedTotals?.appliedCoupon
        ? AppliedCouponModel.fromGQL(estimatedTotals?.appliedCoupon, {
            convertedAmount: estimatedTotals.convertedTotals.discount,
            formattedConvertedAmount: estimatedTotals.convertedTotals.formattedDiscount,
          })
        : undefined,
    });
  }

  public static fromSDK(estimatedTotals: EstimateTotalsResponse): EstimatedTotalsModel {
    return new EstimatedTotalsModel({
      additionalFees: (estimatedTotals?.additionalFees ?? []).map(AdditionalFeeModel.fromSDK),
      estimatedAmountToPay: Number(estimatedTotals.priceSummary.total.amount),
      minimumOrderAmount: MinimumOrderAmountModel.fromSDK(estimatedTotals.calculationErrors.orderValidationErrors),
      payLater: PriceSummaryModel.fromSDK(estimatedTotals.payLater),
      payNow: PriceSummaryModel.fromSDK(estimatedTotals.payNow),
      priceSummary: PriceSummaryModel.fromSDK(estimatedTotals.priceSummary),
      shippingInfo: estimatedTotals.shippingInfo
        ? ShippingInfoModel.fromSDK(estimatedTotals.shippingInfo, estimatedTotals?.calculationErrors?.carrierErrors)
        : undefined,
      violations: (estimatedTotals?.violations ?? []).map(ViolationModel.fromSDK),
      appliedCoupon: toAppliedCouponSDK(estimatedTotals.appliedDiscounts),
    });
  }
}
