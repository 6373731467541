import type {
  ISettingsParam,
  ISettingsParamValuesByType,
  SettingsParamType,
  createSettingsParams,
} from '@wix/tpa-settings';
import { MenusSettingsParams, type IMenusSettingsParams } from './Settings/Tabs/MenusTab/MenusSettingsParams';
import type { IDisplaySettingsParams } from './Settings/Tabs/DisplayTab/DisplaySettingsParams';
import { DisplaySettingsParams } from './Settings/Tabs/DisplayTab/DisplaySettingsParams';
// eslint-disable-next-line no-restricted-imports

export type SettingsParamsType<T extends Record<string, SettingsParamType>> = ReturnType<
  typeof createSettingsParams<T>
>;

export type ISettingsParams = IMenusSettingsParams & IDisplaySettingsParams;

export const settingsParams: SettingsParamsType<ISettingsParams> = {
  ...MenusSettingsParams,
  ...DisplaySettingsParams,
};

export default settingsParams;

export type SettingParamValue = ISettingsParamValuesByType[keyof ISettingsParamValuesByType];

export type ExtractSettingParamValue<T> = T extends ISettingsParam<SettingsParamType, infer U> ? U : never;
