import React, {FC} from 'react'
import noop from 'lodash/noop'
import {
  DropdownTheme,
  StatesButton,
  StatesButtonStates,
  TextFieldTheme,
  ThreeDotsLoader,
  DatePickerInputFirstDayOfWeek,
} from 'wix-ui-tpa/cssVars'
import {useSettings} from '@wix/tpa-settings/react'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {settingsParams} from '../../../settingsParams'
import {ButtonAlignment, FormLayout} from '../../../../../types/createStylesParams'
import {getFirstDayOfWeek, parseLocaleFromBM} from '../../../../../utils/locale'
import {LayoutSize} from '../../../../../components-shared/LayoutSizeProvider/types'
import {classes as widgetClasses} from '../../Widget.st.css'
import {LazyDatePicker} from '../../../../../components-shared/LazyDatePicker'
import {useDynamicImport} from '../../../../../utils/useDynamicImport'
import {Dropdown} from '../../../../../components-shared/Dropdown'

import {useHooks} from './useHooks'
import {classes, st} from './Form.st.css'

interface FormProps {
  enabledReservationLocations: ReservationLocation[]
  layoutSize: LayoutSize
  containerWidth: number
  showAutoApproveButtonLabel?: boolean
}

export const Form: FC<FormProps> = ({
  enabledReservationLocations,
  layoutSize,
  containerWidth,
  showAutoApproveButtonLabel,
}) => {
  const settings = useSettings()

  const {
    t,
    isSSR,
    submitButtonContentWidth,
    isMobile,
    isLoading,
    regionalSettings,
    reservationLocationsOptions,
    partySizeOptions,
    timeOptions,
    selectedReservationLocation,
    selectedPartySize,
    selectedDate,
    selectedTime,
    handlePartySizeChange,
    handleDateChange,
    handleTimeChange,
    handleFindTableSubmit,
    handleLocationChange,
    filterDate,
    shouldShowReservationsUnavailable,
    handleHideReservationsUnavailable,
    reservationAddOnDataHookWithId,
  } = useHooks(enabledReservationLocations, layoutSize)

  const NotAcceptingModal = useDynamicImport(
    () => import('../../../../Reservations/components/NotAcceptingModal'),
  )

  const locale = parseLocaleFromBM(regionalSettings)

  const weekFirstDay = getFirstDayOfWeek(locale) as DatePickerInputFirstDayOfWeek

  // prevents layout flickering after initial render
  if (containerWidth === 0) {
    return <div className={classes.formMock} />
  }

  const textFieldTheme = settings.get(settingsParams.textFieldTheme) as TextFieldTheme
  const buttonAlignment = settings.get(settingsParams.buttonAlignment)
  const buttonLabelText = settings.get(
    showAutoApproveButtonLabel
      ? settingsParams.buttonLabelText
      : settingsParams.buttonLabelRequestText,
  )

  const showFormFieldLabels = settings.get(settingsParams.showFormFieldLabels)

  const formLayout = settings.get(settingsParams.formLayout)

  const formLayoutClass =
    formLayout === FormLayout.Packed || layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S
      ? classes.packed
      : classes.strip

  const isTextFieldThemeBox = textFieldTheme === TextFieldTheme.Box
  const dropdownTheme = isTextFieldThemeBox ? DropdownTheme.Box : DropdownTheme.Line
  const datePickerTheme = isTextFieldThemeBox ? TextFieldTheme.Box : TextFieldTheme.Line

  const formRootClass = isTextFieldThemeBox ? classes.boxForm : classes.lineForm
  const dropDownClass = isTextFieldThemeBox ? classes.boxDropdown : classes.lineDropdown
  const datePickerClass = isTextFieldThemeBox ? classes.boxDatePicker : classes.lineDatePicker
  const dateTextFieldClass = isTextFieldThemeBox
    ? classes.boxDateTextField
    : classes.lineDateTextField

  const buttonAlignmentClass = ButtonAlignmentToClass[buttonAlignment]

  return (
    <>
      <form
        className={st(classes.formRoot, formRootClass, formLayoutClass)}
        onSubmit={handleFindTableSubmit}
      >
        <div className={classes.formFieldsWrapper}>
          {reservationLocationsOptions.length > 1 && (
            <div className={classes.formField}>
              <Dropdown
                upgrade
                className={dropDownClass}
                aria-label={t('uou-reservations.add-on.location')}
                label={showFormFieldLabels ? t('uou-reservations.add-on.location') : undefined}
                options={reservationLocationsOptions}
                initialSelectedId={selectedReservationLocation?.id ?? undefined}
                onChange={handleLocationChange}
                theme={dropdownTheme}
              />
            </div>
          )}

          <div className={classes.formField}>
            <Dropdown
              upgrade
              className={dropDownClass}
              aria-label={t('uou-reservations.add-on.party')}
              label={showFormFieldLabels ? t('uou-reservations.add-on.party') : undefined}
              options={partySizeOptions}
              initialSelectedId={String(selectedPartySize)}
              onChange={handlePartySizeChange}
              theme={dropdownTheme}
            />
          </div>

          <div className={classes.formField}>
            <LazyDatePicker
              label={t('uou-reservations.add-on.date')}
              value={isSSR ? undefined : selectedDate}
              onChange={handleDateChange}
              locale={locale}
              firstDayOfWeek={weekFirstDay}
              datePickerClass={datePickerClass}
              dateTextFieldClass={dateTextFieldClass}
              dateTextFieldDataHook="date-textfield"
              filterDate={filterDate}
              showFormFieldLabels={showFormFieldLabels}
              popoverPlacement={isMobile ? 'bottom' : 'bottom-start'}
              inputTheme={datePickerTheme}
            />
          </div>

          <div className={classes.formField}>
            <Dropdown
              upgrade
              key={selectedTime?.value}
              className={dropDownClass}
              aria-label={t('uou-reservations.add-on.time')}
              label={showFormFieldLabels ? t('uou-reservations.add-on.time') : undefined}
              options={timeOptions}
              // server renders UTC time
              initialSelectedId={isSSR ? undefined : selectedTime?.id}
              onChange={handleTimeChange}
              theme={dropdownTheme}
            />
          </div>
        </div>

        <div className={st(classes.buttonWrapper, buttonAlignmentClass)}>
          <StatesButton
            disabled={isLoading}
            upgrade
            type="submit"
            state={isLoading ? StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE}
            idleContent={buttonLabelText}
            inProgressContent={<ThreeDotsLoader className={classes.loader} />}
            className={classes.formButton}
            data-hook={reservationAddOnDataHookWithId}
            style={{
              ...(submitButtonContentWidth ? {width: `${submitButtonContentWidth}px`} : {}),
            }}
            // glitch in WUT. It always takes onClick as a prop despite type="submit" on button
            onClick={noop}
          />
        </div>
      </form>
      {NotAcceptingModal ? (
        <NotAcceptingModal
          isOpen={shouldShowReservationsUnavailable}
          onClose={handleHideReservationsUnavailable}
          layoutSize={layoutSize}
          bannerClassName={widgetClasses.notAcceptingBanner}
        />
      ) : (
        <div></div>
      )}
    </>
  )
}

const ButtonAlignmentToClass: Record<ButtonAlignment, string> = {
  [ButtonAlignment.Left]: classes.buttonLeft,
  [ButtonAlignment.Center]: classes.buttonCenter,
  [ButtonAlignment.Right]: classes.buttonRight,
  [ButtonAlignment.Stretch]: classes.buttonStretch,
}
