import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';
import {ItemLink} from '../ItemLink/ItemLink';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export const Name = ({lineItem}: {lineItem: LineItemModel}) => {
  const {
    cartStore: {makeCartItemNameLink},
  } = useControllerProps();
  const {productName} = lineItem;

  if (makeCartItemNameLink) {
    return (
      <p data-hook={CartItemDataHook.Name} className={s.productName}>
        <ItemLink lineItem={lineItem}>{productName}</ItemLink>
      </p>
    );
  }

  return (
    <p data-hook={CartItemDataHook.Name} className={s.productName}>
      {productName}
    </p>
  );
};
