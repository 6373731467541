import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { isStorybook } from './utils/environment';

export const cardBrandIconBaseUrl =
  'https://static.parastorage.com/services/wallet/1.1761.0/assets/images/cardNetwork';

export const paymentMethodIconUrl: Record<string, string> = {
  payPal:
    'https://wixmp-6613fa290e8c1ac70a0297b6.wixmp.com/payment-methods/checkout/svg/payPal.svg',
};

export const SITE_CURRENCY_DEFAULT = 'USD';

export const supportedPaymentMethodType: string[] = [PaymentMethod.CreditCard];

if (isStorybook()) {
  supportedPaymentMethodType.push(PaymentMethod.PayPal);
}
