import type { ContextParams, EditorPlatformApp, EditorSDK } from '@wix/platform-editor-sdk';
import { withEcomPlatformData } from '@wix/ecom-platform-sdk';
import { withMembersArea } from '@wix/members-area-integration-kit';
import { editorReadyImpl } from './editor/editorReady';
import { exportsImpl } from './editor/exports';
import { createAppManifest } from './editor/appManifest';
import { eventsImpl } from './editor/events';
import { ecomComponentsConfiguration } from './editor/installationUtils';
import { MEMBERS_AREA_DEFAULT_APPS } from './appConsts/consts';

const platformApp = withMembersArea(
  withEcomPlatformData(
    {
      editorReady: editorReadyImpl,
      getAppManifest: createAppManifest,
      onEvent: eventsImpl,
      exports: exportsImpl,
    } as unknown as EditorPlatformApp,
    () => ecomComponentsConfiguration.requireEcomComponents()
  ),
  {
    installAutomatically: false,
    membersAreaApps: MEMBERS_AREA_DEFAULT_APPS,
  }
);

export const editorReady = platformApp.editorReady;
export const getAppManifest = platformApp.getAppManifest;
export const onEvent = platformApp.onEvent;
export const exports = (editorSDK: EditorSDK, context: ContextParams) => {
  // ecom does not return the private/public methods exported from the module https://jira.wixpress.com/browse/EE-52641
  return {
    ...exportsImpl(editorSDK, context),
    // @ts-expect-error
    ...platformApp.exports?.(editorSDK, context),
  };
};
