/* eslint-disable no-console */
import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import type { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import type { IBIReporterService } from 'root/services/biReporterService';
import {
  getMissingPopups,
  installAppIfMissing,
  isPageInstalledByAppDefId,
  progressBarMoveToStepNumber,
} from './editor.utils';
import { LIGHTBOX_IDS, PAGE_DATA } from 'root/appConsts/consts';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { createAppPage } from 'root/utils/createPage';
import { installLightBoxByTpaAppId, installLightboxes } from './oloLightboxesInstallation';
import type { EcomComponentConfiguration } from '@wix/ecom-platform-sdk';

export async function handleFirstInstall({
  editorSDK,
  t,
  experiments,
  appDefId,
  msid,
  isResponsive,
  isStudio,
  fedopsLogger,
  biReporterService,
  subjectType,
  shouldOpenProgressBar,
  isOptimusOrigin = false,
}: {
  editorSDK: EditorSDK;
  t: TFunction;
  experiments: Experiments;
  appDefId: string;
  msid: string;
  isResponsive: boolean;
  isStudio: boolean;
  fedopsLogger: FedopsLogger;
  biReporterService: IBIReporterService;
  subjectType: string;
  shouldOpenProgressBar: boolean;
  isOptimusOrigin?: boolean;
}) {
  // eslint-disable-next-line no-console
  console.log('Online orders - installation starting...');

  const commonBIReq = {
    msid,
    isStudio,
    isFirstInstall: true,
    isInstallationRetry: false,
  };

  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'first_install_started',
    ...commonBIReq,
  });

  await progressBarMoveToStepNumber(editorSDK, t, 1, shouldOpenProgressBar);

  const { oloPageRef, oloWidgetRef } = await createAppPage({
    editorSDK,
    appDefId,
    pageData: PAGE_DATA,
    isResponsive,
    isStudio,
    shouldAddMenuItem: true,
    isOptimusOrigin,
    fedopsLogger,
    t,
  });

  const { menusPromise, tipsPromise, trackerPromise } = installAppsIfMissing({
    editorSDK,
    fedopsLogger,
    biReporterService,
    isFirstInstall: true,
    isOptimusOrigin,
  });

  biReporterService?.reportOloGenericDebugBiEvent({
    subjectType,
    value: {
      editorReady: 'before_installing_lightboxes',
      oloPageRef,
      ...commonBIReq,
    },
  });
  // eslint-disable-next-line no-console
  console.log('Online orders - installing lightboxes...');
  const isFirstInstall = true;
  const lightboxesPromise = installLightboxes(
    editorSDK,
    t,
    appDefId,
    isResponsive,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );

  // eslint-disable-next-line no-console
  console.log('Online orders - first install ended.');
  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'first_install_ended',
    ...commonBIReq,
  });
  return { oloPageRef, menusPromise, lightboxesPromise, tipsPromise, trackerPromise, oloWidgetRef };
}

export const handleInstallationRetry = async ({
  editorSDK,
  t,
  experiments,
  appDefId,
  msid,
  isResponsive,
  isStudio,
  biReporterService,
  fedopsLogger,
}: {
  editorSDK: EditorSDK;
  t: TFunction;
  experiments: Experiments;
  appDefId: string;
  msid: string;
  isResponsive: boolean;
  isStudio: boolean;
  biReporterService: IBIReporterService;
  fedopsLogger: FedopsLogger;
}) => {
  const isOLOPageInstalled = await isPageInstalledByAppDefId(editorSDK, APP_DEF_IDS.orders);
  const commonBIReq = {
    msid,
    isResponsive,
    isStudio,
  };

  if (!isOLOPageInstalled) {
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: 'OLO_page_not_exists_retry',
      appDefId,
      ...commonBIReq,
    });
    await createAppPage({
      editorSDK,
      appDefId,
      pageData: PAGE_DATA,
      isResponsive,
      isStudio,
      shouldAddMenuItem: true,
      fedopsLogger,
      t,
    });
  }

  const { tipsPromise, trackerPromise } = installAppsIfMissing({
    editorSDK,
    fedopsLogger,
    biReporterService,
    isFirstInstall: false,
  });

  await Promise.all([tipsPromise, trackerPromise]);

  const lightboxIds = [LIGHTBOX_IDS.itemModal, LIGHTBOX_IDS.dispatchModal, LIGHTBOX_IDS.errorModal];

  const missingPopups = await getMissingPopups(editorSDK, lightboxIds);

  missingPopups.forEach(async (popup) => {
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: `popup_is_not_installed`,
      value: popup,
      ...commonBIReq,
    });
    const isFirstInstall = false;
    await installLightBoxByTpaAppId[popup](
      editorSDK,
      t,
      appDefId,
      isResponsive,
      biReporterService,
      fedopsLogger,
      isFirstInstall
    );
  });
};

const installAppsIfMissing = ({
  editorSDK,
  fedopsLogger,
  biReporterService,
  isFirstInstall,
  isOptimusOrigin,
}: {
  editorSDK: EditorSDK;
  fedopsLogger: FedopsLogger;
  biReporterService: IBIReporterService;
  isFirstInstall: boolean;
  isOptimusOrigin?: boolean;
}) => {
  const menusPromise =
    isFirstInstall &&
    installAppIfMissing(
      editorSDK,
      APP_DEF_IDS.menus,
      biReporterService,
      fedopsLogger,
      isFirstInstall,
      isOptimusOrigin
    );
  const tipsPromise = installAppIfMissing(
    editorSDK,
    APP_DEF_IDS.tips,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );
  const trackerPromise = installAppIfMissing(
    editorSDK,
    APP_DEF_IDS.orderTracker,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );

  return { menusPromise, tipsPromise, trackerPromise };
};

export const duplicatePage = async (editorSDK: EditorSDK, sourcePageId: string) => {
  return editorSDK.pages.duplicate('_token', {
    pageId: sourcePageId,
    shouldAddMenuItem: true,
    shouldDuplicatePageCode: true,
  }) as unknown as PageRef;
};

export const getAvailablePageUri = async (editorSDK: EditorSDK, pageUriSEO: string) => {
  const applicationPages = await editorSDK.pages.data.getAll('_token');
  const urls = applicationPages.map((p) => p.pageUriSEO);
  console.log('Online orders - getAvailablePageUri -> all pages urls', urls);
  let isUriExists = applicationPages.some((page) => page.pageUriSEO === pageUriSEO);
  console.log('Online orders - getAvailablePageUri -> isUriExists', { isUriExists, pageUriSEO });
  let retries = 1;
  const MAX_RETRIES = 10;
  let updatedUri = pageUriSEO;
  while (isUriExists && retries < MAX_RETRIES) {
    updatedUri = `${pageUriSEO}-${retries}`;
    // eslint-disable-next-line no-loop-func
    isUriExists = applicationPages.some((page) => page.pageUriSEO === updatedUri);
    retries++;
  }

  console.log('Online orders - getAvailablePageUri -> updated url', updatedUri);
  return updatedUri;
};

export const updatePage = async ({
  editorSDK,
  pageRef,
  operationId,
  title,
  pageUriSEO,
  useDynamicUri,
}: {
  editorSDK: EditorSDK;
  pageRef: PageRef;
  operationId?: string;
  title?: string;
  pageUriSEO?: string;
  useDynamicUri?: boolean;
}) => {
  if (operationId) {
    if (pageRef && pageRef.id) {
      const data: Partial<PageData> = {
        tpaPageId: `${PAGE_DATA.pageId}-${operationId}`,
      };

      if (title) {
        data.title = title;
      }

      if (pageUriSEO) {
        data.pageUriSEO = useDynamicUri
          ? await getAvailablePageUri(editorSDK, pageUriSEO ?? PAGE_DATA.pageUriSEO)
          : pageUriSEO;
      }
      await editorSDK.pages.data.update('token', {
        pageRef,
        data,
      });
    }
  }
};

export const ecomComponentsConfiguration = {
  requireEcomComponents: async (): Promise<EcomComponentConfiguration> => {
    return {
      ecomComponents: {},
    };
  },
};

const getComponentByWidgetId = async (editorSDK: EditorSDK, appDefId: string, widgetId: string) => {
  const allComponents = await editorSDK.tpa.app.getAllComps('_token', appDefId);
  return allComponents ? allComponents.find((comp) => comp.widgetId === widgetId) : undefined;
};

export const isOldAppFullWidth = async (editorSDK: EditorSDK) => {
  const APP_DEF_ID = '13e8d036-5516-6104-b456-c8466db39542';
  const WIDGET_ID = '96254d42-7323-40cb-a7cb-b7c242019728';
  const component = await getComponentByWidgetId(editorSDK, APP_DEF_ID, WIDGET_ID);

  if (component) {
    const componentRef = await editorSDK.components.getById('', { id: component.id });
    return editorSDK.components.isFullWidth('', { componentRef });
  }
};
