import type { EditorSDK } from '@wix/platform-editor-sdk';
import { MENUS_SHOWCASE_APP_DEF_ID, PAGE_DATA } from 'root/utils/consts';
import type { ComponentStructure } from 'root/utils/OOI/consts';
import { WIDGET_IDS, widgetLayout } from 'root/utils/OOI/consts';
import { createWidgetDefinition } from 'root/utils/OOI/editorScript/shared/createDefinitions';
import { setPagesState } from 'root/utils/createPage';
import { applyOoiDesign, getBlocksDesign } from './design';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { MigrationComponent } from './types';
import { applyOoiSettings, getBlocksSettings } from './settings';
import { getBlocksLayout } from './layout';

export const migrateByRef = async ({
  editorSDK,
  tpaAppId,
  component,
  isResponsive,
  flowAPI,
}: {
  editorSDK: EditorSDK;
  tpaAppId: number;
  component: MigrationComponent;
  isResponsive: boolean;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const blocksDesign = getBlocksDesign({ component, isResponsive });

  const blockSettings = getBlocksSettings({ component });

  const blocksLayout = getBlocksLayout({ component, isResponsive });

  await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    const ancestors = await editorSDK.document.components.getAncestors('', { componentRef: component.ref });
    const parent = ancestors[0];

    const tpaWidget: ComponentStructure = isResponsive
      ? createWidgetDefinition({
          appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
          widgetId: WIDGET_IDS.menus,
          tpaAppId,
          overrides: {
            parent: parent.id,
            layout: blocksLayout.layout,
            layouts: blocksLayout.layouts,
            scopedLayouts: blocksLayout.scopedLayouts,
            layoutResponsive: undefined,
          },
        })
      : {
          componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
          data: {
            applicationId: `${tpaAppId}`,
            widgetId: WIDGET_IDS.menus,
            appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
            type: 'TPA',
          },
          parent: parent.id,
          skin: 'wysiwyg.viewer.skins.TPASectionSkin',
          layout: blocksLayout.layout ?? widgetLayout,
          mobileStructure: blocksLayout.mobileStructure ? { layout: blocksLayout.mobileStructure.layout } : undefined,
          layouts: undefined,
        };

    await editorSDK.document.components.remove('', { componentRef: component.ref });
    const ooiRef = await editorSDK.document.components.addAndAdjustLayout('', {
      componentDefinition: tpaWidget,
      pageRef: parent,
    });

    const { ooiStyleParams } = await applyOoiSettings({
      editorSDK,
      ref: ooiRef,
      settings: blockSettings,
      flowAPI,
    });
    await applyOoiDesign({ editorSDK, ref: ooiRef, design: blocksDesign, ooiStyleParams });
  });
};

export const migrateAll = async ({
  editorSDK,
  tpaAppId,
  isResponsive,
  flowAPI,
}: {
  editorSDK: EditorSDK;
  tpaAppId: number;
  isResponsive: boolean;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const allRefComponents = await editorSDK.document.components.refComponents.getAllAppRefComponents('');
  const components: MigrationComponent[] = await Promise.all(
    allRefComponents.map(async (ref) => {
      return { ref, serialize: await editorSDK.document.components.serialize('', { componentRef: ref }) };
    })
  );

  const blocksComponents = components.filter((component) => component.serialize.data.widgetId === PAGE_DATA.widgetId);

  await Promise.all(
    blocksComponents.map((component) => migrateByRef({ editorSDK, tpaAppId, component, isResponsive, flowAPI }))
  );

  await setPagesState({ editorSDK });

  // @ts-expect-error
  await editorSDK.document.tpa.app.refreshApp('', {
    appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
  });
};
