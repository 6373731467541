import type { TFunction } from '@wix/yoshi-flow-editor';
import type { PayPalPaymentMethod } from '@appTypes';

export const getPayPalTitle = (
  paymentMethod: PayPalPaymentMethod,
  t: TFunction,
  displayName?: string,
): string => {
  const { email } = paymentMethod.paymentInfo;

  return t('app.payment-method.paypal.title', {
    title: displayName || t('app.payment-method.paypal.default-title'),
    email,
  });
};

export const getPayPalHolder = (
  paymentMethod: PayPalPaymentMethod,
  t: TFunction,
): string | undefined => {
  const { accountHolder } = paymentMethod.paymentInfo;

  if (accountHolder) {
    return t('app.payment-method.account-holder.label', { accountHolder });
  }
};
