import type { StyleParamType } from '@wix/tpa-settings';
import { useStyles } from '@wix/tpa-settings/react';
import type { StyleParamKeysByType } from 'root/components/Menus/stylesParams';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { getAlignmentText } from 'root/utils/alignment';

export const useGetAlignment = (style: StyleParamKeysByType<StyleParamType.Number>) => {
  const styles = useStyles();

  const alignment = styles.get(stylesParams[style]);

  return getAlignmentText(alignment);
};
