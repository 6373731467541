import React from 'react'
import {DETAILS_ROUTE} from '../../../../../commons/constants/navigation'
import {FormEntry} from '../chunks/form-entry'
import {StatusScreensEntry} from '../chunks/status-screens-entry'
import {EventDetails} from '../event-details'
import {EventDetailsMobile} from '../event-details-mobile'
import {RouterProps} from './interfaces'

export const Router = ({route, mobile, reservationState, isTicketed}: RouterProps) => {
  switch (route) {
    case DETAILS_ROUTE.DETAILS:
    case DETAILS_ROUTE.PICK_TICKETS:
      return mobile ? <EventDetailsMobile /> : <EventDetails />
    case DETAILS_ROUTE.TICKET_FORM:
    case DETAILS_ROUTE.FORM:
    case DETAILS_ROUTE.CHECKOUT:
      return <FormEntry route={route} ticketed={isTicketed} />
    case DETAILS_ROUTE.TEMPLATE_ORDER:
    case DETAILS_ROUTE.ORDER:
    case DETAILS_ROUTE.ONLINE_CONFERENCING:
    case DETAILS_ROUTE.LINK_EXPIRED:
    case DETAILS_ROUTE.NOT_FOUND:
    case DETAILS_ROUTE.CHANGE_RSVP:
    case DETAILS_ROUTE.THANK_YOU_MESSAGES:
      return <StatusScreensEntry route={route} reservationState={reservationState} />
    default:
      return <div>404</div>
  }
}
