import type { IHttpClient } from '@wix/yoshi-flow-editor';
import type { SavedPaymentMethodWithMeta, SavedPaymentMethod } from '@appTypes';
import { concatWithMetaData } from '@utils/paymentMethodMapper';
import {
  fetchSavedPaymentMethods,
  markSavedPaymentMethodAsPrimary,
  removeSavedPaymentMethod,
} from './http';

export class SavedPaymentMethodService {
  constructor(private readonly httpClient: IHttpClient) {}

  async getAll(): Promise<SavedPaymentMethodWithMeta[] | undefined> {
    const { savedPaymentMethods } = await fetchSavedPaymentMethods(
      this.httpClient,
      {
        siteMemberId: 'me',
      },
    );

    if (savedPaymentMethods) {
      return concatWithMetaData(savedPaymentMethods as SavedPaymentMethod[]);
    }
  }

  async markAsPrimary(savedPaymentMethodId: string): Promise<void> {
    await markSavedPaymentMethodAsPrimary(this.httpClient, {
      savedPaymentMethodId,
    });
  }

  async remove(savedPaymentMethodId: string): Promise<void> {
    await removeSavedPaymentMethod(this.httpClient, { savedPaymentMethodId });
  }
}
