import model from './model';
import { MenusController } from './menusController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from 'root/api/consts';
import { hasDataChanged } from 'root/utils/utils';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { translations, environment, experiments, controllerConfig } = flowAPI;
  const t = translations.t as TFunction;
  const { isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const { timezone = DEFAULT_TIMEZONE, regionalSettings } = wixCodeApi.site;
  const locale = regionalSettings || DEFAULT_LOCALE;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;
  let didInit = false;

  const menusController = new MenusController(
    $w,
    t,
    experiments,
    isEditor,
    timezone,
    locale,
    isMemberLoggedIn
  );

  $widget.onPropsChanged((prevProps, nextProps) => {
    const { menus } = nextProps.data;
    const shouldInit = hasDataChanged({ prevData: prevProps.data?.menus, nextData: menus });
    if (shouldInit) {
      if (menus.length > 0) {
        if (!didInit) {
          didInit = true;
          menusController.init(menus);
        } else {
          menusController.setMenus(menus);
        }
      } else {
        menusController.setEmptyState();
      }
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      if ($widget.props.data.menus.length > 0) {
        didInit = true;
        menusController.init($widget.props.data.menus);
      } else {
        menusController.setEmptyState();
      }
    },
    exports: {},
  };
});
